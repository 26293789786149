var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", [
      !_vm.reservation.saved_in_waiting_list
        ? _c("span", { staticClass: "d-block" }, [
            _vm._v(_vm._s(_vm.$tl("labels.reservation.thankForReservation"))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reservation.saved_in_waiting_list
        ? _c("span", [
            _vm._v(_vm._s(_vm.$tl("success.waitingList.registered"))),
          ])
        : _vm.widget.is_gv_enabled &&
          _vm.reservation.gift &&
          _vm.option_bank &&
          _vm.hasSetupIntent
        ? _c("span", [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$tl("labels.reservation.summarySendAfterGvValidation")
                ) +
                "\n        "
            ),
          ])
        : _c("span", [
            _vm._v(_vm._s(_vm.$tl("labels.reservation.summarySentByMail"))),
          ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.reservation.saved_in_waiting_list &&
      _vm.option_bank === _vm.OPTION_BANK_FOOTPRINT.value &&
      !_vm.hasSetupIntent
        ? _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.$tl("labels.bankImprint.bankImprintInfos")),
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }