import axios from "axios";

export default {
    fetchRestaurantData({ commit }) {
        return axios
            .get(`${API_URL}/widget/${this._vm.$api_key}`)
            .then((response) => {
                commit("setBgColor", response.data.restaurant.widget_color);
                commit("setStripeClientId", response.data.restaurant.stripe_client_id);
                commit("setName", response.data.restaurant.name);
                commit("setWidget", {
                    ...response.data.widget,
                    restaurant: response.data.restaurant,
                });
                commit("setWidgetFontTitle", response.data.restaurant.widget_font_title);
                commit("setOwnerId", response.data.restaurant.owner_id);
                commit("setRoomNumbers", response.data.room_numbers);
                commit("setTimezone", response.data.restaurant.timezone);
                commit("setDefaultTelCountry", response.data.restaurant.default_tel_country);
                commit("setFeatRoomNumbers", response.data.restaurant.feat_room_numbers);
                commit("setCurrency", response.data.restaurant.currency);
                commit("setLexicon", response.data.restaurant.lexicon);
                commit("setCustomEvents", response.data.custom_events.data);
                commit("setFeatWhiteLabeled", response.data.restaurant.feat_white_labeled);
            })
            .catch();
    },
    setBgColor({ commit }, bg_color) {
        commit("setBgColor", bg_color);
    },
};
