export default {
    all(state) {
        return state;
    },
    slots(state) {
        return state.slots;
    },
    available_menus(state) {
        return state.available_menus;
    },
    available_general_options(state) {
        return state.available_general_options;
    },
    slot_message(state) {
        return state.slot_message;
    },
    special_closure(state) {
        return state.special_closure;
    },
    slots_next_dates(state) {
        return state.slots_next_dates;
    },
    can_search_more_next_dates(state) {
        return state.can_search_more_next_dates;
    },
    restaurant_proposals(state) {
        return state.restaurant_proposals;
    },
    slots_full(state) {
        return state.slots_full;
    },
    stripe_setup_intent(state) {
        return state.stripe_setup_intent;
    },
    payplug_setup_intent(state) {
        return state.payplug_setup_intent;
    },
    created_reservation(state) {
        return state.created_reservation;
    },
    service_full_option(state) {
        return state.service_full_option;
    },
    hasMoreSlots(state) {
        return state.hasMoreSlots;
    },
};
