var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { appear: "", name: "modal" },
      on: { "after-enter": _vm.afterEnter },
    },
    [
      _c("div", { staticClass: "modal-mask" }, [
        _c(
          "div",
          {
            staticClass: "modal-wrapper",
            on: {
              click: function ($event) {
                return _vm.$emit("close", $event)
              },
            },
          },
          [
            _c(
              "div",
              {
                ref: `modal-container`,
                staticClass: "modal-container",
                style: _vm.modalContainerStyle,
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-header" },
                  [_vm._t("header")],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [_vm._t("footer")],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }