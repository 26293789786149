<template>
    <div>
        <div id="msform">
            <div class="formContainer">
                <SelectLang
                    v-if="!langFromScriptInclusion"
                    style="top: auto"
                    class="dropdown dropdown-lang align-content-end mr-2 mt-2"
                    @change-lang="$emit('change-lang', $event)" />
                <h2 class="fs-title">{{ reservation.slot.service.restaurant.name }}</h2>
                <div class="">
                    <div class="mt-4 mb-4 settings-title">
                        <h6 v-if="type === 'payment'">
                            <template v-if="amount">{{
                                $tl("labels.prepayment.successAmount", { amount: $utils.formatPrice(amount / 100) })
                            }}</template>
                            <template v-else>{{ $tl("labels.prepayment.success") }}</template>
                        </h6>
                        <h6 v-else-if="type === 'footprint'">
                            {{ $tl("labels.bankImprint.success") }}
                        </h6>
                        <h6 v-else-if="type === 'confirmation'">
                            {{ $tl("labels.reservation.status.confirmed") }}
                        </h6>
                    </div>
                    <a
                        :href="`/booking/widget/public/${$api_key}/reservation/${reservation_token}`"
                        class="next action-button mt-4"
                        style="text-transform: none !important">
                        {{ $tl("labels.reservation.detailsLink") }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectLang from "../SelectLang.vue";

export default {
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        reservation_token: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            validator(value) {
                return ["payment", "footprint", "confirmation"].includes(value);
            },
        },
        amount: {
            type: Number,
            default: null,
        },
        langFromScriptInclusion: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        currentLang() {
            return this.$store.getters["client/lang"];
        },
    },
    components: {
        SelectLang,
    },
};
</script>
