<template>
    <div>
        <LoaderComponent v-if="loadingForm" />
        <template v-else>
            <SelectLang
                v-if="!langFromScriptInclusion"
                class="dropdown dropdown-lang align-content-end"
                @change-lang="$emit('change-lang', $event)" />

            <form @submit="post" method="post">
                <div class="mt-4">
                    <DatePicker :disabledDates="disabledDates" v-model="date" :inline="true" :day-cell-content="onDayCellContentRendered" />
                </div>

                <div v-if="customEventsForSelectedDate.length > 0" class="mt-2">
                    <div class="text-left w-100">
                        <show-custom-event v-for="customEvent in customEventsForSelectedDate" :key="customEvent.id" :custom-event="customEvent" />
                    </div>
                </div>

                <LoaderComponent v-if="loadingServicesCategories > 0" />
                <div v-else>
                    <div class="mt-3 text-left">
                        <select
                            v-model="reservation.service_category"
                            class="custom-select"
                            :style="atLeastOneServiceAvailable ? 'text-transform: capitalize;' : ''"
                            required="required"
                            :disabled="!atLeastOneServiceAvailable"
                            :class="{ disabled: !atLeastOneServiceAvailable }">
                            <option :value="null" disabled>
                                {{
                                    atLeastOneServiceAvailable
                                        ? widget[`wording_service_selection_${currentLang}`] || $tl("labels.reservation.serviceRequired")
                                        : $tl("labels.reservation.noServiceForThisDate")
                                }}
                            </option>
                            <option v-for="(categ, index) in categ_services_available" :key="`category-${index}`" :value="categ">
                                {{ getCategoryName(categ) }}
                            </option>
                        </select>
                    </div>
                    <div class="text-left div-pax-child">
                        <div class="w-100 mt-2">
                            <select
                                v-model="reservation.nb_pers"
                                class="custom-select"
                                required="required"
                                :disabled="!atLeastOneServiceAvailable || !reservation.service_category"
                                :class="{ disabled: !atLeastOneServiceAvailable || !reservation.service_category }">
                                <option :value="null" disabled>
                                    {{ $tl(widget.enable_children ? "labels.reservation.nbOfAdults" : "labels.reservation.nbOfPeople") }}
                                    *
                                </option>
                                <template v-for="i in maxPers - reservation.nb_children * 1">
                                    <option v-if="i >= minPers" :value="i" :key="`nb-people-${i}`">
                                        {{ i }}
                                    </option>
                                </template>
                            </select>
                        </div>
                        <div class="w-100 mt-2 pax-child" v-if="widget.enable_children">
                            <select
                                v-model.number="reservation.nb_children"
                                class="custom-select"
                                :disabled="!atLeastOneServiceAvailable || !reservation.service_category"
                                :class="{ disabled: !atLeastOneServiceAvailable || !reservation.service_category }">
                                <option value="0">{{ $tl("labels.reservation.nbOfChildren") }} ({{ $tl("labels.reservation.lessTenYears") }})</option>
                                <option v-for="i in remainingAvailableNbChildren" :value="i" :key="`nb-children-${i}`">
                                    {{ i }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="text-center">
                        <input
                            type="submit"
                            :disabled="!atLeastOneServiceAvailable"
                            :class="{ disabled: !atLeastOneServiceAvailable }"
                            class="next action-button mt-2"
                            :value="$tl('labels.next')" />
                    </div>
                </div>
            </form>
        </template>
    </div>
</template>

<script>
import DatePicker from "../DatePicker.vue";
import { DateTime } from "luxon";
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import SelectLang from "../SelectLang.vue";
import getCustomEventBell from "../../icons/customEventBell.js";
import showCustomEvent from "../showCustomEvent.vue";

export default {
    data() {
        return {
            reservation: this.$store.getters["reservation/all"],
            reservation_data: this.$store.getters["reservationData/all"],
            loadingForm: false,
            loadingServicesCategories: 0,
            dateData: null,
            disabledDates: {
                to: null,
                dates: [],
                customPredictor: this.customAvailabilityPredictor,
            },
            categ_services_available: [],
        };
    },
    props: {
        langFromScriptInclusion: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        minPers() {
            if (this.reservation.service_category && this.reservation.service_category.min_pers !== null)
                return this.reservation.service_category.min_pers;
            return this.widget.min_pers * 1;
        },
        maxPers() {
            if (this.reservation.service_category && this.reservation.service_category.max_pers !== null)
                return this.reservation.service_category.max_pers;
            return this.widget.max_pers * 1;
        },
        remainingAvailableNbChildren() {
            const nb1 = this.maxPers * 1 - this.reservation.nb_pers * 1;
            if (!this.widget.max_children) return nb1;
            return Math.min(nb1, this.widget.max_children * 1);
        },
        atLeastOneServiceAvailable() {
            return this.categ_services_available && this.categ_services_available.length > 0;
        },
        date: {
            get() {
                return this.reservation.reservation_date instanceof DateTime ? this.reservation.reservation_date : this.getDateTime().startOf("day");
            },
            set(date) {
                if (date != null && this.reservation.reservation_date != date) {
                    this.reservation.reservation_date = date;
                    this.onDateUpdated();
                }
            },
        },
        currentLang() {
            return this.$store.getters["client/lang"];
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        customEvents() {
            return this.$store.getters["restaurant/customEvents"];
        },
        customEventsForSelectedDate() {
            return this.getCustomEventsForDate(this.date.toISODate());
        },
    },
    methods: {
        onDayCellContentRendered(cell) {
            const date = DateTime.fromMillis(cell.timestamp).toISODate();

            if (cell.isDisabled || this.getCustomEventsForDate(date).length < 1) {
                return cell.date;
            }

            return (
                cell.date +
                getCustomEventBell({
                    fill: cell.isSelected ? "#fff" : undefined,
                    style: "position: absolute; right: 2px; top: 2px;",
                })
            );
        },
        getCustomEventsForDate(date) {
            return this.customEvents.filter((customEvent) => customEvent.date_begin <= date && date <= customEvent.date_end);
        },
        onDateUpdated() {
            this.reservation.service_category = null;
            this.loadServicesCategories();
        },
        post(e) {
            if (e) {
                e.preventDefault();
            }

            this.loadingForm = true;
            this.$emit("set-form-errors", []);

            axios
                .post(`${API_URL}/widget/${this.$api_key}/slots`, {
                    date: this.reservation.reservation_date.toISODate(),
                    service_category: this.reservation.service_category.fr,
                    nb_pers: this.reservation.nb_pers,
                    nb_children: this.reservation.nb_children,
                })
                .then((response) => {
                    if (response.data.slots && response.data.slots.length > 0) {
                        this.loadingForm = false;
                        this.reservation_data.slots = response.data.slots;
                        this.reservation_data.special_closure = false;
                        this.reservation_data.slot_message = null;
                        this.$emit("next-step");
                    } else {
                        this.reservation_data = {
                            ...this.reservation_data,
                            ...{
                                slots: [],
                                slot_message: response.data.message || null,
                                special_closure: response.data && response.data.is_closed === true,
                                hasMoreSlots: response.data && response.data.has_more_slots === true,
                            },
                        };

                        let hasError = false;
                        const data = {
                            date: this.reservation.reservation_date.toISODate(),
                            service_category: this.reservation.service_category.fr,
                            nb_pers: this.reservation.nb_pers,
                            nb_children: this.reservation.nb_children,
                        };
                        const errorCallback = (error) => {
                            hasError = true;
                            if (error.response && error.response.data.errors)
                                this.$emit("set-form-errors", this.formErrors.concat(error.response.data.errors));
                        };
                        const restaurantsProposalsPromise = axios
                            .post(`${API_URL}/widget/${this.$api_key}/restaurantsProposals`, data)
                            .then((response) => (this.reservation_data.restaurant_proposals = response.data))
                            .catch(errorCallback);
                        const slotsFullPromise = axios
                            .post(`${API_URL}/widget/${this.$api_key}/slotsFull`, data)
                            .then((response) => (this.reservation_data.slots_full = response.data.slots))
                            .catch(errorCallback);
                        Promise.all([restaurantsProposalsPromise, slotsFullPromise])
                            .then(() => {
                                this.loadingForm = false;
                                if (hasError) return;
                                this.$emit("next-step");
                            })
                            .catch(() => (this.loadingForm = false));
                    }
                })
                .catch((error) => {
                    this.loadingForm = false;

                    if (error.response && error.response.data && error.response.data.errors) {
                        this.$emit("set-form-errors", error.response.data.errors);
                    }
                });
        },
        initDisabledDates() {
            if (!this.widget) {
                return;
            }

            if (this.widget.max_reservation_hours && this.widget.max_reservation_hours * 1 > 0) {
                this.disabledDates.from = this.getDateTime().plus({ hours: this.widget.max_reservation_hours }).toJSDateCustom();
            }

            if (this.widget.closed_dates && this.widget.closed_dates.length > 0) {
                for (let i = 0; i < this.widget.closed_dates.length; i++) {
                    this.disabledDates.dates.push(this.getDateTime(this.widget.closed_dates[i]).toJSDateCustom());
                }
            }

            this.disabledDates.to = this.getDateTime().minus({ days: 1 }).toJSDateCustom();
        },
        openCategories(date) {
            var result = [];

            for (const index in this.widget.restaurant.services) {
                const service = this.widget.restaurant.services[index];

                if (result.indexOf(service.category) !== -1) {
                    continue;
                }

                let isOpen = true;

                if (service.special) {
                    const openingStart = this.getDateTime(service.special_datetime_begin).startOf("day");
                    const openingEnd = this.getDateTime(service.special_datetime_end).endOf("day");

                    if (openingStart > date || date > openingEnd) {
                        isOpen = false;
                    }
                }

                if (isOpen) {
                    const serviceDays = JSON.parse(service.days);
                    const englishDayOfWeek = date.setLocale("en").weekdayLong.toLowerCase();

                    if (serviceDays[englishDayOfWeek] !== 1) {
                        isOpen = false;
                    }
                }

                if (isOpen) {
                    result.push(service.category);
                }
            }

            return result;
        },
        customAvailabilityPredictor: function (date) {
            const dateTime = this.getDateTime(date, false).startOf("day");
            const openCategories = this.openCategories(dateTime);

            for (const index in this.widget.restaurant.closures) {
                const closure = this.widget.restaurant.closures[index];
                const closureStart = this.getDateTime(closure.datetime_begin).startOf("day");
                const closureEnd = this.getDateTime(closure.datetime_end).endOf("day");

                if (closureStart <= dateTime && dateTime <= closureEnd) {
                    let tmpCategoriesOpen = openCategories.slice();

                    for (const i in closure.services) {
                        let indexOfCategory = -1;
                        if ((indexOfCategory = tmpCategoriesOpen.indexOf(closure.services[i].category)) !== -1) {
                            tmpCategoriesOpen = tmpCategoriesOpen.splice(indexOfCategory, 1);
                        }
                    }

                    if (tmpCategoriesOpen.length < 1) {
                        return true;
                    }
                }
            }

            return openCategories.length === 0;
        },
        loadServicesCategories() {
            this.loadingServicesCategories++;
            return axios
                .get(`${API_URL}/widget/${this.$api_key}/services-categories/${this.reservation.reservation_date.toISODate()}`)
                .then((response) => {
                    this.categ_services_available = response.data;
                    this.loadingServicesCategories--;
                })
                .catch((error) => {
                    this.loadingServicesCategories--;
                });
        },
        getCategoryName(category) {
            const basicCategories = ["brunch", "midi", "soir"];
            if (basicCategories.includes(category.fr)) return this.$tl(`labels.service.category.${category.fr}`);
            else if (this.currentLang === "fr") return category.fr;
            return category.en;
        },
    },
    watch: {
        reservation: {
            deep: true,
            handler(newVal) {
                if (newVal) {
                    this.$store.dispatch("reservation/setAll", newVal);
                }
            },
        },
        reservation_data: {
            deep: true,
            handler(newVal) {
                if (newVal) this.$store.dispatch("reservationData/setAll", newVal);
            },
        },
        "reservation.service_category": function (newVal) {
            if (this.reservation.nb_pers || this.reservation.nb_children) {
                const nbPersTotal = this.reservation.nb_pers * 1 + this.reservation.nb_children * 1;
                if (!newVal) {
                    this.reservation.nb_pers = null;
                    this.reservation.nb_children = 0;
                } else if (
                    (newVal.min_pers !== null && nbPersTotal < newVal.min_pers) ||
                    (newVal.max_pers !== null && nbPersTotal > newVal.max_pers)
                ) {
                    this.reservation.nb_pers = null;
                    this.reservation.nb_children = 0;
                }
            }
        },
    },
    components: {
        SelectLang,
        DatePicker,
        LoaderComponent,
        showCustomEvent,
    },
    created() {
        this.initDisabledDates();

        this.loadingForm = true;
        let nbRequiredElemFromUrl = 0;
        const obj = this.$utils.initFromUrl(["reservation_date", "nb_pers", "nb_children", "service_category_fr", "step"]);
        if (obj.step && !isNaN(obj.step) && parseInt(obj.step) > 1) nbRequiredElemFromUrl++;
        if (obj.reservation_date) {
            this.reservation.reservation_date = DateTime.fromISO(obj.reservation_date, { timeZone: this.timezone });
            nbRequiredElemFromUrl++;
        } else {
            this.reservation.reservation_date = this.date;
        }
        if (obj.nb_pers && !isNaN(obj.nb_pers) && parseInt(obj.nb_pers) > 0) {
            nbRequiredElemFromUrl++;
            this.reservation.nb_pers = parseInt(obj.nb_pers);
        }
        if (obj.nb_children && !isNaN(obj.nb_children) && parseInt(obj.nb_children) > 0) {
            this.reservation.nb_children = parseInt(obj.nb_children);
        }

        this.loadServicesCategories().then(() => {
            if (obj.service_category_fr) {
                const service_category = this.categ_services_available.find(
                    (categ) => categ.fr.toLowerCase() === obj.service_category_fr.toLowerCase()
                );
                if (service_category) {
                    nbRequiredElemFromUrl++;
                    this.reservation.service_category = service_category;
                }
            }
            if (nbRequiredElemFromUrl >= 4) {
                this.post();
            } else {
                this.loadingForm = false;
            }
        });
    },
};
</script>
