<template>
    <div>
        <form @submit="post" method="post" class="text-left">
            <div v-if="slots && slots.length > 0" class="slot_btn_select mt-4 radio-widget">
                <label class="mb-3 noshow-widget-font-title">
                    {{ $tl("labels.hour") }}
                    <br />
                    <small class="text-muted" v-if="widget && widgetSlotMessage && widget.widget_messages_configuration.slot_message_activated === 1">
                        {{ widgetSlotMessage }}
                    </small>
                </label>
                <label class="container-box" v-for="slot in slots" :key="`slot-${slot.id}`">
                    <input
                        type="radio"
                        name="slot"
                        v-model="slot_id"
                        :value="slot.id"
                        :disabled="loadingTryToPlaceInRoom"
                        @change="slotSelected(slot)" />
                    <span class="checkmark"></span> {{ slot.hour_start }}
                </label>
            </div>
            <div v-else>
                <div v-if="hasMoreSlots">
                    {{ $tl("labels.slots.notAllOpened") }}
                </div>
                <div class="h6 text-warning" v-else-if="slot_message">
                    {{ $tl(`labels.full.${slot_message}`) }}
                </div>
                <div class="mt-4" v-else>
                    {{ $tl("labels.full.serviceFull") }}
                </div>

                <div class="card-group mt-3 mb-3">
                    <div class="card widget-card" :data-label="reservation_data.service_full_option.othersDays">
                        <div class="card-body" @click="slotsNextSelected">
                            <h6 class="mb-0 noshow-widget-font-title">{{ $tl("labels.full.nextDatesLabel") }}</h6>
                        </div>
                    </div>
                    <div
                        class="card widget-card"
                        :data-label="reservation_data.service_full_option.waitingList"
                        v-if="slots.length == 0 && widget.enable_waitings">
                        <div class="card-body" @click="reservation_data.service_full_option.value = reservation_data.service_full_option.waitingList">
                            <h6 class="mb-0 noshow-widget-font-title">{{ $tl("labels.full.waitingListLabel") }}</h6>
                        </div>
                    </div>
                    <div
                        class="card widget-card"
                        :data-label="reservation_data.service_full_option.othersRestaurants"
                        v-if="restaurant_proposals && restaurant_proposals.length > 0">
                        <div
                            class="card-body"
                            @click="reservation_data.service_full_option.value = reservation_data.service_full_option.othersRestaurants">
                            <h6 class="mb-0 noshow-widget-font-title">{{ $tl("labels.full.otherRestaurantLabel") }}</h6>
                        </div>
                    </div>
                </div>

                <template v-if="reservation_data.service_full_option.value === reservation_data.service_full_option.othersDays">
                    <LoaderComponent v-if="loadingSlotsNext" />
                    <div class="widget-full-option pt-4 pr-4 pl-4" :class="Object.keys(slots_next_dates).length === 0 ? 'pb-4' : 'pb-3'" v-else>
                        <div class="slot_btn_select">
                            <span class="text-warning" v-if="Object.keys(slots_next_dates).length === 0">
                                {{
                                    !can_search_more_next_dates ? $tl("labels.full.noNextDatesAvailable") : $tl("labels.full.noNextDatesAvailableYet")
                                }}
                            </span>
                            <a
                                href="#"
                                v-else
                                v-for="key in Object.keys(slots_next_dates)"
                                :key="`date-${key}`"
                                @click="resetDate($event, slots_next_dates[key])"
                                class="btn btn-secondary widget-public-hour btn-sm mb-2 mr-2">
                                {{ displayDate(slots_next_dates[key], DateTime.DATE_HUGE) }}
                            </a>
                            <button
                                v-if="can_search_more_next_dates"
                                type="button"
                                class="d-block action-button"
                                style="width: auto !important; text-transform: initial !important; padding: 10px 10px !important"
                                @click="fetchSlotsNext(false)">
                                {{ $tl("labels.full.btnSearchMore") }}
                            </button>
                        </div>
                    </div>
                </template>

                <div
                    class="widget-full-option p-4"
                    v-if="reservation_data.service_full_option.value === reservation_data.service_full_option.waitingList">
                    <div>
                        <div v-if="success_waiting_list" class="alert alert-success">
                            {{ success_waiting_list }}
                        </div>
                        <div v-else>
                            <div v-if="error_waiting_list" class="alert alert-danger">
                                {{ error_waiting_list }}
                            </div>
                            <LoaderComponent v-if="loadingWaitingForm" />
                            <template v-else>
                                <div class="row">
                                    <div class="col-12 radio-widget mb-2">
                                        <label>{{ $tl("labels.hour") }} <small>*</small></label>
                                        <label class="container-box d-inline-block" v-for="slot in slots_full" :key="`slot--${slot.id}`">
                                            <input type="radio" name="slot" v-model="slot_id" :value="slot.id" @change="slotSelected(slot, false)" />
                                            <span class="checkmark"></span> {{ slot.hour_start }}
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group text-left mb-0">
                                            <label>{{ $tl("labels.form.civility") }} <small>*</small></label>
                                            <div class="slot_btn_select radio-widget">
                                                <label class="container-box" style="width: initial">
                                                    <input
                                                        type="radio"
                                                        name="civility"
                                                        value="monsieur"
                                                        v-model="client.civility"
                                                        required="required" />
                                                    <span class="checkmark"></span>
                                                    {{ $tl("labels.form.man") }}
                                                </label>
                                                <label class="container-box" style="width: initial">
                                                    <input
                                                        type="radio"
                                                        name="civility"
                                                        value="madame"
                                                        v-model="client.civility"
                                                        required="required" />
                                                    <span class="checkmark"></span>
                                                    {{ $tl("labels.form.woman") }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mt-2">
                                        <label>{{ $tl("labels.form.lastName") }} <small>*</small></label>
                                        <input type="text" name="lastname" v-model="client.lastname" placeholder="..." required="required" />
                                    </div>
                                    <div class="col-sm-6 mt-2">
                                        <label>{{ $tl("labels.form.firstName") }} <small>*</small></label>
                                        <input type="text" name="firstname" v-model="client.firstname" placeholder="..." required="required" />
                                    </div>
                                    <div class="col-sm-12 mt-2">
                                        <label>{{ $tl("labels.form.company") }}</label>
                                        <input type="text" name="company" v-model="client.company" placeholder="..." required="required" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mt-2">
                                        <label>{{ $tl("labels.form.email") }} <small>*</small></label>
                                        <input type="email" name="email" v-model="client.email" placeholder="..." required="required" />
                                    </div>
                                    <div class="col-sm-6 mt-2">
                                        <label>{{ $tl("labels.form.phone") }} <small>*</small></label>
                                        <vue-tel-input
                                            name="telInput"
                                            class="mb-2"
                                            @validate="setPhoneAndCountry"
                                            :defaultCountry="defaultTelCountry"
                                            v-model="phone.raw"
                                            :enabledCountryCode="true"
                                            :wrapperClasses="this.phone.isValid === null || this.phone.isValid ? '' : 'border border-danger'"
                                            inputClasses="m-0 border-0"
                                            mode="international"
                                            placeholder="-- -- -- -- --"
                                            required="required"></vue-tel-input>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 checkbox-widget">
                                        <label class="container-box" style="width: 100%; line-height: 1.3">
                                            <input type="checkbox" required="required" v-model="cgv" true-value="1" />
                                            <span class="checkmark"></span>
                                            <i18n path="labels.form.acceptCGUAfterBooking" tag="span" class="link-widget">
                                                <template slot="cgu">
                                                    <a
                                                        :href="
                                                            customCgu || `${base_url}/booking/widget/public/${this.$api_key}/cgv?lang=${currentLang}`
                                                        "
                                                        target="_blank">
                                                        {{ $tl("labels.form.CGU") }}
                                                    </a>
                                                </template>
                                                <template slot="pcdp">
                                                    <a
                                                        :href="`${base_url}/booking/widget/public/${this.$api_key}/policy?lang=${currentLang}`"
                                                        target="_blank">
                                                        {{ $tl("labels.form.PCDP") }}
                                                    </a>
                                                </template>
                                            </i18n>
                                            <br />
                                            <small class="text-muted">{{ $tl("labels.form.acceptCGUReason") }}</small>
                                        </label>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-secondary text-white-hover" @click.prevent="saveInWaitingList">
                                    {{ $tl("labels.form.save") }}
                                </button>
                            </template>
                        </div>
                    </div>
                </div>

                <div
                    class="widget-full-option p-4"
                    v-if="reservation_data.service_full_option.value === reservation_data.service_full_option.othersRestaurants">
                    <div class="slot_btn_select">
                        <div v-for="(restau, index) in restaurant_proposals" class="d-inline-block mr-3" :key="`restau-${index}`">
                            <div class="restau-infos">
                                <h6>{{ restau.name }}</h6>
                                <p>
                                    {{ restau.formatted_address }} <br />
                                    {{ restau.tel }}
                                </p>
                                <a :href="redirectToRestaurant(restau)" target="_blank" class="btn btn-secondary widget-public-hour btn-sm mr-2">
                                    {{ $tl("labels.action.book") }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoaderComponent v-if="loadingMenus" />
            <template v-else>
                <div class="d-flex mx-3 justify-content-end">
                    <a
                        v-if="reservation.service && hasExternalMenu"
                        style="width: auto !important"
                        class="action-button text-center"
                        :href="linkExternalMenu"
                        target="_blank">
                        {{ $tl("labels.seeExternalMenu") }}
                    </a>
                </div>
                <div class="mb-4 pt-3 pl-3 pr-3 pb-0 mt-2" style="border: 1px solid #f0f0f0" v-if="availableSeatingPlanRooms.length > 1">
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="mb-3 mt-2 noshow-widget-font-title">
                                {{ $tl("labels.option.chooseRoom") }}
                                <span v-if="!reservation.service.allow_clients_choose_room_mandatory">({{ $tl("labels.optional") }})</span
                                ><small v-else>*</small>
                            </label>
                        </div>
                        <div class="col-sm-6">
                            <LoaderComponent v-if="loadingTryToPlaceInRoom" />
                            <select v-else class="custom-select" v-model="reservation.prefered_room">
                                <option :value="null" :disabled="reservation.service.allow_clients_choose_room_mandatory == 1">
                                    <span v-if="!reservation.service.allow_clients_choose_room_mandatory">{{
                                        $tl("labels.option.noPreferedRoom")
                                    }}</span>
                                    <span v-else>{{ $tl("labels.option.chooseRoom") }}</span>
                                </option>
                                <option v-for="room in availableSeatingPlanRooms" :value="room" :key="room.id">
                                    {{ room.name }}
                                </option>
                            </select>
                            <span class="text-danger" v-if="errorTryToPlaceInRoom">{{ errorTryToPlaceInRoom }}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="mb-4 pt-3 pl-3 pr-3 pb-0 mt-2"
                    style="border: 1px solid #f0f0f0"
                    v-if="available_menus.length > 0 && reservation.nb_pers > 0">
                    <div class="d-flex justify-content-between">
                        <label class="mb-3 mt-2">
                            <span class="noshow-widget-font-title">
                                {{ $tl("labels.option.chooseMenu") }}
                                <template v-if="reservation.service.menu_mandatory == false"> ({{ $tl("labels.optional") }}) </template>
                            </span>
                            <br />
                            <small v-if="areMenusNeedToBeUnique">{{ $tl("labels.option.menuIsUnique") }}</small>
                            <small v-if="widget.res" class="d-block text-muted"></small>
                        </label>
                    </div>

                    <template v-for="m in available_menus">
                        <div
                            class="one-menu mb-3"
                            v-if="!m.for_children || (reservation.nb_children > 0 && !areMenusNeedToBeUnique)"
                            :key="`menu-${m.id}`">
                            <div class="row text-left mb-0">
                                <div class="col-sm-8">
                                    <img v-if="m.img !== null" :src="m.img" class="menu-img mr-1" />
                                    <div class="d-inline-block v-align-top">
                                        <div class="d-flex align-items-center label-menu">
                                            <label class="mb-1">
                                                {{ m.name }}{{ m.price === 0 ? "" : ` - ${formatCurrency(m.price, restaurantCurrency)}` }}
                                            </label>
                                            <a
                                                v-if="ALL_EXTERNAL_DESCRIPTION_TYPES.includes(m.external_description_type)"
                                                class="ml-2 mb-1 d-flex"
                                                :href="getExternalDescriptionURL(m)"
                                                target="_blank">
                                                <feather type="search" class="feather-search"></feather>
                                            </a>
                                        </div>
                                        <small class="d-block text-muted mb-2">
                                            {{ m.description }}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <select
                                        :ref="`ref_menu_${m.id}`"
                                        class="custom-select"
                                        :disabled="nbMenusAvailable(m.id, m.for_children) === 0"
                                        @change="chooseMenu(m.id, m.for_children, m.price, $event)">
                                        <option value="0">--</option>
                                        <option v-if="areMenusNeedToBeUnique && nbMenusAvailable(m.id) > 0" :value="nb_pers_total">
                                            {{ nb_pers_total }}
                                        </option>
                                        <option v-else v-for="n in nbMenusAvailable(m.id, m.for_children)" :key="`menu-${m.id}-${n}`" :value="n">
                                            {{ n }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div
                                v-if="
                                    reservation.choosen_menus[`id_${m.id}`] !== undefined &&
                                    reservation.choosen_menus[`id_${m.id}`].value > 0 &&
                                    m.menu_options.data.length > 0
                                ">
                                <div
                                    class="option-widget row text-left m-0"
                                    v-for="option in m.menu_options.data"
                                    :key="`menu-${m.id}-option-${option.id}`">
                                    <div class="col-sm-8">
                                        <img v-if="option.img !== null" :src="option.img" class="menu-option-img mr-1" />
                                        <div class="d-inline-block v-align-top">
                                            <label class="mb-0">
                                                {{ option.name
                                                }}{{ option.price === 0 ? "" : `- ${formatCurrency(option.price, restaurantCurrency)}` }}
                                                <small v-if="option.quantity_type === 'mandatory_for_each'" class="text-muted">
                                                    ( {{ $tl("labels.option.forAllMenus") }})
                                                </small>
                                            </label>
                                            <small class="text-muted mb-2">
                                                {{ option.description }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 checkbox-widget">
                                        <div v-if="option.quantity_type === 'mandatory_for_each'">
                                            <select
                                                style="height: 40px; padding: 10px 15px"
                                                :ref="`ref_option_${m.id}${option.id}`"
                                                class="custom-select"
                                                @change="chooseMenuOptionMandatory(m.id, option.price, option.id, $event)">
                                                <option value="0">--</option>
                                                <option :value="reservation.choosen_menus[`id_${m.id}`].value">
                                                    {{ reservation.choosen_menus[`id_${m.id}`].value }}
                                                </option>
                                            </select>
                                        </div>
                                        <select
                                            style="height: 40px; padding: 10px 15px"
                                            v-else-if="option.quantity_type === 'limited_by_pers'"
                                            class="custom-select"
                                            :ref="`ref_option_${m.id}${option.id}`"
                                            @change="chooseMenuOptionLimited(m.id, option.price, option.id, $event)">
                                            <option value="0">0</option>
                                            <option
                                                v-for="n in parseInt(reservation.choosen_menus[`id_${m.id}`].value)"
                                                :value="n"
                                                :key="`menu-${m.id}-option-quantity-${n}`">
                                                {{ n }}
                                            </option>
                                        </select>
                                        <input
                                            style="height: 40px; padding: 10px 15px"
                                            class="form-control"
                                            type="number"
                                            step="1"
                                            min="0"
                                            value="0"
                                            :ref="`ref_option_${m.id}${option.id}`"
                                            v-else-if="option.quantity_type === 'unlimited'"
                                            @change="chooseMenuOptionUnlimited(m.id, option.price, option.id, $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <div
                    class="mb-4 pt-3 pl-3 pr-3 pb-0 mt-2"
                    style="border: 1px solid #f0f0f0"
                    v-if="available_general_options.length > 0 && reservation.nb_pers > 0">
                    <label class="mb-3 mt-2 noshow-widget-font-title">
                        {{ $tl("labels.chooseOption") }}
                        <br />
                    </label>

                    <div class="one-menu mb-3" v-for="(o, index) in available_general_options" :key="`general_option-${index}`">
                        <div class="row text-left mb-0">
                            <div class="col-sm-8">
                                <img v-if="o.img !== null" :src="o.img" class="general-option-img mr-1" />
                                <div class="d-inline-block v-align-top">
                                    <label class="mb-1">
                                        {{ o.name }}{{ o.price === 0 ? "" : ` - ${formatCurrency(o.price, restaurantCurrency)}` }}
                                    </label>
                                    <small class="d-block text-muted mb-2">
                                        {{ o.description }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div v-if="o.quantity_type === 'mandatory_for_each'">
                                    <select
                                        style="height: 40px; padding: 10px 15px"
                                        :ref="`ref_general_option_${o.id}`"
                                        class="custom-select"
                                        @change="chooseGeneralOption(o.id, o.price, $event)">
                                        <option value="0">--</option>
                                        <option :value="nb_pers_total">
                                            {{ nb_pers_total }}
                                        </option>
                                    </select>
                                </div>

                                <select
                                    style="height: 40px; padding: 10px 15px"
                                    v-else-if="o.quantity_type === 'limited_by_pers'"
                                    class="custom-select"
                                    :ref="`ref_general_option_${o.id}`"
                                    @change="chooseGeneralOption(o.id, o.price, $event)">
                                    <option value="0">0</option>
                                    <option v-for="n in nb_pers_total" :value="n" :key="`general_option-quantity-${n}`">
                                        {{ n }}
                                    </option>
                                </select>

                                <input
                                    style="height: 40px; padding: 10px 15px"
                                    class="form-control"
                                    type="number"
                                    step="1"
                                    min="0"
                                    value="0"
                                    :ref="`ref_general_option_${o.id}`"
                                    v-else-if="o.quantity_type === 'unlimited'"
                                    @change="chooseGeneralOption(o.id, o.price, $event)" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="mt-2 text-center">
                <a href="#" @click="$emit('previous-step')" class="previous action-button-previous">{{ $tl("labels.back") }}</a>
                <input
                    v-if="showNextButton"
                    type="submit"
                    :disabled="loadingTryToPlaceInRoom"
                    :value="$tl('labels.next')"
                    class="next action-button" />
            </div>
        </form>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import WidgetLangsEnum from "../../mixins/enums/WidgetLangsEnum.js";
import { DateTime } from "luxon";
import ExternalDescriptionTypeEnum from "../../mixins/enums/booking/ExternalDescriptionTypeEnum.js";
import ExternalMenuTypeEnum from "../../mixins/enums/booking/ExternalMenuTypeEnum.js";

export default {
    data() {
        return {
            loadingSlotsNext: false,
            loadingMenus: false,
            loadingWaitingForm: false,
            loadingTryToPlaceInRoom: false,
            errorTryToPlaceInRoom: null,
            waiting_list: false,
            success_waiting_list: null,
            error_waiting_list: null,
            phone: {
                raw: null,
                isValid: null,
                country: null,
                value: null,
            },
            client: this.$store.getters["client/client"],
            reservation: this.$store.getters["reservation/all"],
            reservation_data: this.$store.getters["reservationData/all"],
            cgv: false,
            DateTime,
            slotNextStoppedAtDate: null,
        };
    },
    mixins: [WidgetLangsEnum, ExternalDescriptionTypeEnum, ExternalMenuTypeEnum],
    props: {
        globalLang: {
            default: null,
        },
    },
    computed: {
        currentLang() {
            return this.$store.getters["client/lang"];
        },
        customCgu() {
            return this.$store.getters["restaurant/cgu"];
        },
        defaultTelCountry() {
            return this.$store.getters["restaurant/defaultTelCountry"];
        },
        hasAllNextDates() {
            return this.reservation_data.next_dates && this.reservation_data.next_dates.length === 7;
        },
        showNextButton() {
            return (
                this.slots &&
                this.slots.length > 0 &&
                this.reservation.slot &&
                this.reservation.slot.id &&
                this.reservation.service &&
                !this.errorTryToPlaceInRoom &&
                (!this.reservation.service.allow_clients_choose_room ||
                    !this.reservation.service.allow_clients_choose_room_mandatory ||
                    this.reservation.prefered_room ||
                    this.availableSeatingPlanRooms.length <= 1)
            );
        },
        availableSeatingPlanRooms() {
            if (
                !this.reservation.service ||
                !this.reservation.service.allow_clients_choose_room ||
                !this.reservation.service.seating_plan_rooms ||
                !this.reservation.service.seating_plan_rooms.data ||
                this.reservation.service.seating_plan_rooms.data.length == 0
            )
                return [];
            return this.reservation.service.seating_plan_rooms.data.filter((r) => r.isEnabledForService);
        },
        widgetSlotMessage() {
            if (!this.widget || !this.widget.widget_messages_configuration) return "";
            if (this.globalLang === this.LANG_FR.value) return this.widget.widget_messages_configuration.slot_message_content;
            return (
                this.widget.widget_messages_configuration.slot_message_content_en || this.widget.widget_messages_configuration.slot_message_content
            );
        },
        base_url() {
            return NOSHOW_URL;
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        restaurantCurrency() {
            return this.$store.getters["restaurant/currency"];
        },
        slots() {
            return this.$store.getters["reservationData/slots"];
        },
        slot_message() {
            return this.$store.getters["reservationData/slot_message"];
        },
        slot_id: {
            get() {
                if (this.reservation.slot) return this.reservation.slot.id;
                return null;
            },
            set() {},
        },
        slots_full() {
            return this.$store.getters["reservationData/slots_full"];
        },
        available_menus: {
            get() {
                return this.$store.getters["reservationData/available_menus"];
            },
            set(newVal) {
                this.$store.dispatch("reservationData/setAvailableMenus", newVal);
            },
        },
        available_general_options: {
            get() {
                return this.$store.getters["reservationData/available_general_options"];
            },
            set(newVal) {
                this.$store.dispatch("reservationData/setAvailableGeneralOptions", newVal);
            },
        },
        slots_next_dates() {
            return this.$store.getters["reservationData/slots_next_dates"];
        },
        can_search_more_next_dates() {
            return this.$store.getters["reservationData/can_search_more_next_dates"];
        },
        restaurant_proposals() {
            return this.$store.getters["reservationData/restaurant_proposals"];
        },
        nb_menus_choosen() {
            return this.$store.getters["reservation/nb_menus_choosen"];
        },
        nb_pers_total() {
            return parseInt(this.$store.getters["reservation/nb_pers_total"]);
        },
        areMenusNeedToBeUnique() {
            if (this.reservation.service && this.reservation.service.menu_unique && this.reservation.service.menu_unique_from) {
                return this.nb_pers_total >= this.reservation.service.menu_unique_from;
            }

            return false;
        },
        hasExternalMenu() {
            return this.ALL_EXTERNAL_MENU_TYPES.includes(this.reservation.service.external_menu_type) && this.linkExternalMenu !== "";
        },
        linkExternalMenu() {
            if (this.reservation.service.external_menu_type === this.EXTERNAL_MENU_FILE.value) {
                return this.reservation.service.path_file ?? "";
            }
            if (this.reservation.service.external_menu_type === this.EXTERNAL_MENU_LINK.value) {
                return this.reservation.service.external_menu["link"] ?? "";
            }

            return "";
        },
        hasMoreSlots() {
            return this.$store.getters["reservationData/hasMoreSlots"];
        },
    },
    methods: {
        redirectToRestaurant(restaurant) {
            return `${NOSHOW_URL}/booking/widget/public/${restaurant.widget.token}/page`;
        },
        resetDate(e, date) {
            if (e) e.preventDefault();
            this.reservation.reservation_date = DateTime.fromISO(date);
            this.$emit("previous-step-and-post");
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.phone.isValid = phoneObject.isValid;

                if (this.phone.isValid) {
                    this.phone.value = phoneObject.number;

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.phone.country = phoneObject.regionCode;
                    } else {
                        this.phone.country = phoneObject.country.iso2;
                    }
                }
            } else {
                this.phone.isValid = false;
            }
        },
        chooseMenu(menu_id, for_children, price, event) {
            let nbm_already_choosed = this.nb_menus_choosen;
            let nbm = event.target.value;

            // if value already set for this select
            let max_menus = 0;
            if (this.reservation.choosen_menus["id_" + menu_id] && this.reservation.choosen_menus["id_" + menu_id].value) {
                max_menus = this.nb_pers_total + this.reservation.choosen_menus["id_" + menu_id].value * 1 - nbm_already_choosed;
            } else {
                max_menus = this.nb_pers_total - nbm_already_choosed;
            }

            if (nbm > max_menus) {
                event.target.value = max_menus;
                nbm = max_menus;
            }

            if (nbm > 0) {
                let newVal = undefined;
                this.reservation.choosen_menus[`id_${menu_id}`] !== undefined
                    ? ((newVal = this.reservation.choosen_menus[`id_${menu_id}`]), (newVal.value = nbm))
                    : (newVal = { id: menu_id, value: nbm, price, for_children, options: {} });
                this.$set(this.reservation.choosen_menus, `id_${menu_id}`, newVal);
                this.$nextTick(() => {
                    if (Object.keys(this.reservation.choosen_menus[`id_${menu_id}`].options).length > 0) {
                        for (const index in this.reservation.choosen_menus[`id_${menu_id}`].options) {
                            const option = this.reservation.choosen_menus[`id_${menu_id}`].options[index];
                            const value = isNaN(Number.parseInt(option.value)) ? 0 : Number.parseInt(option.value);
                            if (["mandatory_for_each", "limited_by_pers"].indexOf(option.quantity_type) > -1) {
                                if (option.quantity_type === "mandatory_for_each") {
                                    this.$set(this.reservation.choosen_menus[`id_${menu_id}`].options[index], "value", nbm);
                                } else if (value > nbm) {
                                    this.$set(this.reservation.choosen_menus[`id_${menu_id}`].options[index], "value", nbm);
                                }
                            }
                        }
                    }
                });
            } else {
                this.$set(this.reservation, "choosen_menus", this.$_.omit(this.reservation.choosen_menus, `id_${menu_id}`));
            }
        },
        chooseGeneralOption(option_id, price, event) {
            let value = Number.parseInt(event.target.value);

            if (isNaN(value) || value < 0) {
                event.target.value = 0;
                value = 0;
            }

            if (value > 0) {
                if (typeof this.reservation.choosen_general_options[`id_${option_id}`] !== "undefined") {
                    var newVal = this.reservation.choosen_general_options[`id_${option_id}`];
                    newVal.value = value;
                } else {
                    var newVal = { id: option_id, value, price };
                }

                this.$set(this.reservation.choosen_general_options, `id_${option_id}`, newVal);
            } else {
                this.$set(this.reservation, "choosen_general_options", this.$_.omit(this.reservation.choosen_general_options, `id_${option_id}`));
            }
        },
        chooseMenuOptionMandatory(menu_id, price, option_id, event) {
            const value = event.target.value;

            if (value > 0) {
                this.$set(this.reservation.choosen_menus[`id_${menu_id}`].options, `id_${option_id}`, {
                    id: option_id,
                    value: this.reservation.choosen_menus[`id_${menu_id}`].value,
                    price,
                    quantity_type: "mandatory_for_each",
                });
            } else {
                const optionsArray = this.reservation.choosen_menus[`id_${menu_id}`].options;
                this.$set(this.reservation.choosen_menus[`id_${menu_id}`], "options", this.$_.omit(optionsArray, `id_${option_id}`));
            }
        },
        chooseMenuOptionLimited(menu_id, price, option_id, event) {
            const quantity = event.target.value;

            if (quantity > 0) {
                this.$set(this.reservation.choosen_menus[`id_${menu_id}`].options, `id_${option_id}`, {
                    id: option_id,
                    value: quantity,
                    price,
                    quantity_type: "limited_by_pers",
                });
            } else {
                const optionsArray = this.reservation.choosen_menus[`id_${menu_id}`].options;
                this.$set(this.reservation.choosen_menus[`id_${menu_id}`], "options", this.$_.omit(optionsArray, `id_${option_id}`));
            }
        },
        chooseMenuOptionUnlimited(menu_id, price, option_id, event) {
            const quantity = Number.parseInt(event.target.value);

            if (isNaN(quantity) || quantity < 0) {
                event.target.value = 0;
                const optionsArray = this.reservation.choosen_menus[`id_${menu_id}`].options;
                this.$set(this.reservation.choosen_menus[`id_${menu_id}`], "options", this.$_.omit(optionsArray, `id_${option_id}`));
            } else if (quantity > 0) {
                this.$set(this.reservation.choosen_menus[`id_${menu_id}`].options, `id_${option_id}`, {
                    id: option_id,
                    value: quantity,
                    price,
                    quantity_type: "unlimited",
                });
            }
        },
        getNbChosenMenus(exceptMenuId, onlyChildrenMenu = false) {
            return Object.values(this.reservation.choosen_menus).reduce(
                (count, menu) => (menu.id === exceptMenuId || (onlyChildrenMenu && !menu.for_children) ? count : count + parseInt(menu.value)),
                0
            );
        },
        nbMenusAvailable(exceptMenuId, isChildrenMenu = false) {
            const nbMenusAvailable = this.nb_pers_total - this.getNbChosenMenus(exceptMenuId);

            if (!isChildrenMenu) {
                return nbMenusAvailable;
            }

            return Math.min(nbMenusAvailable, this.reservation.nb_children - this.getNbChosenMenus(exceptMenuId, true));
        },
        slotSelected(slot, loadService = true) {
            if (slot) {
                if (loadService) this.loadService(slot.service_id);
                this.reservation.slot = slot;
            } else {
                this.reservation.slot = null;
            }
        },
        loadService(service_id, stop_loading = true) {
            this.available_menus = [];
            this.available_general_options = [];
            this.errorTryToPlaceInRoom = null;
            this.reservation.prefered_room = null;
            this.loadingMenus = true;
            this.reservation.choosen_menus = {};

            return axios
                .get(
                    `${API_URL}/widget/${
                        this.$api_key
                    }/services/${service_id}?include=menus,menus.menu_options,general_options,seating_plan_rooms,allow_choose_seating_plan_room&nb_pers_total=${
                        this.nb_pers_total
                    }&reservation_date=${this.reservation.reservation_date.toISODate()}`
                )
                .then((response) => {
                    this.reservation.service = response.data;
                    this.available_menus = this.reservation.service.menus.data;
                    this.available_general_options = this.reservation.service.general_options.data;
                    // check if full payment is needed (or at least a percent of payment)
                    if (this.nb_menus_choosen > 0 && this.reservation.service.prepayment_enabled) {
                        this.reservation.full_payment_needed = true;
                    }
                    this.loadingMenus = !stop_loading;
                })
                .catch((error) => {
                    this.loadingMenus = !stop_loading;
                    if (error.response && error.response.data.errors) this.$emit("set-form-errors", error.response.data.errors);
                });
        },
        checkUniqueMenu() {
            var menuAreUnique = false;
            if (this.reservation.service) {
                if (!this.reservation.service.menu_unique) {
                    return true;
                }

                if (this.reservation.service.menu_unique_from) {
                    menuAreUnique = this.nb_pers_total < this.reservation.service.menu_unique_from;
                }

                if (!menuAreUnique) {
                    let menuSelectedWithValue = false;

                    for (let index in this.reservation.choosen_menus) {
                        if (this.reservation.choosen_menus[index].value > 0) {
                            if (menuSelectedWithValue) {
                                return false;
                            }

                            menuSelectedWithValue = true;
                        }
                    }

                    return true;
                }

                return menuAreUnique;
            } else {
                return false;
            }
        },
        post(e) {
            if (e) e.preventDefault();

            let go_to_next_step = true;
            this.$emit("set-form-errors", []);

            if (
                this.reservation.service.menu_mandatory &&
                this.reservation.service.menu_mandatory_from <= this.nb_pers_total &&
                this.available_menus.length > 0
            ) {
                let nb_menu_selected = 0;
                for (let elem in this.reservation.choosen_menus) {
                    nb_menu_selected += this.reservation.choosen_menus[elem].value * 1;
                }
                if (nb_menu_selected != this.nb_pers_total) {
                    go_to_next_step = false;
                }
            }

            if (go_to_next_step) {
                if (this.checkUniqueMenu()) {
                    if (this.errorTryToPlaceInRoom) {
                        this.$emit("set-form-errors", [this.$tl("errors.seating_plan.no_room_chosen")]);
                    } else {
                        this.$emit("next-step");
                    }
                } else {
                    this.$emit("set-form-errors", [this.$tl("errors.menus.unique", { nb_pers_total: this.nb_pers_total })]);
                }
            } else {
                this.$emit("set-form-errors", [this.$tl("errors.menus.required")]);
            }
        },
        saveInWaitingList() {
            this.error_waiting_list = "";
            this.$emit("set-form-errors", []);

            if (!this.client.email) {
                this.error_waiting_list = this.$tl("errors.form.email.required");

                return;
            }

            this.loadingWaitingForm = true;

            axios
                .post(`${API_URL}/widget/${this.$api_key}/waitings`, {
                    ...this.client,
                    phone_number: this.client.phoneNumber,
                    phone_country: this.client.phoneCountry,
                    ...this.reservation,
                    service_category: this.reservation.service_category.fr,
                    service_category_en: this.reservation.service_category.en,
                    slot_id: this.slot_id,
                    cgv: this.cgv === "1" ? true : false,
                })
                .then(() => {
                    this.success_waiting_list = this.$tl("success.waitingList.registered");
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        this.$emit("set-form-errors", Object.values(error.response.data.errors).flat());
                    }
                })
                .finally(() => (this.loadingWaitingForm = false));
        },
        tryToPlaceInRoom() {
            this.loadingTryToPlaceInRoom = true;
            this.errorTryToPlaceInRoom = null;

            if (!this.reservation.prefered_room) {
                return;
            }

            axios
                .post(`${API_URL}/widget/${this.$api_key}/services/${this.reservation.service.id}/tryToPlaceInRoom`, {
                    reservation_date: this.reservation.reservation_date.toISODate(),
                    nb_pers: this.nb_pers_total,
                    slot_id: this.slot_id,
                    room_id: this.reservation.prefered_room.id,
                })
                .then((response) => {
                    if (!response.data.result) {
                        this.errorTryToPlaceInRoom = this.$tl("errors.seating_plan.room_full");
                    }
                })
                .catch((error) => {
                    this.errorTryToPlaceInRoom = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loadingTryToPlaceInRoom = false));
        },
        fetchSlotsNext(resetData = false) {
            this.loadingSlotsNext = true;

            if (resetData) {
                this.reservation_data.slots_next_dates = [];
                this.reservation_data.can_search_more_next_dates = false;
                this.slotNextStoppedAtDate = null;
            }

            axios
                .post(`${API_URL}/widget/${this.$api_key}/slotsNext`, {
                    date: this.reservation.reservation_date.toISODate(),
                    service_category: this.reservation.service_category.fr,
                    nb_pers: this.reservation.nb_pers,
                    nb_children: this.reservation.nb_children,
                    next_dates: this.reservation_data.slots_next_dates,
                    stopped_at_date: this.slotNextStoppedAtDate,
                })
                .then((response) => {
                    this.reservation_data.slots_next_dates = response.data.next_dates;
                    this.reservation_data.can_search_more_next_dates = response.data.can_search_more;
                    this.slotNextStoppedAtDate = response.data.stopped_at_date;
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        this.$emit("set-form-errors", Object.values(error.response.data.errors).flat());
                    }
                })
                .finally(() => (this.loadingSlotsNext = false));
        },
        slotsNextSelected() {
            this.reservation_data.slots_next_dates = [];
            this.reservation_data.service_full_option.value = this.reservation_data.service_full_option.othersDays;
            this.fetchSlotsNext(true);
        },
        getExternalDescriptionURL(m) {
            if (m.external_description_type === this.EXTERNAL_DESCRIPTION_LINK.value) return m.external_description.link;
            if (m.external_description_type === this.EXTERNAL_DESCRIPTION_FILE.value) return m.path_file ?? "";
            return "";
        },
    },
    watch: {
        "reservation.prefered_room": {
            deep: true,
            handler(newVal) {
                this.errorTryToPlaceInRoom = null;
                if (newVal) this.tryToPlaceInRoom();
            },
        },
        phone: {
            deep: true,
            handler(newVal) {
                if (newVal.isValid) {
                    this.client.phoneNumber = this.phone.value.international;
                    this.client.phoneCountry = this.phone.country;
                }
            },
        },
        reservation: {
            deep: true,
            handler(newVal) {
                if (newVal) this.$store.dispatch("reservation/setAll", newVal);
            },
        },
        reservation_data: {
            deep: true,
            handler(newVal) {
                if (newVal) this.$store.dispatch("reservationData/setAll", newVal);
            },
        },
        client: {
            deep: true,
            immediate: true,
            handler(newVal) {
                if (newVal) this.$store.dispatch("client/setClient", newVal);
            },
        },
        waiting_slot_id(newVal) {
            if (newVal) {
                const slot = this.reservation_data.slots_full.find((s) => s.id === newVal);
                if (slot) this.reservation.slot = slot;
            }
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.phone = {
            raw: this.client.phoneNumber || null,
            isValid: this.client.phoneNumber != null,
            country: this.client.phoneCountry || null,
            value: this.client.phoneNumber ? { international: this.client.phoneNumber } : null,
        };
        let nbRequiredElemFromUrl = 0;
        const obj = this.$utils.initFromUrl(["slot_hour", "slot_id", "step"]);
        if (obj.step && !isNaN(obj.step) && parseInt(obj.step) > 2) nbRequiredElemFromUrl++;
        if (obj.slot_hour || obj.slot_id) {
            const slot = this.slots.find((s) => s.hour_start == obj.slot_hour || s.id == obj.slot_id);
            if (slot) {
                this.reservation.slot = slot;
                this.loadService(slot.service_id, false).then(() => {
                    nbRequiredElemFromUrl++;
                    this.loadingMenus = false;
                    if (nbRequiredElemFromUrl >= 2) this.post();
                });
            }
        }
    },
};
</script>

<style scoped>
.menu-img,
.menu-option-img,
.general-option-img {
    max-width: 80px;
    margin-bottom: 0.5rem;
}

.v-align-top {
    vertical-align: top;
}
</style>
