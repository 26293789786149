export default {
    bgcolor: "#00000",
    stripe_client_id: null,
    name: "",
    widget: null,
    widget_font_title: "'Open Sans', sans-serif",
    owner_id: null,
    room_numbers: [],
    timezone: undefined,
    default_tel_country: "FR",
    feat_room_numbers: false,
    currency: "EUR",
    lexicon: "restaurant",
    customEvents: [],
    featWhiteLabeled: true, // Do not show provider until data has been fetched
};
