<template>
    <div id="msform">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <loader-component v-if="loading" />
        <div v-if="reservation && !loading" class="formContainer">
            <SelectLang
                v-if="!langFromScriptInclusion"
                style="top: auto"
                class="dropdown dropdown-lang align-content-end mr-2 mt-2"
                @change-lang="$emit('change-lang', $event)" />
            <h2 class="fs-title noshow-widget-font-title">{{ reservation.slot.service.restaurant.name }}</h2>
            <div v-if="!reservation.canCancel" class="alert alert-danger">{{ $tl("infos.cannotCancelReservation") }}</div>
            <div class="row border-light b-radius-20 pt-4 pb-4 mb-3 mt-3 ml-0 mr-0">
                <div
                    class="text-left"
                    :class="{
                        'col-md-7': reservation.qrCodeEnabled,
                        'col-12': !reservation.qrCodeEnabled,
                    }">
                    <div>
                        <div class="mb-2">
                            <strong class="noshow-widget-font-title">
                                {{ $tl("labels.reservation.details") }}
                            </strong>
                        </div>
                        <div>
                            {{
                                displayDate(reservation.reservation_datetime, {
                                    ...DateTime.DATETIME_FULL,
                                    timeZoneName: undefined,
                                })
                            }}
                        </div>
                        <div>{{ reservation.nb_pers }} {{ $tl("labels.option.person") }}<br /></div>
                        <div v-if="reservation.nb_children && reservation.nb_children > 0">
                            {{ reservation.nb_children }} {{ $tl("labels.option.children") }}<br />
                        </div>
                        <div v-if="reservation.menus.data && reservation.menus.data.length > 0">
                            <p class="mb-0 mt-2">{{ $tl("labels.option.choosenMenus") }}</p>
                            <ul>
                                <li v-for="(m, i) in reservation.menus.data" style="list-style-type: disc" :key="i">
                                    <p>{{ m.nb }} x {{ m.name }} ({{ formatCurrency(m.price, restaurantCurrency) }})</p>
                                    <ul v-if="m.menu_options && m.menu_options.length > 0">
                                        <li v-for="(o, io) in m.menu_options" :key="io">
                                            {{ o.nb }} x {{ $tl("labels.option.menuOption") }} {{ o.name }} ({{
                                                formatCurrency(o.price, restaurantCurrency)
                                            }})
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div v-if="reservation.general_options.data && reservation.general_options.data.length > 0">
                            <p class="mb-0 mt-2">{{ $tl("labels.option.choosenOptions") }}</p>
                            <ul>
                                <li v-for="(o, i) in reservation.general_options.data" style="list-style-type: disc" :key="i">
                                    <p>{{ o.nb }} x {{ o.name }} ({{ formatCurrency(o.price, restaurantCurrency) }})</p>
                                </li>
                            </ul>
                        </div>
                        <div
                            class="mt-1"
                            v-if="
                                !stripeIntentExpired &&
                                (reservation.status === RESERVATION_STATUS_REFUSED.value || reservation.status === RESERVATION_STATUS_CANCELED.value)
                            ">
                            <span v-if="reservation.status === RESERVATION_STATUS_REFUSED.value" class="text-danger">
                                {{ $tl("labels.reservation.status.refused") }}
                            </span>
                            <span v-else-if="reservation.status === RESERVATION_STATUS_CANCELED.value" class="text-danger">
                                {{ $tl("labels.reservation.status.canceled") }}
                            </span>
                        </div>
                        <div
                            v-else-if="
                                reservation.payment &&
                                reservation.payment.option_bank === OPTION_BANK_PAYMENT.value &&
                                reservation.payment.status === PAYMENT_STATUS_PENDING.value
                            ">
                            <p>
                                <strong
                                    >{{ $tl("labels.prepayment.amountToPay") }}
                                    {{ formatCurrency(reservation.payment.stripe_intent_amount / 100) }}</strong
                                >
                            </p>
                            <StripePaymentForm
                                v-if="stripeIntent"
                                buttonAdditionalClass="btn-color"
                                :reservation="reservation"
                                :stripeIntent="stripeIntent"
                                @payment-success="$emit('payment-success', $event)"
                                @footprint-success="$emit('footprint-success', $event)" />
                            <PayPlugPaymentForm
                                v-if="payplugIntent"
                                buttonAdditionalClass="btn-color"
                                :reservation="reservation"
                                :payplugIntent="payplugIntent"
                                @payment-success="$emit('payment-success', $event)"
                                @footprint-success="$emit('footprint-success', $event)" />
                        </div>
                        <div
                            v-else-if="
                                reservation.payment &&
                                reservation.payment.option_bank === OPTION_BANK_PAYMENT.value &&
                                reservation.payment.status === PAYMENT_STATUS_EXPIRED.value
                            ">
                            {{ $tl("labels.prepayment.expired") }}<br />
                            {{ reservation.status !== RESERVATION_STATUS_CANCELED.value ? $tl("labels.restaurant.contactForInfo") : "" }}
                        </div>
                        <div
                            v-else-if="
                                reservation.payment &&
                                reservation.payment.option_bank === OPTION_BANK_FOOTPRINT.value &&
                                reservation.payment.status === PAYMENT_STATUS_PENDING.value
                            ">
                            <br />
                            {{ $tl("labels.bankImprint.mustBeFilled") }}<br />
                            <small>
                                {{ $tl("labels.bankImprint.amountPerPersonToCapture") }}
                                {{ formatCurrency(reservation.amount_if_noshow / 100) }} </small
                            ><br />
                            <p>
                                <strong>
                                    {{ $tl("labels.bankImprint.totalBankImprint") }}
                                    {{ formatCurrency((reservation.amount_if_noshow / 100) * nb_pers_total) }}
                                </strong>
                            </p>
                            <StripePaymentForm
                                v-if="stripeIntent"
                                buttonLabel="Envoyer"
                                buttonAdditionalClass="btn-color"
                                :reservation="reservation"
                                :stripeIntent="stripeIntent"
                                @payment-success="$emit('payment-success', $event)"
                                @footprint-success="$emit('footprint-success', $event)" />
                            <PayPlugPaymentForm
                                v-if="payplugIntent"
                                buttonLabel="Saisir l'empreinte bancaire"
                                buttonAdditionalClass="btn-color"
                                :reservation="reservation"
                                :payplugIntent="payplugIntent"
                                @payment-success="$emit('payment-success', $event)"
                                @footprint-success="$emit('footprint-success', $event)" />
                        </div>
                        <div
                            v-else-if="
                                reservation.payment &&
                                reservation.payment.option_bank === OPTION_BANK_FOOTPRINT.value &&
                                reservation.payment.status === PAYMENT_STATUS_EXPIRED.value
                            ">
                            {{ $tl("labels.bankImprint.expired") }}<br />
                            {{ reservation.status !== RESERVATION_STATUS_CANCELED.value ? $tl("labels.restaurant.contactForInfo") : "" }}
                        </div>
                        <div
                            v-else-if="
                                reservation.payment &&
                                (reservation.payment.status === PAYMENT_STATUS_CAPTURABLE.value ||
                                    reservation.payment.status === PAYMENT_STATUS_CAPTURED.value)
                            ">
                            <span v-if="reservation.payment.option_bank === OPTION_BANK_FOOTPRINT.value">{{ $tl("labels.bankImprint.label") }} </span>
                            <span v-else-if="reservation.payment.has_receipt">{{ $tl("labels.prepayment.label") }} </span>
                            <span v-else>{{ $tl("labels.prepayment.labelPartial") }} </span>
                            {{ formatCurrency(reservation.payment.stripe_intent_amount / 100) }}
                        </div>
                        <div
                            v-if="
                                (reservation.status === RESERVATION_STATUS_VALIDATED.value ||
                                    reservation.status === RESERVATION_STATUS_OPTION.value) &&
                                reservation.confirmed_by_client !== null &&
                                reservation.confirmed_by_client === 0
                            ">
                            <div>
                                <p>{{ $tl("labels.reservation.status.toBeConfirmedByClient") }}</p>
                                <button
                                    class="btn btn-sm btn-success btn-color mb-2"
                                    @click="confirmResa($event)"
                                    style="text-transform: none !important">
                                    {{ $tl("labels.reservation.confirm") }}
                                </button>
                            </div>
                        </div>
                        <div v-if="showStatus">
                            {{ reservationStatus(reservation.status) }}
                        </div>
                        <div v-if="reservation.canCancel">
                            <div v-if="cancelableReservationStatus.some((s) => s.value === reservation.status)">
                                <button class="btn btn-danger btn-sm mt-4" @click="cancelResa()">
                                    {{ $tl("labels.reservation.cancel") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5" v-if="reservation.qrCodeEnabled">
                    <qrcode :value="reservation.token" :options="{ width: 160 }"></qrcode>
                </div>
                <div v-if="reservation.payment && reservation.payment.status === PAYMENT_STATUS_PENDING.value" class="col-12 text-left mt-1">
                    <i18n :path="`labels.form.${cguLabel}`" tag="span" class="link-widget">
                        <template slot="cgu">
                            <a :href="customCgu || `${base_url}/booking/widget/public/${this.$api_key}/cgv?lang=${currentLang}`" target="_blank">
                                {{ $tl("labels.form.CGU") }}
                            </a>
                        </template>
                        <template slot="pcdp">
                            <a :href="`${base_url}/booking/widget/public/${this.$api_key}/policy?lang=${currentLang}`" target="_blank">
                                {{ $tl("labels.form.PCDP") }}
                            </a>
                        </template>
                    </i18n>
                    <br />
                    <small class="text-muted">{{ $tl("labels.form.acceptCGUReason") }}</small>
                </div>
                <div class="col-12 text-left">
                    <div class="sepa"></div>
                    <div class="mb-2">
                        <strong class="noshow-widget-font-title">
                            {{ $tl("labels.restaurant.details") }}
                        </strong>
                    </div>
                    <div class="mb-2 text-uppercase">
                        <span>{{ reservation.slot.service.restaurant.name }}</span>
                    </div>
                    <div>
                        {{ reservation.slot.service.restaurant.formatted_address }}
                    </div>
                    <div>
                        {{ reservation.slot.service.restaurant.tel }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import Qrcode from "@chenfengyuan/vue-qrcode";
import axios from "axios";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum.js";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum.js";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import StripePaymentForm from "./PaymentForm/Stripe.vue";
import PayPlugPaymentForm from "./PaymentForm/PayPlug.vue";
import { DateTime } from "luxon";
import SelectLang from "../SelectLang.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            stripeIntent: null,
            payplugIntent: null,
            DateTime,
        };
    },
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        reservation_token: {
            type: String,
            required: true,
        },
        langFromScriptInclusion: {
            type: Boolean,
            required: true,
        },
    },
    mixins: [ReservationStatusEnum, PaymentStatusEnum, OptionBankEnum],
    computed: {
        cguLabel() {
            if (this.reservation.payment) {
                switch (this.reservation.payment.option_bank) {
                    case this.OPTION_BANK_FOOTPRINT.value:
                        return "acceptCGUAfterFootprint";
                    case this.OPTION_BANK_PAYMENT.value:
                        return "acceptCGUAfterPayment";
                }
            }

            return "";
        },
        base_url() {
            return NOSHOW_URL;
        },
        customCgu() {
            return this.$store.getters["restaurant/cgu"];
        },
        currentLang() {
            return this.$store.getters["client/lang"];
        },
        timezone() {
            return this.$store.getters["restaurant/timezone"];
        },
        nb_pers_total() {
            if (this.reservation) {
                const nb_pers = this.reservation.nb_pers || 0;
                const nb_children = this.reservation.nb_children || 0;
                return nb_pers + nb_children;
            }
            return 0;
        },
        showStatus() {
            return (
                this.reservation &&
                !(
                    (this.reservation.payment && this.reservation.payment.status === this.PAYMENT_STATUS_PENDING.value) ||
                    (this.reservation.confirmed_by_client !== null && this.reservation.confirmed_by_client === 0)
                )
            );
        },
        stripeIntentExpired() {
            return this.reservation && this.reservation.payment && this.reservation.payment.status === this.PAYMENT_STATUS_EXPIRED.value;
        },
        restaurantCurrency() {
            return this.$store.getters["restaurant/currency"];
        },
    },
    methods: {
        reservationStatus(status) {
            if (status === this.RESERVATION_STATUS_CREATED.value || status === this.RESERVATION_STATUS_OPTION.value) {
                return this.$tl("labels.reservation.status.created");
            } else if (status === this.RESERVATION_STATUS_VALIDATED.value) {
                return this.$tl("labels.reservation.status.validated");
            } else if (status === this.RESERVATION_STATUS_CONFIRMED.value) {
                return this.$tl("labels.reservation.status.confirmed");
            } else if (status === this.RESERVATION_STATUS_CANCELED.value || status === this.RESERVATION_STATUS_REFUSED.value) {
                return this.$tl("labels.reservation.status.canceled");
            } else if (status === this.RESERVATION_STATUS_NOSHOW.value) {
                return this.$tl("labels.reservation.status.noshow");
            } else if (status === "present" || status === this.RESERVATION_STATUS_OVER.value) {
                return this.$tl("labels.reservation.status.over");
            }
            return "";
        },
        initPaymentForm() {
            if (this.reservation && this.reservation.payment && this.reservation.payment.status === this.PAYMENT_STATUS_PENDING.value) {
                this.getCardSetupIntent();
            }
        },
        getCardSetupIntent() {
            this.loading = true;

            axios
                .get(`${API_URL}/widget/card/intent?token=${this.reservation_token}`)
                .then((response) => {
                    this.loading = false;
                    if (response.data.type && response.data.setup_intent) {
                        this[`${response.data.type}Intent`] = response.data.setup_intent;
                    } else {
                        this.error = this.$tl("errors.common.unknown");
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.displayErrorMsgFromErrorResponse(error);
                });
        },
        cancelResa() {
            if (!confirm("Êtes-vous sûr de vouloir annuler votre réservation ?")) return;

            this.loading = true;
            this.error = null;

            axios
                .put(`${API_URL}/widget/reservation/${this.reservation_token}`)
                .then(() => {
                    this.loading = false;
                    this.$emit("fetch-reservation");
                })
                .catch((error) => {
                    this.loading = false;
                    this.displayErrorMsgFromErrorResponse(error);
                });
        },
        confirmResa() {
            this.loading = true;
            this.error = null;

            axios
                .put(`${API_URL}/widget/reservation/${this.reservation_token}/confirm`)
                .then(() => {
                    this.$emit("resa-confirmed");
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.displayErrorMsgFromErrorResponse(error);
                });
        },
        displayErrorMsgFromErrorResponse(error) {
            if (error.response && error.response.data.message) this.error = error.response.data.message;
            else if (error.message) this.error = error.message;
            else this.error = this.$tl("errors.common.unknown");
        },
    },
    components: {
        LoaderComponent,
        Qrcode,
        StripePaymentForm,
        PayPlugPaymentForm,
        SelectLang,
    },
};
</script>
