export default {
    bgColor(state) {
        return state.bgcolor;
    },
    stripeClientId(state) {
        return state.stripe_client_id;
    },
    name(state) {
        return state.name;
    },
    widget(state) {
        return state.widget;
    },
    cgu(state) {
        return state.widget ? state.widget.cgu : null;
    },
    widgetFontTitle(state) {
        return state.widget_font_title;
    },
    ownerId(state) {
        return state.owner_id;
    },
    room_numbers(state) {
        return state.room_numbers;
    },
    timezone(state) {
        return state.timezone;
    },
    defaultTelCountry(state) {
        return state.default_tel_country;
    },
    feat_room_numbers(state) {
        return state.feat_room_numbers;
    },
    currency(state) {
        return state.currency;
    },
    lexicon(state) {
        return state.lexicon;
    },
    customEvents(state) {
        return state.customEvents;
    },
    featWhiteLabeled(state) {
        return state.featWhiteLabeled;
    },
};
