<template>
    <div>
        <div>
            <div id="stripeCardForm" ref="card"></div>
        </div>
        <div class="note-obligatoire text-left mt-3">
            <small class="text-muted" v-html="$tl('labels.bankImprint.stripeInfos', { stripe_url: 'https://stripe.com' })"> </small>
        </div>
        <div class="text-left mt-2 text-warning" v-if="threeDSMessage">
            <small>{{ threeDSMessage }}</small>
        </div>
        <div class="mt-4 text-center">
            <a href="#" @click="$emit('previous-step')" class="previous action-button-previous">{{ $tl("labels.back") }}</a>
            <button type="button" class="next action-button mt-4" @click="post">
                {{ $tl("labels.action.book") }}
            </button>
        </div>
    </div>
</template>

<script>
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum";

export default {
    data() {
        return {
            card: null,
            stripe: null,
            stripeInitTries: 0,
        };
    },
    mixins: [OptionBankEnum],
    props: {
        option_bank: {
            default: null,
        },
    },
    computed: {
        threeDSMessage() {
            if (this.option_bank === this.OPTION_BANK_PAYMENT.value) return this.$tl("labels.prepayment.paymentWaitingConfirmation");
            if (this.option_bank === this.OPTION_BANK_FOOTPRINT.value) return this.$tl("labels.bankImprint.paymentWaitingConfirmation");
            return undefined;
        },
        stripeSetupIntent() {
            return this.$store.getters["reservationData/stripe_setup_intent"];
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        client() {
            return this.$store.getters["client/client"];
        },
        stripe_client_id() {
            return this.$store.getters["restaurant/stripeClientId"];
        },
    },
    methods: {
        initStripe() {
            ++this.stripeInitTries;

            if (typeof Stripe === "undefined") {
                if (this.stripeInitTries < 3) {
                    setTimeout(() => {
                        this.initStripe();
                    }, 500);
                } else {
                    this.$emit("set-form-errors", [this.$tl("errors.form.payment")]);
                }

                return;
            }

            this.stripe = Stripe(STRIPE_API_KEY, { stripeAccount: this.stripe_client_id });

            this.card = this.stripe.elements().create("card");
            this.card.mount(this.$refs.card);
        },
        post(e) {
            if (e) {
                e.preventDefault();
            }

            if (this.stripe === null) {
                alert(this.$tl("errors.form.payment"));

                return;
            }

            this.$emit("set-loading", true);

            this.stripe
                .handleCardPayment(this.stripeSetupIntent.client_secret, this.card, {
                    payment_method_data: {
                        billing_details: { name: this.client.lastname },
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.$emit("set-loading", false);
                        this.$emit("set-form-errors", [result.error.message]);
                    } else this.$emit("next-step");
                })
                .catch((error) => {
                    this.$emit("set-loading", false);
                    this.$emit("set-form-errors", [error.message]);
                });
        },
    },
    mounted() {
        this.initStripe();
    },
};
</script>
