var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "datepicker",
    _vm._g(
      _vm._b(
        {
          ref: "datePicker",
          attrs: {
            format: _vm.customDateFormater,
            "monday-first": _vm.isUserLangFr,
            highlighted: _vm.highlighted,
            language: _vm.datePickerLanguage,
          },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v
            },
            expression: "date",
          },
        },
        "datepicker",
        _vm.$attrs,
        false
      ),
      _vm.filteredListeners
    ),
    [
      _vm.showClearButton_ && _vm.date !== null
        ? _c("feather", {
            staticClass: "mr-1 text-danger pointer",
            staticStyle: { position: "absolute", top: "10px", right: "0" },
            attrs: { slot: "afterDateInput", type: "x" },
            on: {
              click: function ($event) {
                _vm.date = null
              },
            },
            slot: "afterDateInput",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }