export default {
    data() {
        return {
            EXTERNAL_DESCRIPTION_FILE: {
                value: "file",
            },
            EXTERNAL_DESCRIPTION_LINK: {
                value: "link",
            },
        };
    },
    computed: {
        ALL_EXTERNAL_DESCRIPTION_TYPES() {
            return [this.EXTERNAL_DESCRIPTION_FILE.value, this.EXTERNAL_DESCRIPTION_LINK.value];
        },
    },
};
