var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notranslate",
      class: _vm.layoutClass,
      staticStyle: { "box-sizing": "border-box" },
      attrs: { translate: "no" },
    },
    [
      _vm.inMaintenance
        ? _c("MaintenanceError")
        : _vm.hasLicenceError
        ? _c("LicencesErrors")
        : _c(`${_vm.layout}Layout`, {
            key: _vm.currentWidgetKey,
            tag: "component",
            attrs: {
              globalLang: _vm.globalLang,
              langFromScriptInclusion: _vm.langFromScriptInclusion,
            },
            on: { remount: _vm.remount, "change-lang": _vm.changeLang },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }