<template>
    <div>
        <div class="alert alert-danger" v-if="fatalError">
            {{ fatalError }}
        </div>
        <form v-else class="text-center" action="" method="post" @submit="showPaymentForm" novalidate>
            <a href="#" @click="$emit('previous-step')" class="previous action-button-previous">{{ $tl("labels.back") }}</a>
            <input type="submit" :value="$tl('labels.action.pay')" class="next action-button" />
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fatalError: null,
            isPaid: false,
        };
    },
    computed: {
        payplugSetupIntent() {
            return this.$store.getters["reservationData/payplug_setup_intent"];
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        client() {
            return this.$store.getters["client/client"];
        },
    },
    methods: {
        showPaymentForm(e) {
            if (e) e.preventDefault();
            Payplug.showPayment(this.payplugSetupIntent.hosted_payment.payment_url);
        },
    },
    mounted() {
        if (!Payplug || !this.payplugSetupIntent || !this.payplugSetupIntent.hosted_payment || !this.payplugSetupIntent.hosted_payment.payment_url) {
            this.fatalError = "Can't init PayPlug";
            return;
        }
        Payplug._listen("message", window, (e) => {
            if (typeof e.data === "string") {
                if (e.data === "closePayPlugFrame" && this.isPaid) {
                    this.$emit("next-step");
                }
            } else if (typeof e.data === "object") {
                if (e.data.event === "paidByPayPlug" || e.data.event === "setRedirectionUrl") {
                    this.isPaid = true;
                    this.$emit("prevent-refresh-page");
                }
            }
        });
    },
};
</script>
