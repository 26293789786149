export default {
    data() {
        return {
            EXTERNAL_MENU_FILE: {
                value: "file",
            },
            EXTERNAL_MENU_LINK: {
                value: "link",
            },
        };
    },
    computed: {
        ALL_EXTERNAL_MENU_TYPES() {
            return [this.EXTERNAL_MENU_FILE.value, this.EXTERNAL_MENU_LINK.value];
        },
    },
};
