import state from "./state";

export default {
    setReservationDate(state, reservation_date) {
        state.reservation_date = reservation_date;
    },
    setSavedInWaitingList(state) {
        state.saved_in_waiting_list = true;
    },
    setServiceCategory(state, service_category) {
        state.service_category = service_category;
    },
    setOptionBank(state, option_bank) {
        state.option_bank = option_bank;
    },
    setNbPers(state, nb_pers) {
        state.nb_pers = nb_pers;
    },
    setNbChildren(state, nb_children) {
        state.nb_children = nb_children;
    },
    setSlot(state, slot) {
        state.slot = slot;
    },
    setService(state, service) {
        state.service = service;
    },
    setChoosenMenus(state, choosen_menus) {
        state.choosen_menus = choosen_menus;
    },
    setFullPaymentNeeded(state, full_payment_needed) {
        state.full_payment_needed = full_payment_needed;
    },
    setGift(state, gift) {
        state.gift = gift;
    },
    setComment(state, comment) {
        state.comment = comment;
    },
    setIsPrepaymentByClient(state, isPrepaymentByClient) {
        state.isPrepaymentByClient = isPrepaymentByClient;
    },
    setChoosenGeneralOptions(state, choosen_general_options) {
        state.choosen_general_options = choosen_general_options;
    },
    setPreferedRoom(state, prefered_room) {
        state.prefered_room = prefered_room;
    },
    setRoomNumber(state, room_number) {
        state.room_number = room_number;
    },
};
