export default {
    setLang({ commit }, lang) {
        commit("setLang", lang);
    },
    setClient({ commit }, client) {
        commit("setFirstName", client.firstname);
        commit("setLastName", client.lastname);
        commit("setEmail", client.email);
        commit("setPhoneNumber", client.phoneNumber);
        commit("setPhoneCountry", client.phoneCountry);
        commit("setCompany", client.company);
        commit("setCivility", client.civility);
    },
    setFirstName({ commit }, firstname) {
        commit("setFirstName", firstname);
    },
    setLastName({ commit }, lastname) {
        commit("setLastName", lastname);
    },
    setEmail({ commit }, email) {
        commit("setEmail", email);
    },
    setPhoneNumber({ commit }, phoneNumber) {
        commit("setPhoneNumber", phoneNumber);
    },
    setPhoneCountry({ commit }, phoneCountry) {
        commit("setPhoneCountry", phoneCountry);
    },
    setCompany({ commit }, company) {
        commit("setCompany", company);
    },
    setCivility({ commit }, civility) {
        commit("setCivility", civility);
    },
};
