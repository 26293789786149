export default {
    common: {
        network: "Veuillez vérifier votre connexion internet",
        unknown: "Une erreur est survenue",
        reservation_not_found: "Désolé, la réservation est introuvable",
        maintenance: "Le widget est actuellement indisponible. Merci de réessayer plus tard.",
        widgetDisabled: "Le widget est désactivé pour cet établissement.",
    },
    menus: {
        unique: "Vous ne pouvez choisir qu'une seule prestation pour les {nb_pers_total} personnes",
        required: "Veuillez choisir des prestations pour toutes les personnes",
    },
    general: {
        fillAllFields: "Veuillez remplir tous les champs",
    },
    form: {
        payment: "Une erreur est survenue lors de l'initialisation du formulaire de paiement",
        email: {
            required: "Veuillez renseigner une adresse mail",
        },
    },
    commands: {
        add: {
            no_slots: "Aucun créneau disponible pour ce jour.",
        },
    },
    address: {
        no_match: "Aucune correspondance",
        no_one_selected: "Merci de saisir une adresse dans la liste",
        no_deliverable: "Aucune zone de livraison ne couvre cette adresse",
    },
    seating_plan: {
        room_full: "Désolé, cette salle est complète pour cette plage horaire",
        no_room_chosen: "Merci de sélectionner un choix de salle valide",
    },
};
