var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", { domProps: { innerHTML: _vm._s(_vm.custom_css) } }),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "padding-top": "20px" } },
      [
        _vm.loading ? _c("loader-component") : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading",
              },
            ],
          },
          [
            _vm.reservation && !_vm.hasSuccess
              ? _c("EditReservationComponent", {
                  ref: "editReservationComponent",
                  attrs: {
                    langFromScriptInclusion: _vm.langFromScriptInclusion,
                    reservation_token: _vm.reservation_token,
                    reservation: _vm.reservation,
                  },
                  on: {
                    "fetch-reservation": _vm.fetchReservation,
                    "payment-success": _vm.onPaymentSuccess,
                    "footprint-success": _vm.onFootprintSuccess,
                    "resa-confirmed": _vm.onConfirmationSuccess,
                    "change-lang": function ($event) {
                      return _vm.$emit("change-lang", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.reservation && _vm.hasSuccess
              ? _c("EditReservationSuccessComponent", {
                  ref: "editReservationSuccessComponent",
                  attrs: {
                    langFromScriptInclusion: _vm.langFromScriptInclusion,
                    reservation_token: _vm.reservation_token,
                    reservation: _vm.reservation,
                    type: _vm.successType,
                    amount: _vm.paymentAmount,
                  },
                  on: {
                    "change-lang": function ($event) {
                      return _vm.$emit("change-lang", $event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }