import { DateTime } from "luxon";

DateTime.prototype.toJSDateCustom = function () {
    return new Date(this.year, this.month - 1, this.day, this.hour, this.minute);
};

/*
(Infos)

export const DATETIME_HUGE_WITH_SECONDS = {
  year: n,
  month: l,
  day: n,
  weekday: l,
  hour: n,
  minute: n,
  second: n,
  timeZoneName: l,
};

*/

const n = "numeric";
const s = "short";
const l = "long";

export default {
    data() {
        // Define custom presets for formatting
        return {
            TIME_SIMPLE: DateTime.TIME_SIMPLE, // FR: hh:mm
            DATE_SHORT: DateTime.DATE_SHORT, // FR: DD/MM/YYYY
            DATE_SHORT_DAY_NO_YEAR: {
                // FR: Day MM
                ...DateTime.DATE_SHORT,
                weekday: l,
                year: undefined,
            },
            DATE_MED: DateTime.DATE_MED, // FR: DD Mo. YYYY
            DATE_MED_DAY_NO_YEAR: {
                // FR: Day DD Mo.
                ...DateTime.DATE_MED,
                weekday: l,
                year: undefined,
            },
            DATE_FULL: DateTime.DATE_FULL, // FR: DD Month YYYY
            DATE_FULL_NO_YEAR: {
                // FR: DD Month
                ...DateTime.DATE_FULL,
                year: undefined,
            },
            DATE_HUGE: DateTime.DATE_HUGE, // FR: Day DD Month YYYY
            DATE_HUGE_NO_YEAR: {
                // Day DD Month
                ...DateTime.DATE_HUGE,
                year: undefined,
            },
            DATETIME_SHORT: DateTime.DATETIME_SHORT, // FR: DATE_SHORT à TIME_SIMPLE
            DATETIME_MED: DateTime.DATETIME_MED, // FR: DATE_MED à TIME_SIMPLE
            DATETIME_FULL: {
                // FR: DATE_FULL à TIME_SIMPLE
                ...DateTime.DATETIME_FULL,
                timeZoneName: undefined,
            },
            DATETIME_HUGE: {
                // FR: DATE_HUGE à TIME_SIMPLE
                ...DateTime.DATETIME_HUGE,
                timeZoneName: undefined,
            },
            DATETIME_SHORT_DAY: {
                // FR: Day DATETIME_SHORT
                ...DateTime.DATETIME_SHORT,
                weekday: l,
            },
        };
    },
    methods: {
        displayDate(date = undefined, format = undefined, keepOriginalTimeZone = true, timeZone = undefined) {
            if (typeof format === "undefined" || format === null) {
                format = this.DATE_FULL;
            }
            if (typeof format === "string") {
                return this.getDateTime(date, keepOriginalTimeZone, timeZone).toFormat(format);
            }
            if (typeof format === "object") {
                return this.getDateTime(date, keepOriginalTimeZone, timeZone).toLocaleString(format);
            }
            console.error(`Invalid format given in DateUtils::displayDate: "${format}", typeof "${typeof format}"`);
            return date;
        },
        getDateTime(date = undefined, keepOriginalTimeZone = true, timeZone = undefined) {
            const tz = timeZone || this.timezone;
            if (typeof date === "undefined" || date === null) {
                date = DateTime.now();
            } else if (!(date instanceof DateTime)) {
                if (typeof date === "string") {
                    if (keepOriginalTimeZone) {
                        date = DateTime.fromISO(date);
                    } else {
                        date = DateTime.fromISO(date.split("T")[0], {
                            zone: tz,
                        });
                    }
                } else if (date instanceof Date) {
                    if (keepOriginalTimeZone) {
                        date = DateTime.fromJSDate(date);
                    } else {
                        date = DateTime.fromObject(
                            {
                                day: date.getDate(),
                                month: date.getMonth() + 1,
                                year: date.getFullYear(),
                            },
                            {
                                zone: tz,
                            }
                        );
                    }
                } else {
                    console.error(`Could not transform date to luxon DateTime : "${date}", typeof "${typeof date}`);
                    return date;
                }
            }
            return date.setZone(tz);
        },
        getJSDateFromDateTime(date = undefined) {
            if (typeof date === "undefined" || date === null) {
                date = this.getDateTime();
            }
            if (!(date instanceof DateTime)) {
                console.error(`Could not transform date to JS Date object : "${date}", typeof "${typeof date}`);
                return date;
            }
            return date.toJSDateCustom();
        },
        englishDays() {
            return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        },
    },
    computed: {
        timezone() {
            return this.$store.getters["restaurant/timezone"];
        },
    },
};
