<template>
    <div v-if="!hasFeatWhileLabeled" class="w-100 text-center">
        {{ $tl("labels.widgetProvidedBy") }}<br />
        <img :src="`${NOSHOW_URL}/images/logo-lead-extend.png`" class="provider-logo" />
    </div>
</template>

<script>
export default {
    computed: {
        hasFeatWhileLabeled() {
            return this.$store.getters["restaurant/featWhiteLabeled"];
        },
    },
    created() {
        this.NOSHOW_URL = NOSHOW_URL;
    },
};
</script>
