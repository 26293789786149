<template>
    <div>
        <div class="alert alert-danger" v-if="fatalError">
            {{ fatalError }}
        </div>
        <template v-else>
            <loader-component v-if="payplugLoading" />
            <button
                class="btn btn-sm btn-success"
                :class="buttonAdditionalClass"
                v-show="payplugGenerated"
                :disabled="payplugLoading"
                @click="handleCardPayment">
                {{ buttonLabel }}
            </button>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import OptionBankEnum from "../../../mixins/enums/booking/OptionBankEnum.js";

export default {
    data() {
        return {
            fatalError: null,
            payplugGenerated: false,
            payplugLoading: false,
            isPaid: false,
        };
    },
    mixins: [OptionBankEnum],
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        payplugIntent: {
            type: Object,
            required: true,
        },
        buttonLabel: {
            type: String,
            default: "Payer",
        },
        buttonAdditionalClass: {
            type: String,
            default: "",
        },
    },
    methods: {
        init() {
            if (
                !Payplug ||
                !this.payplugIntent ||
                !this.payplugIntent.hosted_payment ||
                !this.payplugIntent.hosted_payment.payment_url
            ) {
                this.fatalError = "Can't init PayPlug";
                return;
            }
            Payplug._listen("message", window, (e) => {
                if (typeof e.data === "string") {
                    if (e.data === "closePayPlugFrame" && this.isPaid) {
                        this.onPaymentSuccess();
                    }
                } else if (typeof e.data === "object") {
                    if (e.data.event === "paidByPayPlug" || e.data.event === "setRedirectionUrl") {
                        this.isPaid = true;
                    }
                }
            });
            this.payplugGenerated = true;
        },
        handleCardPayment() {
            Payplug.showPayment(this.payplugIntent.hosted_payment.payment_url);
        },
        onPaymentSuccess() {
            if (this.reservation.payment.option_bank === this.OPTION_BANK_PAYMENT.value)
                this.$emit("payment-success", this.reservation.payment.stripe_intent_amount);
            else if (this.reservation.payment.option_bank === this.OPTION_BANK_FOOTPRINT.value)
                this.$emit("footprint-success");
        },
    },
    components: {
        LoaderComponent,
    },
    mounted() {
        this.init();
    },
};
</script>
