export default {
    seeExternalMenu: "See menu",
    reservation: {
        serviceRequired: "Service *",
        noServiceForThisDate: "No service is available for this date.",
    },
    option: {
        chooseMenu: "Choice of menus",
        choosenMenus: "Menus selected:",
        childrenMenu: "Children's menu",
        menuIsUnique: "A single menu is requested for the whole table",
    },
    prepayment: {
        paymentMenusMandatory: "To validate and guarantee your reservation, payment for the selected menus and options is required.",
        downPayment:
            "To validate and guarantee your reservation, a deposit of {percentage}% of the total of the selected menus and options is required.",
    },
    full: {
        serviceFull: "Our establishment is fully booked for this service.",
    },
};
