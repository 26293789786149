import Vue from "vue";
import Package from "../package.json";
import App from "./App.vue";
import store from "./store/store.js";
import VueI18n from "vue-i18n";
import VTooltip from "v-tooltip";
import i18n from "./plugins/i18n";
import lodash from "lodash";
import utils from "./plugins/utils.js";
import axios from "axios";
import BaseModal from "./components/Modals/BaseModal.vue";
import ErrorsInterceptor from "./axios/Interceptors/ErrorsInterceptor";
import VueTelInput from "vue-tel-input";
import DateUtilsHelper from "./mixins/utils/DateUtils.js";
import UtilsHelper from "./mixins/utils/Utils.js";
import VueFeather from "vue-feather";

require("bootstrap");

/**
 * Add X-App-Version header
 */
const appVersion = Package.version;
if (appVersion) {
    axios.defaults.headers.common["X-App-Version"] = appVersion;
} else {
    console.error(`${THEME} app version could not be found`);
}

import "./scss/bootstrap.min.scss";
import "./scss/style.scss";
import { initSentry } from "./plugins/sentry.js";

const initFunction = function (id, token, params = {}) {
    if (typeof id === "undefined" || typeof token === "undefined") {
        if (THEME === "noshow") {
            throw "NoShow Booking Error : missing required args 'id' or 'token' in 'initNoShowBooking' function";
        } else {
            throw "Y-Services Booking Error : missing required args 'id' or 'token' in 'initYServicesBooking' function";
        }
    }

    Vue.prototype.$__BUILD_ID__ = BUILD_ID;
    Vue.use(VueI18n);
    Vue.use(VTooltip);
    Vue.prototype.$_ = lodash;
    Vue.use(lodash);
    Vue.use(utils);
    Vue.use(VueTelInput);
    Vue.use(VueFeather);

    Vue.mixin(UtilsHelper);
    Vue.mixin(DateUtilsHelper);

    Vue.component("modal", BaseModal);

    token = encodeURIComponent(token);
    Vue.prototype.$api_key = token;
    var storeObject = store(token);
    storeObject.dispatch("widget/setInitParams", params);
    ErrorsInterceptor(storeObject);
    var app = new Vue({
        el: `#${id}`,
        i18n,
        store: storeObject,
        render: (h) => h(App),
    });

    if (ENABLE_SENTRY) {
        initSentry(BUILD_ID);
    }
};

if (THEME === "noshow") {
    window.initNoShowBooking = initFunction;
} else {
    window.initYServicesBooking = initFunction;
}
