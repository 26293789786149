var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { attrs: { id: "progressbar" } }, [
    _c(
      "li",
      {
        class: { active: _vm.step >= 1 },
        on: {
          click: function ($event) {
            return _vm.changeStep(1)
          },
        },
      },
      [
        _c("span", { staticClass: "none-mobile" }, [
          _vm._v(_vm._s(_vm.$tl("labels.step.reservation"))),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        class: { active: _vm.step >= 2 },
        on: {
          click: function ($event) {
            return _vm.changeStep(2)
          },
        },
      },
      [
        _c("span", { staticClass: "none-mobile" }, [
          _vm._v(_vm._s(_vm.$tl("labels.step.options"))),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        class: { active: _vm.step >= 3 },
        on: {
          click: function ($event) {
            return _vm.changeStep(3)
          },
        },
      },
      [
        _c("span", { staticClass: "none-mobile" }, [
          _vm._v(_vm._s(_vm.$tl("labels.step.informations"))),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        class: { active: _vm.step >= 4 },
        on: {
          click: function ($event) {
            return _vm.changeStep(4)
          },
        },
      },
      [
        _vm.option_bank === _vm.OPTION_BANK_PAYMENT.value
          ? _c("div", [
              _c("span", { staticClass: "none-mobile" }, [
                _vm._v(_vm._s(_vm.$tl("labels.step.prepayment"))),
              ]),
            ])
          : _vm.footprintRequiredNow
          ? _c("div", [
              _c("span", { staticClass: "none-mobile" }, [
                _vm._v(_vm._s(_vm.$tl("labels.step.bankImprint"))),
              ]),
            ])
          : _c("div", [
              _c("span", { staticClass: "none-mobile" }, [
                _vm._v(_vm._s(_vm.$tl("labels.step.confirmation"))),
              ]),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }