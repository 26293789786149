import labels from "./labels.json";
import errors from "./errors.json";
import success from "./success.json";
import lexicons from "./lexicons";
import infos from "./infos.json";

export default {
    labels,
    errors,
    success,
    lexicons,
    infos,
};
