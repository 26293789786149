var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loader-color loader-main-div text-center mt-4 mb-4" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "text-muted" }, [_vm._v(_vm._s(_vm.message_))]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ring mb-2" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }