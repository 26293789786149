var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column align-items-center justify-content-center",
    },
    [
      _c("h5", [
        _vm._v(
          "\n        " + _vm._s(_vm.$tl("errors.common.maintenance")) + "\n    "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }