export default {
    reservation: {
        serviceRequired: "Game *",
        noServiceForThisDate: "No game is available for this date.",
        lessTenYears: "Under 11 years",
    },
    full: {
        serviceFull: "Our establishment is fully booked for this game.",
    },
};
