<template>
    <div>
        <button
            style="text-transform: capitalize; border-color: #ededed"
            class="btn btn-sm btn-outline-secondary dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            {{ currentLang }}
        </button>
        <div
            class="dropdown-menu dropdown-menu-right shadow"
            style="transform: translate3d(-69px, 29px, 0px); min-width: 100px"
            aria-labelledby="dropdownMenu2">
            <button
                class="dropdown-item d-flex pl-1"
                type="button"
                v-for="lang in ALL_WIDGET_LANGS"
                :key="lang.value"
                @click="$emit('change-lang', lang.value)">
                <country-flag class="dropdown-item" :country="lang.countryFlag" />
                <span class="name-lang">{{ lang.dropdownLabel }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import WidgetLangsEnum from "../mixins/enums/WidgetLangsEnum.js";
import CountryFlag from "vue-country-flag";

export default {
    mixins: [WidgetLangsEnum],
    computed: {
        currentLang() {
            return this.$store.getters["client/lang"];
        },
    },
    components: {
        CountryFlag,
    },
};
</script>
