var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadingForm
        ? _c("LoaderComponent")
        : _c("form", { attrs: { method: "post" }, on: { submit: _vm.post } }, [
            _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "slot_btn_select radio-widget text-left" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "container-box",
                        staticStyle: { width: "initial" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.client.civility,
                              expression: "client.civility",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "civility",
                            value: "monsieur",
                            required: "required",
                          },
                          domProps: {
                            checked: _vm._q(_vm.client.civility, "monsieur"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.firstname.focus()
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.client,
                                "civility",
                                "monsieur"
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$tl("labels.form.man")) +
                            " *\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "container-box",
                        staticStyle: { width: "initial" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.client.civility,
                              expression: "client.civility",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "civility",
                            value: "madame",
                            required: "required",
                          },
                          domProps: {
                            checked: _vm._q(_vm.client.civility, "madame"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.firstname.focus()
                            },
                            change: function ($event) {
                              return _vm.$set(_vm.client, "civility", "madame")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$tl("labels.form.woman")) +
                            " *\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "div-widget-infos" }, [
              _c("div", { staticClass: "w-100 widget-infos-firstname" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.client.firstname,
                      expression: "client.firstname",
                    },
                  ],
                  ref: "firstname",
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "firstname",
                    maxlength: "50",
                    placeholder: _vm.$tl("labels.form.firstName") + " *",
                    required: "required",
                  },
                  domProps: { value: _vm.client.firstname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.client, "firstname", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-100 widget-infos-lastname" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.client.lastname,
                      expression: "client.lastname",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "lastname",
                    maxlength: "50",
                    placeholder: _vm.$tl("labels.form.lastName") + " *",
                    required: "required",
                  },
                  domProps: { value: _vm.client.lastname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.client, "lastname", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.widget.enable_society
                ? _c("div", { staticClass: "w-100 widget-infos-company" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.client.company,
                          expression: "client.company",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "company",
                        placeholder: _vm.$tl("labels.form.company"),
                      },
                      domProps: { value: _vm.client.company },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.client, "company", $event.target.value)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6 pt-1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.client.email,
                      expression: "client.email",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: _vm.$tl("labels.form.email") + " *",
                    required: "required",
                  },
                  domProps: { value: _vm.client.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.client, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-6 pt-1" },
                [
                  _c("vue-tel-input", {
                    staticClass: "mb-2",
                    attrs: {
                      name: "telInput",
                      defaultCountry: _vm.defaultTelCountry,
                      enabledCountryCode: true,
                      wrapperClasses:
                        _vm.phone.isValid === null || _vm.phone.isValid
                          ? ""
                          : "border border-danger",
                      inputClasses: "m-0 border-0",
                      mode: "international",
                      placeholder: _vm.$tl("labels.form.phone") + " *",
                      required: "required",
                    },
                    on: { validate: _vm.setPhoneAndCountry },
                    model: {
                      value: _vm.phone.raw,
                      callback: function ($$v) {
                        _vm.$set(_vm.phone, "raw", $$v)
                      },
                      expression: "phone.raw",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.isFeatRoomNumbersEnable
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 pt-1" }, [
                    _c(
                      "label",
                      { staticClass: "mb-0 noshow-widget-font-title" },
                      [
                        _vm._v(_vm._s(_vm.$tl("labels.form.roomNumber")) + " "),
                        _c("small", [_vm._v("*")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reservation.room_number,
                          expression: "reservation.room_number",
                        },
                      ],
                      staticClass: "form-control mt-2",
                      attrs: {
                        type: "text",
                        name: "room_number",
                        required: "",
                      },
                      domProps: { value: _vm.reservation.room_number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.reservation,
                            "room_number",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.roomNumbersForAutocomplete && !_vm.isRoomNumberValid
                      ? _c(
                          "div",
                          { staticClass: "room-dropdown" },
                          _vm._l(
                            _vm.roomNumbersForAutocomplete,
                            function (roomNumber) {
                              return _c(
                                "span",
                                {
                                  key: roomNumber.id,
                                  staticClass: "d-block pointer text-left",
                                  on: {
                                    click: function ($event) {
                                      _vm.reservation.room_number =
                                        roomNumber.number
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(roomNumber.number) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.roomNumbers.length > 0 &&
                    !_vm.isRoomNumberValid &&
                    _vm.reservation.room_number !== null &&
                    _vm.reservation.room_number.length > 0 &&
                    _vm.roomNumbersForAutocomplete.length === 0
                      ? _c("span", { staticClass: "text-danger d-block" }, [
                          _vm._v(
                            "\n                    Aucune chambre n'a été trouvée\n                "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.widget.enable_gift && _vm.widget.enable_gift == 1
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 pt-1" }, [
                    _c(
                      "label",
                      { staticClass: "mb-0 noshow-widget-font-title" },
                      [_vm._v(_vm._s(_vm.$tl("labels.option.gift")))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-left mb-2" }, [
                      _c("small", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "labels.reservation.bookWithGiftGiveReference"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reservation.gift,
                          expression: "reservation.gift",
                        },
                      ],
                      staticClass: "form-control mt-2",
                      attrs: { type: "text", name: "gift" },
                      domProps: { value: _vm.reservation.gift },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.reservation, "gift", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.widget.comment_enabled
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 pt-1" }, [
                    _c(
                      "label",
                      { staticClass: "mb-0 noshow-widget-font-title" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$tl("labels.action.commentForRestorantOwner")
                          ) + "\n                    "
                        ),
                        _vm.widget.comment_mandatory
                          ? _c("span", { staticClass: "required-mark" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.widgetCommentLabel
                      ? _c("div", { staticClass: "text-left" }, [
                          _c("small", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.widgetCommentLabel) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reservation.comment,
                          expression: "reservation.comment",
                        },
                      ],
                      staticClass: "form-control mt-2",
                      attrs: {
                        required: _vm.widget.comment_mandatory === 1,
                        name: "comment",
                        rows: "4",
                      },
                      domProps: { value: _vm.reservation.comment },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.reservation,
                            "comment",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isOptionalPaymentEnabled &&
            (!_vm.reservation.gift || _vm.reservation.gift === "")
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 pt-1" }, [
                    _c("div", { staticClass: "form-group text-left mb-0" }, [
                      _c(
                        "label",
                        { staticClass: "mb-0 noshow-widget-font-title" },
                        [
                          _vm._v(
                            _vm._s(_vm.$tl("labels.prepayment.payment")) + " "
                          ),
                          _c("span", { staticClass: "required-mark" }, [
                            _vm._v("*"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "slot_btn_select radio-widget pt-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "container-box",
                              staticStyle: { width: "initial" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.isPrepaymentByClient,
                                    expression:
                                      "reservation.isPrepaymentByClient",
                                  },
                                ],
                                attrs: {
                                  name: "optionalPayment",
                                  type: "radio",
                                  value: "1",
                                  required: "required",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.reservation.isPrepaymentByClient,
                                    "1"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.reservation,
                                      "isPrepaymentByClient",
                                      "1"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$tl("labels.prepayment.prepay", {
                                      percentage:
                                        _vm.reservation.service
                                          .prepayment_percent,
                                      total: _vm.formatCurrency(
                                        _vm.totalAmount
                                      ),
                                    })
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "container-box",
                              staticStyle: { width: "initial" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.isPrepaymentByClient,
                                    expression:
                                      "reservation.isPrepaymentByClient",
                                  },
                                ],
                                attrs: {
                                  name: "optionalPayment",
                                  type: "radio",
                                  value: "0",
                                  required: "required",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.reservation.isPrepaymentByClient,
                                    "0"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.reservation,
                                      "isPrepaymentByClient",
                                      "0"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tl("labels.prepayment.payAtRestaurant")
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col-12 checkbox-widget" }, [
                _c(
                  "label",
                  {
                    staticClass: "container-box",
                    staticStyle: { width: "100%", "line-height": "1.3" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cgv,
                          expression: "cgv",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        required: "required",
                        "true-value": "1",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.cgv)
                          ? _vm._i(_vm.cgv, null) > -1
                          : _vm._q(_vm.cgv, "1"),
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.cgv,
                            $$el = $event.target,
                            $$c = $$el.checked ? "1" : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.cgv = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.cgv = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.cgv = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" }),
                    _vm._v(" "),
                    _c(
                      "i18n",
                      {
                        staticClass: "link-widget",
                        attrs: {
                          path: "labels.form.acceptCGUAfterBooking",
                          tag: "span",
                        },
                      },
                      [
                        _c("template", { slot: "cgu" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  _vm.customCgu ||
                                  `${_vm.base_url}/booking/widget/public/${this.$api_key}/cgv?lang=${_vm.currentLang}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$tl("labels.form.CGU")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("template", { slot: "pcdp" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.base_url}/booking/widget/public/${this.$api_key}/policy?lang=${_vm.currentLang}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$tl("labels.form.PCDP")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$tl("labels.form.acceptCGUReason"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-0 text-center" }, [
              _c(
                "a",
                {
                  staticClass: "previous action-button-previous",
                  attrs: { href: "#" },
                  on: { click: _vm.previousStep },
                },
                [_vm._v(_vm._s(_vm.$tl("labels.back")))]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "next action-button mt-2",
                attrs: { type: "submit" },
                domProps: {
                  value:
                    _vm.option_bank === _vm.OPTION_BANK_PAYMENT.value
                      ? _vm.$tl("labels.action.pay")
                      : _vm.$tl("labels.action.book"),
                },
              }),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }