export default {
    reservation_date: null,
    service_category: null,
    nb_pers: null,
    nb_children: 0,
    slot: null,
    option_bank: null,
    service: null,
    choosen_menus: {},
    choosen_general_options: {},
    full_payment_needed: false,
    gift: null,
    comment: null,
    isPrepaymentByClient: 1,
    saved_in_waiting_list: false,
    prefered_room: null,
    room_number: null,
};
