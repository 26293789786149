var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "text-left",
        attrs: { method: "post" },
        on: { submit: _vm.post },
      },
      [
        _vm.slots && _vm.slots.length > 0
          ? _c(
              "div",
              { staticClass: "slot_btn_select mt-4 radio-widget" },
              [
                _c("label", { staticClass: "mb-3 noshow-widget-font-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.hour")) +
                      "\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _vm.widget &&
                  _vm.widgetSlotMessage &&
                  _vm.widget.widget_messages_configuration
                    .slot_message_activated === 1
                    ? _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.widgetSlotMessage) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm._l(_vm.slots, function (slot) {
                  return _c(
                    "label",
                    { key: `slot-${slot.id}`, staticClass: "container-box" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.slot_id,
                            expression: "slot_id",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "slot",
                          disabled: _vm.loadingTryToPlaceInRoom,
                        },
                        domProps: {
                          value: slot.id,
                          checked: _vm._q(_vm.slot_id, slot.id),
                        },
                        on: {
                          change: [
                            function ($event) {
                              _vm.slot_id = slot.id
                            },
                            function ($event) {
                              return _vm.slotSelected(slot)
                            },
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkmark" }),
                      _vm._v(" " + _vm._s(slot.hour_start) + "\n            "),
                    ]
                  )
                }),
              ],
              2
            )
          : _c(
              "div",
              [
                _vm.hasMoreSlots
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.slots.notAllOpened")) +
                          "\n            "
                      ),
                    ])
                  : _vm.slot_message
                  ? _c("div", { staticClass: "h6 text-warning" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl(`labels.full.${_vm.slot_message}`)) +
                          "\n            "
                      ),
                    ])
                  : _c("div", { staticClass: "mt-4" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.full.serviceFull")) +
                          "\n            "
                      ),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-group mt-3 mb-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card widget-card",
                      attrs: {
                        "data-label":
                          _vm.reservation_data.service_full_option.othersDays,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                          on: { click: _vm.slotsNextSelected },
                        },
                        [
                          _c(
                            "h6",
                            { staticClass: "mb-0 noshow-widget-font-title" },
                            [
                              _vm._v(
                                _vm._s(_vm.$tl("labels.full.nextDatesLabel"))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.slots.length == 0 && _vm.widget.enable_waitings
                    ? _c(
                        "div",
                        {
                          staticClass: "card widget-card",
                          attrs: {
                            "data-label":
                              _vm.reservation_data.service_full_option
                                .waitingList,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body",
                              on: {
                                click: function ($event) {
                                  _vm.reservation_data.service_full_option.value =
                                    _vm.reservation_data.service_full_option.waitingList
                                },
                              },
                            },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass: "mb-0 noshow-widget-font-title",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl("labels.full.waitingListLabel")
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.restaurant_proposals &&
                  _vm.restaurant_proposals.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "card widget-card",
                          attrs: {
                            "data-label":
                              _vm.reservation_data.service_full_option
                                .othersRestaurants,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card-body",
                              on: {
                                click: function ($event) {
                                  _vm.reservation_data.service_full_option.value =
                                    _vm.reservation_data.service_full_option.othersRestaurants
                                },
                              },
                            },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass: "mb-0 noshow-widget-font-title",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.full.otherRestaurantLabel"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.reservation_data.service_full_option.value ===
                _vm.reservation_data.service_full_option.othersDays
                  ? [
                      _vm.loadingSlotsNext
                        ? _c("LoaderComponent")
                        : _c(
                            "div",
                            {
                              staticClass: "widget-full-option pt-4 pr-4 pl-4",
                              class:
                                Object.keys(_vm.slots_next_dates).length === 0
                                  ? "pb-4"
                                  : "pb-3",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "slot_btn_select" },
                                [
                                  Object.keys(_vm.slots_next_dates).length === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "text-warning" },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                !_vm.can_search_more_next_dates
                                                  ? _vm.$tl(
                                                      "labels.full.noNextDatesAvailable"
                                                    )
                                                  : _vm.$tl(
                                                      "labels.full.noNextDatesAvailableYet"
                                                    )
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._l(
                                        Object.keys(_vm.slots_next_dates),
                                        function (key) {
                                          return _c(
                                            "a",
                                            {
                                              key: `date-${key}`,
                                              staticClass:
                                                "btn btn-secondary widget-public-hour btn-sm mb-2 mr-2",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.resetDate(
                                                    $event,
                                                    _vm.slots_next_dates[key]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.displayDate(
                                                      _vm.slots_next_dates[key],
                                                      _vm.DateTime.DATE_HUGE
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                  _vm._v(" "),
                                  _vm.can_search_more_next_dates
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "d-block action-button",
                                          staticStyle: {
                                            width: "auto !important",
                                            "text-transform":
                                              "initial !important",
                                            padding: "10px 10px !important",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fetchSlotsNext(false)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.full.btnSearchMore"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.reservation_data.service_full_option.value ===
                _vm.reservation_data.service_full_option.waitingList
                  ? _c("div", { staticClass: "widget-full-option p-4" }, [
                      _c("div", [
                        _vm.success_waiting_list
                          ? _c("div", { staticClass: "alert alert-success" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.success_waiting_list) +
                                  "\n                    "
                              ),
                            ])
                          : _c(
                              "div",
                              [
                                _vm.error_waiting_list
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-danger" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.error_waiting_list) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.loadingWaitingForm
                                  ? _c("LoaderComponent")
                                  : [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 radio-widget mb-2",
                                          },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$tl("labels.hour")) +
                                                  " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.slots_full,
                                              function (slot) {
                                                return _c(
                                                  "label",
                                                  {
                                                    key: `slot--${slot.id}`,
                                                    staticClass:
                                                      "container-box d-inline-block",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.slot_id,
                                                          expression: "slot_id",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "radio",
                                                        name: "slot",
                                                      },
                                                      domProps: {
                                                        value: slot.id,
                                                        checked: _vm._q(
                                                          _vm.slot_id,
                                                          slot.id
                                                        ),
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            _vm.slot_id =
                                                              slot.id
                                                          },
                                                          function ($event) {
                                                            return _vm.slotSelected(
                                                              slot,
                                                              false
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "checkmark",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          slot.hour_start
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group text-left mb-0",
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.form.civility"
                                                    )
                                                  ) + " "
                                                ),
                                                _c("small", [_vm._v("*")]),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "slot_btn_select radio-widget",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                      staticStyle: {
                                                        width: "initial",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.client
                                                                .civility,
                                                            expression:
                                                              "client.civility",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          name: "civility",
                                                          value: "monsieur",
                                                          required: "required",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.client.civility,
                                                            "monsieur"
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.client,
                                                              "civility",
                                                              "monsieur"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.form.man"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                      staticStyle: {
                                                        width: "initial",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.client
                                                                .civility,
                                                            expression:
                                                              "client.civility",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          name: "civility",
                                                          value: "madame",
                                                          required: "required",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.client.civility,
                                                            "madame"
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.client,
                                                              "civility",
                                                              "madame"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.form.woman"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-6 mt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.lastName"
                                                  )
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.client.lastname,
                                                  expression: "client.lastname",
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                name: "lastname",
                                                placeholder: "...",
                                                required: "required",
                                              },
                                              domProps: {
                                                value: _vm.client.lastname,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.client,
                                                    "lastname",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-6 mt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.firstName"
                                                  )
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.client.firstname,
                                                  expression:
                                                    "client.firstname",
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                name: "firstname",
                                                placeholder: "...",
                                                required: "required",
                                              },
                                              domProps: {
                                                value: _vm.client.firstname,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.client,
                                                    "firstname",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-12 mt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.form.company")
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.client.company,
                                                  expression: "client.company",
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                name: "company",
                                                placeholder: "...",
                                                required: "required",
                                              },
                                              domProps: {
                                                value: _vm.client.company,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.client,
                                                    "company",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-6 mt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.form.email")
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.client.email,
                                                  expression: "client.email",
                                                },
                                              ],
                                              attrs: {
                                                type: "email",
                                                name: "email",
                                                placeholder: "...",
                                                required: "required",
                                              },
                                              domProps: {
                                                value: _vm.client.email,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.client,
                                                    "email",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-6 mt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.form.phone")
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                            _vm._v(" "),
                                            _c("vue-tel-input", {
                                              staticClass: "mb-2",
                                              attrs: {
                                                name: "telInput",
                                                defaultCountry:
                                                  _vm.defaultTelCountry,
                                                enabledCountryCode: true,
                                                wrapperClasses:
                                                  this.phone.isValid === null ||
                                                  this.phone.isValid
                                                    ? ""
                                                    : "border border-danger",
                                                inputClasses: "m-0 border-0",
                                                mode: "international",
                                                placeholder: "-- -- -- -- --",
                                                required: "required",
                                              },
                                              on: {
                                                validate:
                                                  _vm.setPhoneAndCountry,
                                              },
                                              model: {
                                                value: _vm.phone.raw,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.phone,
                                                    "raw",
                                                    $$v
                                                  )
                                                },
                                                expression: "phone.raw",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mt-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 checkbox-widget",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "container-box",
                                                staticStyle: {
                                                  width: "100%",
                                                  "line-height": "1.3",
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.cgv,
                                                      expression: "cgv",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    required: "required",
                                                    "true-value": "1",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.cgv
                                                    )
                                                      ? _vm._i(_vm.cgv, null) >
                                                        -1
                                                      : _vm._q(_vm.cgv, "1"),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = _vm.cgv,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? "1"
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.cgv =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.cgv = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.cgv = $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "i18n",
                                                  {
                                                    staticClass: "link-widget",
                                                    attrs: {
                                                      path: "labels.form.acceptCGUAfterBooking",
                                                      tag: "span",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "cgu" },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                _vm.customCgu ||
                                                                `${_vm.base_url}/booking/widget/public/${this.$api_key}/cgv?lang=${_vm.currentLang}`,
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.form.CGU"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "template",
                                                      { slot: "pcdp" },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: `${_vm.base_url}/booking/widget/public/${this.$api_key}/policy?lang=${_vm.currentLang}`,
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.form.PCDP"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "small",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.form.acceptCGUReason"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-secondary text-white-hover",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.saveInWaitingList.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl("labels.form.save")
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.reservation_data.service_full_option.value ===
                _vm.reservation_data.service_full_option.othersRestaurants
                  ? _c("div", { staticClass: "widget-full-option p-4" }, [
                      _c(
                        "div",
                        { staticClass: "slot_btn_select" },
                        _vm._l(
                          _vm.restaurant_proposals,
                          function (restau, index) {
                            return _c(
                              "div",
                              {
                                key: `restau-${index}`,
                                staticClass: "d-inline-block mr-3",
                              },
                              [
                                _c("div", { staticClass: "restau-infos" }, [
                                  _c("h6", [_vm._v(_vm._s(restau.name))]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(restau.formatted_address) +
                                        " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(restau.tel) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-secondary widget-public-hour btn-sm mr-2",
                                      attrs: {
                                        href: _vm.redirectToRestaurant(restau),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$tl("labels.action.book")
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
        _vm._v(" "),
        _vm.loadingMenus
          ? _c("LoaderComponent")
          : [
              _c("div", { staticClass: "d-flex mx-3 justify-content-end" }, [
                _vm.reservation.service && _vm.hasExternalMenu
                  ? _c(
                      "a",
                      {
                        staticClass: "action-button text-center",
                        staticStyle: { width: "auto !important" },
                        attrs: { href: _vm.linkExternalMenu, target: "_blank" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tl("labels.seeExternalMenu")) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.availableSeatingPlanRooms.length > 1
                ? _c(
                    "div",
                    {
                      staticClass: "mb-4 pt-3 pl-3 pr-3 pb-0 mt-2",
                      staticStyle: { border: "1px solid #f0f0f0" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "label",
                            {
                              staticClass: "mb-3 mt-2 noshow-widget-font-title",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$tl("labels.option.chooseRoom")) +
                                  "\n                            "
                              ),
                              !_vm.reservation.service
                                .allow_clients_choose_room_mandatory
                                ? _c("span", [
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.$tl("labels.optional")) +
                                        ")"
                                    ),
                                  ])
                                : _c("small", [_vm._v("*")]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _vm.loadingTryToPlaceInRoom
                              ? _c("LoaderComponent")
                              : _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reservation.prefered_room,
                                        expression: "reservation.prefered_room",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.reservation,
                                          "prefered_room",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.reservation.service
                                              .allow_clients_choose_room_mandatory ==
                                            1,
                                        },
                                        domProps: { value: null },
                                      },
                                      [
                                        !_vm.reservation.service
                                          .allow_clients_choose_room_mandatory
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.option.noPreferedRoom"
                                                  )
                                                )
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.option.chooseRoom"
                                                  )
                                                )
                                              ),
                                            ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.availableSeatingPlanRooms,
                                      function (room) {
                                        return _c(
                                          "option",
                                          {
                                            key: room.id,
                                            domProps: { value: room },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(room.name) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                            _vm._v(" "),
                            _vm.errorTryToPlaceInRoom
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.errorTryToPlaceInRoom)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.available_menus.length > 0 && _vm.reservation.nb_pers > 0
                ? _c(
                    "div",
                    {
                      staticClass: "mb-4 pt-3 pl-3 pr-3 pb-0 mt-2",
                      staticStyle: { border: "1px solid #f0f0f0" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _c("label", { staticClass: "mb-3 mt-2" }, [
                            _c(
                              "span",
                              { staticClass: "noshow-widget-font-title" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$tl("labels.option.chooseMenu")
                                    ) +
                                    "\n                            "
                                ),
                                _vm.reservation.service.menu_mandatory == false
                                  ? [
                                      _vm._v(
                                        " (" +
                                          _vm._s(_vm.$tl("labels.optional")) +
                                          ") "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _vm.areMenusNeedToBeUnique
                              ? _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl("labels.option.menuIsUnique")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.widget.res
                              ? _c("small", {
                                  staticClass: "d-block text-muted",
                                })
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.available_menus, function (m) {
                        return [
                          !m.for_children ||
                          (_vm.reservation.nb_children > 0 &&
                            !_vm.areMenusNeedToBeUnique)
                            ? _c(
                                "div",
                                {
                                  key: `menu-${m.id}`,
                                  staticClass: "one-menu mb-3",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row text-left mb-0" },
                                    [
                                      _c("div", { staticClass: "col-sm-8" }, [
                                        m.img !== null
                                          ? _c("img", {
                                              staticClass: "menu-img mr-1",
                                              attrs: { src: m.img },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-inline-block v-align-top",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center label-menu",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "mb-1" },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(m.name) +
                                                        _vm._s(
                                                          m.price === 0
                                                            ? ""
                                                            : ` - ${_vm.formatCurrency(
                                                                m.price,
                                                                _vm.restaurantCurrency
                                                              )}`
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.ALL_EXTERNAL_DESCRIPTION_TYPES.includes(
                                                  m.external_description_type
                                                )
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "ml-2 mb-1 d-flex",
                                                        attrs: {
                                                          href: _vm.getExternalDescriptionURL(
                                                            m
                                                          ),
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c("feather", {
                                                          staticClass:
                                                            "feather-search",
                                                          attrs: {
                                                            type: "search",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "d-block text-muted mb-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(m.description) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-sm-4" }, [
                                        _c(
                                          "select",
                                          {
                                            ref: `ref_menu_${m.id}`,
                                            refInFor: true,
                                            staticClass: "custom-select",
                                            attrs: {
                                              disabled:
                                                _vm.nbMenusAvailable(
                                                  m.id,
                                                  m.for_children
                                                ) === 0,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.chooseMenu(
                                                  m.id,
                                                  m.for_children,
                                                  m.price,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "0" } },
                                              [_vm._v("--")]
                                            ),
                                            _vm._v(" "),
                                            _vm.areMenusNeedToBeUnique &&
                                            _vm.nbMenusAvailable(m.id) > 0
                                              ? _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      value: _vm.nb_pers_total,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.nb_pers_total
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._l(
                                                  _vm.nbMenusAvailable(
                                                    m.id,
                                                    m.for_children
                                                  ),
                                                  function (n) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: `menu-${m.id}-${n}`,
                                                        domProps: { value: n },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(n) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.reservation.choosen_menus[
                                    `id_${m.id}`
                                  ] !== undefined &&
                                  _vm.reservation.choosen_menus[`id_${m.id}`]
                                    .value > 0 &&
                                  m.menu_options.data.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          m.menu_options.data,
                                          function (option) {
                                            return _c(
                                              "div",
                                              {
                                                key: `menu-${m.id}-option-${option.id}`,
                                                staticClass:
                                                  "option-widget row text-left m-0",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-sm-8" },
                                                  [
                                                    option.img !== null
                                                      ? _c("img", {
                                                          staticClass:
                                                            "menu-option-img mr-1",
                                                          attrs: {
                                                            src: option.img,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block v-align-top",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  option.name
                                                                ) +
                                                                _vm._s(
                                                                  option.price ===
                                                                    0
                                                                    ? ""
                                                                    : `- ${_vm.formatCurrency(
                                                                        option.price,
                                                                        _vm.restaurantCurrency
                                                                      )}`
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                            option.quantity_type ===
                                                            "mandatory_for_each"
                                                              ? _c(
                                                                  "small",
                                                                  {
                                                                    staticClass:
                                                                      "text-muted",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                ( " +
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.option.forAllMenus"
                                                                          )
                                                                        ) +
                                                                        ")\n                                            "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-muted mb-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  option.description
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-sm-4 checkbox-widget",
                                                  },
                                                  [
                                                    option.quantity_type ===
                                                    "mandatory_for_each"
                                                      ? _c("div", [
                                                          _c(
                                                            "select",
                                                            {
                                                              ref: `ref_option_${m.id}${option.id}`,
                                                              refInFor: true,
                                                              staticClass:
                                                                "custom-select",
                                                              staticStyle: {
                                                                height: "40px",
                                                                padding:
                                                                  "10px 15px",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chooseMenuOptionMandatory(
                                                                      m.id,
                                                                      option.price,
                                                                      option.id,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "0",
                                                                  },
                                                                },
                                                                [_vm._v("--")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "option",
                                                                {
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .reservation
                                                                        .choosen_menus[
                                                                        `id_${m.id}`
                                                                      ].value,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .reservation
                                                                          .choosen_menus[
                                                                          `id_${m.id}`
                                                                        ].value
                                                                      ) +
                                                                      "\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : option.quantity_type ===
                                                        "limited_by_pers"
                                                      ? _c(
                                                          "select",
                                                          {
                                                            ref: `ref_option_${m.id}${option.id}`,
                                                            refInFor: true,
                                                            staticClass:
                                                              "custom-select",
                                                            staticStyle: {
                                                              height: "40px",
                                                              padding:
                                                                "10px 15px",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.chooseMenuOptionLimited(
                                                                  m.id,
                                                                  option.price,
                                                                  option.id,
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "0",
                                                                },
                                                              },
                                                              [_vm._v("0")]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              parseInt(
                                                                _vm.reservation
                                                                  .choosen_menus[
                                                                  `id_${m.id}`
                                                                ].value
                                                              ),
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: `menu-${m.id}-option-quantity-${n}`,
                                                                    domProps: {
                                                                      value: n,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            " +
                                                                        _vm._s(
                                                                          n
                                                                        ) +
                                                                        "\n                                        "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : option.quantity_type ===
                                                        "unlimited"
                                                      ? _c("input", {
                                                          ref: `ref_option_${m.id}${option.id}`,
                                                          refInFor: true,
                                                          staticClass:
                                                            "form-control",
                                                          staticStyle: {
                                                            height: "40px",
                                                            padding:
                                                              "10px 15px",
                                                          },
                                                          attrs: {
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            value: "0",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.chooseMenuOptionUnlimited(
                                                                m.id,
                                                                option.price,
                                                                option.id,
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.available_general_options.length > 0 &&
              _vm.reservation.nb_pers > 0
                ? _c(
                    "div",
                    {
                      staticClass: "mb-4 pt-3 pl-3 pr-3 pb-0 mt-2",
                      staticStyle: { border: "1px solid #f0f0f0" },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "mb-3 mt-2 noshow-widget-font-title" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.chooseOption")) +
                              "\n                    "
                          ),
                          _c("br"),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.available_general_options,
                        function (o, index) {
                          return _c(
                            "div",
                            {
                              key: `general_option-${index}`,
                              staticClass: "one-menu mb-3",
                            },
                            [
                              _c("div", { staticClass: "row text-left mb-0" }, [
                                _c("div", { staticClass: "col-sm-8" }, [
                                  o.img !== null
                                    ? _c("img", {
                                        staticClass: "general-option-img mr-1",
                                        attrs: { src: o.img },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-inline-block v-align-top",
                                    },
                                    [
                                      _c("label", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(o.name) +
                                            _vm._s(
                                              o.price === 0
                                                ? ""
                                                : ` - ${_vm.formatCurrency(
                                                    o.price,
                                                    _vm.restaurantCurrency
                                                  )}`
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "d-block text-muted mb-2",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(o.description) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-4" }, [
                                  o.quantity_type === "mandatory_for_each"
                                    ? _c("div", [
                                        _c(
                                          "select",
                                          {
                                            ref: `ref_general_option_${o.id}`,
                                            refInFor: true,
                                            staticClass: "custom-select",
                                            staticStyle: {
                                              height: "40px",
                                              padding: "10px 15px",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.chooseGeneralOption(
                                                  o.id,
                                                  o.price,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "0" } },
                                              [_vm._v("--")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.nb_pers_total,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(_vm.nb_pers_total) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : o.quantity_type === "limited_by_pers"
                                    ? _c(
                                        "select",
                                        {
                                          ref: `ref_general_option_${o.id}`,
                                          refInFor: true,
                                          staticClass: "custom-select",
                                          staticStyle: {
                                            height: "40px",
                                            padding: "10px 15px",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.chooseGeneralOption(
                                                o.id,
                                                o.price,
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "0" } },
                                            [_vm._v("0")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.nb_pers_total,
                                            function (n) {
                                              return _c(
                                                "option",
                                                {
                                                  key: `general_option-quantity-${n}`,
                                                  domProps: { value: n },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(n) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : o.quantity_type === "unlimited"
                                    ? _c("input", {
                                        ref: `ref_general_option_${o.id}`,
                                        refInFor: true,
                                        staticClass: "form-control",
                                        staticStyle: {
                                          height: "40px",
                                          padding: "10px 15px",
                                        },
                                        attrs: {
                                          type: "number",
                                          step: "1",
                                          min: "0",
                                          value: "0",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.chooseGeneralOption(
                                              o.id,
                                              o.price,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
        _vm._v(" "),
        _c("div", { staticClass: "mt-2 text-center" }, [
          _c(
            "a",
            {
              staticClass: "previous action-button-previous",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("previous-step")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$tl("labels.back")))]
          ),
          _vm._v(" "),
          _vm.showNextButton
            ? _c("input", {
                staticClass: "next action-button",
                attrs: {
                  type: "submit",
                  disabled: _vm.loadingTryToPlaceInRoom,
                },
                domProps: { value: _vm.$tl("labels.next") },
              })
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }