<template>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <h5>{{ $tl("errors.common.widgetDisabled") }}</h5>
    </div>
</template>

<script>
export default {
    name: "LicencesErrors",
};
</script>
