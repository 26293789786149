export default {
    setLang(state, lang) {
        state.lang = lang;
    },
    setFirstName(state, firstname) {
        state.firstname = firstname;
    },
    setLastName(state, lastname) {
        state.lastname = lastname;
    },
    setEmail(state, email) {
        state.email = email;
    },
    setPhoneNumber(state, phoneNumber) {
        state.phoneNumber = phoneNumber;
    },
    setPhoneCountry(state, phoneCountry) {
        state.phoneCountry = phoneCountry;
    },
    setCompany(state, company) {
        state.company = company;
    },
    setCivility(state, civility) {
        state.civility = civility;
    },
};
