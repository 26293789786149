export default {
    loading: "Veuillez patienter...",
    back: "Retour",
    next: "Suivant",
    seeExternalMenu: "Voir la prestation",
    mandatory: "Obligatoire",
    optional: "Optionnel",
    hour: "Heure d'arrivée souhaitée",
    showMore: "Voir plus",
    showLess: "Voir moins",
    widgetProvidedBy: "Réservation en ligne by",
    service: {
        category: {
            midi: "Midi",
            soir: "Soir",
            brunch: "Brunch",
        },
    },
    reservation: {
        serviceRequired: "Plage horaire *",
        nbOfPeople: "Nombre de personnes",
        nbOfAdults: "Nombre d'adultes",
        nbOfChildren: "Nombre d'enfants",
        lessTenYears: "- 10 ans",
        noServiceForThisDate: "Aucun créneau n'est disponible à cette date",
        bookWithGiftGiveReference: "Si vous souhaitez réserver avec un bon cadeau, merci de nous indiquer sa référence.",
        thankForReservation: "Merci pour votre réservation.",
        summarySentByMail: "Un récapitulatif vous a été envoyé. Pensez à vérifier vos courriers indésirables.",
        summarySendAfterGvValidation:
            "Un récapitulatif vous sera envoyé après vérification de la validité de votre bon cadeau. Pensez à vérifier vos courriers indésirables.",
        details: "Détails de votre réservation :",
        detailsLink: "Détails de la réservation",
        confirm: "Confirmer ma réservation",
        cancel: "Annuler ma réservation",
        status: {
            refused: "Votre réservation est refusée.",
            canceled: "Votre réservation est annulée.",
            created: "Votre réservation est en attente de confirmation.",
            validated: "Votre réservation est validée.",
            confirmed: "Votre réservation est confirmée.",
            noshow: "Vous n'avez pas honoré votre réservation.",
            over: "Votre réservation est terminée.",
            toBeConfirmedByClient: "Réservation à confirmer.",
        },
    },
    restaurant: {
        details: "Informations sur l'établissement :",
        contactForInfo: "Veuillez contacter l'établissement pour plus d'informations.",
    },
    option: {
        person: "personne(s)",
        adult: "adulte(s)",
        children: "enfant(s)",
        menuOption: "Option",
        choosenOptions: "Options sélectionnées :",
        chooseRoom: "Choix de la salle",
        noPreferedRoom: "Peu importe",
        forAllMenus: "par personne",
        gift: "Bon cadeau",
        chooseMenu: "Choix des prestations",
        choosenMenus: "Prestations selectionnées :",
        childrenMenu: "Prestation enfant",
        menuIsUnique: "Une prestation unique est demandée pour l'ensemble des places",
    },
    chooseOption: "Choix des options",
    step: {
        reservation: "Réservation",
        options: "Options",
        informations: "Informations",
        confirmation: "Confirmation",
        bankImprint: "Empreinte bancaire",
        prepayment: "Prépaiement",
    },
    action: {
        pay: "Payer",
        book: "Réserver",
        commentForRestorantOwner: "Commentaire",
        checkInformations: "Vérification des informations",
    },
    prepayment: {
        label: "Prépaiement :",
        labelPartial: "Acompte :",
        paymentMenus: "Prépaiement de",
        paymentMenusMandatory: "Pour valider et garantir votre réservation, le paiement des prestations et options sélectionnées est nécessaire.",
        titleDownPayment: "Acompte de",
        downPayment:
            "Pour valider et garantir votre réservation, un acompte de {percentage}% du total des prestations et options sélectionnées est nécessaire.",
        payment: "Règlement",
        prepay: "Prépayer ma réservation - {total} ({percentage}%)",
        payAtRestaurant: "Payer sur place",
        paymentWaitingConfirmation:
            "Une authentification 3D Secure pourrait vous être demandée pour valider votre paiement. Dans ce cas, le module de votre banque peut mettre quelques secondes à s'afficher, merci de patienter.",
        amountToPay: "Montant à régler :",
        expired: "Le délai pour prépayer votre réservation a expiré.",
        success: "Paiement effectué avec succès",
        successAmount: "Paiement de {amount} effectué avec succès",
    },
    full: {
        serviceFull: "Notre établissement est complet pour cette plage horaire.",
        nextDatesLabel: "Voir les prochaines disponibilités",
        nextDatesContent: "Nous vous proposons d'autres dates disponibles pour votre réservation.",
        noNextDatesAvailable: "Nous n'avons pas trouvé de prochaine disponibilité",
        noNextDatesAvailableYet: "Nous n'avons pas encore trouvé de prochaines disponiblités",
        otherRestaurantLabel: "Réserver dans un autre établissement",
        otherRestaurantContent: "Nous vous proposons nos autres établissements disponibles pour votre réservation.",
        waitingListLabel: "S'inscrire sur liste d'attente",
        waitingListContent: "Nous vous proposons de vous mettre sur liste d'attente en cas de désistement.",
        btnSearchMore: "Poursuivre la recherche",
    },
    form: {
        civility: "Civilité",
        man: "Monsieur",
        woman: "Madame",
        firstName: "Prénom",
        company: "Société",
        lastName: "Nom",
        email: "Email",
        phone: "Téléphone",
        save: "Enregistrer",
        acceptCGUAfterBooking: "En validant ma demande de réservation, je déclare accepter les {cgu} et la {pcdp} et y adhère sans réserve.",
        acceptCGUAfterPayment: "En validant mon prépaiement, je déclare accepter les {cgu} et la {pcdp} et y adhère sans réserve.",
        acceptCGUAfterFootprint: "En validant mon empreinte bancaire, je déclare accepter les {cgu} et la {pcdp} et y adhère sans réserve.",
        CGU: "conditions générales d’utilisation du service",
        PCDP: "politique de confidentialité des données personnelles",
        acceptCGUReason: "Nous collectons ces données afin de gérer votre réservation et l'exécution du contrat lié à cette réservation.",
        roomNumber: "Numéro de chambre",
    },
    bankImprint: {
        label: "Empreinte bancaire :",
        totalBankImprint: "Montant total de l'empreinte bancaire :",
        allReservationsFrom: "Toute réservation effectuée à partir de",
        allReservationsFrom2: "personne est soumise à la prise d'une empreinte bancaire.",
        allReservationsFrom2S: "personnes est soumise à la prise d'une empreinte bancaire.",
        sampleOf: "Un prélèvement de ",
        perPerson: "par personne",
        allReservationsFrom3: "sera effectué seulement en cas de réservation non-honorée (totale ou partielle) ou non-annulée dans un délai de",
        beforeReservationDate: "avant l'heure de réservation.",
        applyInCaseOfnoSHow: "sera effectué seulement en cas de réservation non-honorée.",
        stripeInfos:
            'Vos informations bancaires sont collectées par la passerelle de paiement <a href="{stripe_url}" target="_blank">Stripe</a>, celles-ci sont cryptées et sécurisées.',
        bankImprintInfos:
            "Une empreinte bancaire vous sera demandée par email 6 jours avant la date de votre réservation.<br/> Vous aurez 48h pour la valider. Au délà, votre réservation pourrait être annulée.",
        paymentWaitingConfirmation:
            "Une authentification 3D Secure pourrait vous être demandée pour valider votre empreinte bancaire. Dans ce cas, le module de votre banque peut mettre quelques secondes à s'afficher, merci de patienter.",
        mustBeFilled: "Empreinte bancaire à renseigner :",
        amountPerPersonToCapture: "Montant pouvant être prélevé par personne en cas de no-show :",
        expired: "Le délai pour renseigner votre empreinte bancaire a expiré.",
        success: "Empreinte bancaire enregistrée avec succès",
    },
    slots: {
        notAllOpened: "Ce service n'est pas encore ouvert à la réservation",
    },
};
