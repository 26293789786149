import $ from "jquery";

class Utils {
    formatPrice(price) {
        if (price === null || price === "--") return "--";
        return Number.parseFloat(price).toFixed(2).toString().replace(".", ",");
    }

    getAmountPrice(product) {
        if (product.predefined_amounts.length === 1 && !product.allow_custom_amounts)
            return this.formatPrice(product.predefined_amounts[0]);
        let min = product.predefined_amounts.length > 0 ? Math.min(...product.predefined_amounts) : null;
        let max = product.predefined_amounts.length > 0 ? Math.max(...product.predefined_amounts) : null;
        if (product.allow_custom_amounts) {
            if (min === null || product.min_amount < min) min = product.min_amount;
            if (max === null || product.max_amount < max) max = product.max_amount;
        }
        return this.formatPrice(min) + "€ - " + this.formatPrice(max);
    }

    getQueryParameter(param, url = null) {
        if (url === null) url = location.href.replace(/#+.*$/, "").split("?");
        else url = url.replace(/#+$/, "").split("?");
        if (url.length < 2) return null;
        let params = url[1].split("&");
        for (let i = 0; i < params.length; i++) {
            let keyValue = params[i].split("=");
            if (keyValue[0] === param) return keyValue.length > 1 ? decodeURIComponent(keyValue[1]) : null;
        }
        return null;
    }

    removeQueryParameter(param) {
        let url = location.href.split("?");
        let newUrl = url[0] + "?";
        if (url.length < 2) return location.href;
        let params = url[1].split("&");
        for (let i = 0; i < params.length; i++) {
            let keyValue = params[i].split("=");
            if (keyValue[0] !== param) {
                newUrl += "&" + keyValue[0];
                if (keyValue[1]) newUrl += "=" + keyValue[1];
            }
        }
        return newUrl;
    }

    validLang(lang) {
        return ["fr", "en"].includes(lang);
    }

    getNavigatorLanguage() {
        return window.navigator.language.slice(0, 2) !== "fr" ? "en" : "fr";
    }

    getScriptInclusionLanguage() {
        const elems = $(`script[src^='${NOSHOW_URL}/booking/js/widget.js']`);
        if (elems.length > 0) return this.getQueryParameter("lang", elems[0].src);
        return null;
    }

    initFromUrl(properties) {
        let obj = {};
        properties.forEach((property) => {
            obj[property] = this.getQueryParameter(property);
        });
        return obj;
    }
}

export default {
    install(Vue) {
        Vue.prototype.$utils = new Utils();
    },
};
