export default {
    reservation: {
        serviceRequired: "Vétérinaire *",
        nbOfPeople: "Nombre d'animaux",
        nbOfAdults: "Nombre d'animaux",
    },
    option: {
        chooseMenu: "Choix des patients",
        choosenMenus: "patients sélectionnés :",
        menuOption: "patients",
        choosenOptions: "patients:",
    },
    chooseOption: "Choix des prestations",
    step: {
        options: "prestations",
    },
};
