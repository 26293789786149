export default {
    methods: {
        getErrorMsgFromErrorResponse(error, defaultMessage = undefined) {
            if (error && error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            }

            if (error && error.message) {
                return error.message;
            }

            return defaultMessage || this.$tl("errors.common.unknown");
        },
        formatCurrency(amount, currency = "EUR", locale = this.$store.getters["client/lang"]) {
            return new Intl.NumberFormat(locale, { style: "currency", currency }).format(amount);
        },
        escapeHtml(text) {
            if ([null, undefined, ""].includes(text)) {
                return text;
            }
            const map = {
                "&": "&amp;",
                "<": "&lt;",
                ">": "&gt;",
                '"': "&quot;",
                "'": "&#039;",
            };
            return text.replace(/[&<>"']/g, (m) => map[m]);
        },
    },
};
