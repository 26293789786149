export default {
    lang: null,
    civility: null,
    firstname: null,
    lastname: null,
    email: null,
    phoneNumber: null,
    phoneCountry: null,
    company: null,
};
