var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center w-100" }, [
    _c("div", {
      staticClass: "py-2 pl-1 pr-3",
      domProps: { innerHTML: _vm._s(_vm.customEventBellIcon) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "overflow-auto" },
      [
        _c("div", { staticClass: "font-weight-bold" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.customEvent.title) + "\n        "
          ),
        ]),
        _vm._v(" "),
        _vm.formattedDescription !== ""
          ? [
              _vm.shortedDescription === _vm.formattedDescription
                ? _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.formattedDescription) },
                  })
                : [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.showFullDescription
                            ? _vm.formattedDescription
                            : _vm.shortedDescription
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "text-muted",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.showFullDescription = !_vm.showFullDescription
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.showFullDescription
                                ? _vm.$tl("labels.showLess")
                                : _vm.$tl("labels.showMore")
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }