<template>
    <div :class="layoutClass" style="box-sizing: border-box" class="notranslate" translate="no">
        <MaintenanceError v-if="inMaintenance" />
        <LicencesErrors v-else-if="hasLicenceError" />
        <component
            v-else
            :globalLang="globalLang"
            :key="currentWidgetKey"
            @remount="remount"
            :is="`${layout}Layout`"
            :langFromScriptInclusion="langFromScriptInclusion"
            @change-lang="changeLang" />
    </div>
</template>

<script>
import BookingLayout from "./layouts/BookingLayout.vue";
import EditReservationLayout from "./layouts/EditReservationLayout.vue";
import LicencesErrors from "./components/errors/LicencesErrors.vue";
import MaintenanceError from "./components/errors/MaintenanceError.vue";
import { Settings, DateTime } from "luxon";
import axios from "axios";
import WidgetLangsEnum from "./mixins/enums/WidgetLangsEnum.js";

export default {
    data() {
        return {
            globalLang: null,
            langFromScriptInclusion: false,
            currentWidgetKey: 0,
        };
    },
    mixins: [WidgetLangsEnum],
    components: {
        BookingLayout,
        EditReservationLayout,
        LicencesErrors,
        MaintenanceError,
    },
    methods: {
        validateWidgetParams() {
            const widgetLang = this.$store.getters["widget/lang"];
            if (widgetLang) {
                if (!this.ALL_WIDGET_LANGS.some((l) => l.value === widgetLang)) {
                    const langsConcat = this.ALL_WIDGET_LANGS.map((t) => `"${l.value}"`).join(", ");
                    console.error(`${THEME} Widget: Invalid lang "${widgetLang}", expected one of: ${langsConcat}`);
                    return;
                }
            }
            const widgetDefaultLang = this.$store.getters["widget/defaultLang"];
            if (widgetDefaultLang) {
                if (!this.ALL_WIDGET_LANGS.some((l) => l.value === widgetDefaultLang)) {
                    const langsConcat = this.ALL_WIDGET_LANGS.map((t) => `"${l.value}"`).join(", ");
                    console.error(`${THEME} Widget: Invalid default lang "${widgetDefaultLang}", expected one of: ${langsConcat}`);
                    return;
                }
            }
        },
        remount() {
            this.$store.dispatch("reservation/clear");
            this.currentWidgetKey++;
        },
        changeLang(lang) {
            if (this.isLangValid(lang)) this.globalLang = lang;
        },
    },
    computed: {
        layout() {
            let currentRoute = location.href.split(this.$api_key);
            if (currentRoute.length === 1) {
                if (currentRoute[0].includes("/edit")) return "EditReservation";
                return "Booking";
            }
            if (currentRoute.length < 2) return "Booking";
            if (currentRoute[1].startsWith("/reservation")) return "EditReservation";
            return "Booking";
        },
        layoutClass() {
            if (this.layout === "Booking" || this.layout === "EditReservation") return "noshow-booking-widget";
            return "";
        },
        hasLicenceError() {
            return this.$store.getters["error/getHasLicenceError"];
        },
        inMaintenance() {
            return this.$store.getters["error/getInMaintenance"];
        },
    },
    watch: {
        globalLang: {
            immediate: true,
            handler(newLang) {
                if (this.isLangValid(newLang)) {
                    this.$store.dispatch("client/setLang", newLang);
                    this.$i18n.locale = newLang;
                    Settings.defaultLocale = newLang;
                    axios.defaults.headers.common["X-App-Lang"] = newLang;
                }
            },
        },
    },
    created() {
        this.validateWidgetParams();
        const langQueryParams = this.$utils.getQueryParameter("lang");
        const langWidgetInitParams = this.$store.getters["widget/lang"];
        const defaultlangWidgetInitParams = this.$store.getters["widget/defaultLang"];
        const langScriptInclusion = this.$utils.getScriptInclusionLanguage();
        const langStore = this.$store.getters["client/lang"];
        const langNavigator = this.$utils.getNavigatorLanguage();

        if (this.isLangValid(langQueryParams)) {
            this.globalLang = langQueryParams;
        } else if (this.isLangValid(langWidgetInitParams)) {
            this.globalLang = langWidgetInitParams;
            this.langFromScriptInclusion = true;
        } else if (this.isLangValid(langScriptInclusion)) {
            this.globalLang = langScriptInclusion;
            this.langFromScriptInclusion = true;
        } else if (this.isLangValid(langStore)) {
            this.globalLang = langStore;
        } else if (this.isLangValid(defaultlangWidgetInitParams)) {
            this.globalLang = defaultlangWidgetInitParams;
        } else if (this.isLangValid(langNavigator)) {
            this.globalLang = langNavigator;
        } else {
            this.globalLang = this.$i18n.fallbackLocale;
        }
    },
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=DM+Serif+Display|Lato:400,700|Montserrat+Alternates:400,600|Open+Sans:400,600|Oswald:400,600|Playfair+Display:400,600|Poppins:400,600|Raleway:400,600|Roboto:400,700&display=swap);
</style>
