var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "text-left",
        staticStyle: { position: "relative" },
        attrs: { method: "post" },
      },
      [
        _vm.loading
          ? _c("loader-component", { attrs: { message: _vm.loadingMessage } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading",
              },
            ],
          },
          [
            _vm.option_bank === _vm.OPTION_BANK_PAYMENT.value
              ? _c("label", { staticClass: "mt-4 noshow-widget-font-title" }, [
                  _vm.reservation.service.prepayment_percent === 100
                    ? _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tl("labels.prepayment.paymentMenus")) +
                            "\n                    " +
                            _vm._s(
                              _vm.formatCurrency(
                                _vm.createdReservation.payment
                                  .stripe_intent_amount / 100
                              )
                            ) +
                            "\n                "
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$tl("labels.prepayment.titleDownPayment")
                            ) +
                            "\n                    " +
                            _vm._s(
                              _vm.formatCurrency(
                                _vm.createdReservation.payment
                                  .stripe_intent_amount / 100
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                ])
              : _vm.option_bank === _vm.OPTION_BANK_FOOTPRINT.value
              ? _c("label", { staticClass: "mt-4 noshow-widget-font-title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.step.bankImprint")) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.reservation || !_vm.reservation.service
              ? _c("h6", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("errors.common.unknown")) +
                      "\n            "
                  ),
                ])
              : _vm.option_bank === _vm.OPTION_BANK_PAYMENT.value
              ? _c("div", { staticClass: "note-obligatoire text-left mt-3" }, [
                  _vm.reservation.service.prepayment_percent === 100
                    ? _c("div", [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.prepayment.paymentMenusMandatory",
                                  {
                                    percentage:
                                      _vm.reservation.service
                                        .prepayment_percent,
                                  }
                                )
                              ) +
                              "\n                    "
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$tl("labels.prepayment.downPayment", {
                                  percentage:
                                    _vm.reservation.service.prepayment_percent,
                                })
                              ) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                ])
              : _vm.option_bank === _vm.OPTION_BANK_FOOTPRINT.value
              ? _c("div", { staticClass: "note-obligatoire text-left mt-3" }, [
                  _vm.minutesToHoursFormat(
                    _vm.reservation.service.cancellation_until
                  ) !== undefined
                    ? _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$tl("labels.bankImprint.allReservationsFrom")
                            ) +
                            " " +
                            _vm._s(_vm.reservation.service.footprint_min_pers) +
                            "\n                    " +
                            _vm._s(
                              _vm.$tl(
                                "labels.bankImprint.allReservationsFrom2" +
                                  (_vm.reservation.service.footprint_min_pers <
                                  2
                                    ? ""
                                    : "S")
                              )
                            )
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tl("labels.bankImprint.sampleOf")) +
                            " " +
                            _vm._s(
                              _vm.formatCurrency(
                                _vm.reservation.service.amount_noshow
                              )
                            ) +
                            "\n                    " +
                            _vm._s(_vm.$tl("labels.bankImprint.perPerson")) +
                            " " +
                            _vm._s(
                              _vm.$tl("labels.bankImprint.allReservationsFrom3")
                            ) +
                            "\n                    " +
                            _vm._s(
                              _vm.minutesToHoursFormat(
                                _vm.reservation.service.cancellation_until
                              )
                            ) +
                            "\n                    " +
                            _vm._s(
                              _vm.$tl(
                                "labels.bankImprint.beforeReservationDate"
                              )
                            ) +
                            "."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$tl("labels.bankImprint.totalBankImprint")
                            ) +
                            "\n                    " +
                            _vm._s(
                              _vm.formatCurrency(
                                (_vm.createdReservation.amount_if_noshow *
                                  _vm.nb_pers_total) /
                                  100
                              )
                            ) +
                            "\n                "
                        ),
                      ])
                    : _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$tl("labels.bankImprint.allReservationsFrom")
                            ) +
                            " " +
                            _vm._s(_vm.reservation.service.footprint_min_pers) +
                            "\n                    " +
                            _vm._s(
                              _vm.$tl(
                                "labels.bankImprint.allReservationsFrom2" +
                                  (_vm.reservation.service.footprint_min_pers <
                                  2
                                    ? ""
                                    : "S")
                              )
                            )
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tl("labels.bankImprint.sampleOf")) +
                            " " +
                            _vm._s(
                              _vm.formatCurrency(
                                _vm.reservation.service.amount_noshow
                              )
                            ) +
                            "\n                    " +
                            _vm._s(_vm.$tl("labels.bankImprint.perPerson")) +
                            " (" +
                            _vm._s(
                              _vm.formatCurrency(
                                (_vm.createdReservation.amount_if_noshow *
                                  _vm.nb_pers_total) /
                                  100
                              )
                            ) +
                            ")\n                    " +
                            _vm._s(
                              _vm.$tl("labels.bankImprint.applyInCaseOfnoSHow")
                            ) +
                            "\n                "
                        ),
                      ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.stripeSetupIntent
              ? _c("StripePaymentForm", {
                  staticClass: "mt-4",
                  attrs: { option_bank: _vm.option_bank },
                  on: {
                    "set-form-errors": function ($event) {
                      return _vm.$emit("set-form-errors", $event)
                    },
                    "set-loading": function ($event) {
                      _vm.loading = $event
                    },
                    "next-step": function ($event) {
                      return _vm.$emit("next-step")
                    },
                    "previous-step": function ($event) {
                      return _vm.$emit("previous-step")
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.payplugSetupIntent
              ? _c("PayPlugPaymentForm", {
                  staticClass: "mt-4",
                  on: {
                    "set-form-errors": function ($event) {
                      return _vm.$emit("set-form-errors", $event)
                    },
                    "set-loading": function ($event) {
                      _vm.loading = $event
                    },
                    "next-step": function ($event) {
                      return _vm.$emit("next-step")
                    },
                    "previous-step": function ($event) {
                      return _vm.$emit("previous-step")
                    },
                    "prevent-refresh-page": function ($event) {
                      return _vm.$emit("prevent-refresh-page")
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }