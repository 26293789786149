import labels from "./labels.js";
import errors from "./errors.js";
import success from "./success.js";
import lexicons from "./lexicons";
import infos from "./infos.js";

export default {
    labels,
    errors,
    success,
    lexicons,
    infos,
};
