<template>
    <div>
        <LoaderComponent v-if="loadingForm" />
        <form v-else @submit="post" method="post">
            <div class="row mt-4">
                <div class="col-12">
                    <div class="slot_btn_select radio-widget text-left">
                        <label class="container-box" style="width: initial">
                            <input
                                type="radio"
                                name="civility"
                                value="monsieur"
                                v-model="client.civility"
                                required="required"
                                @click="$refs.firstname.focus()" />
                            <span class="checkmark"></span> {{ $tl("labels.form.man") }} *
                        </label>
                        <label class="container-box" style="width: initial">
                            <input
                                type="radio"
                                name="civility"
                                value="madame"
                                v-model="client.civility"
                                required="required"
                                @click="$refs.firstname.focus()" />
                            <span class="checkmark"></span> {{ $tl("labels.form.woman") }} *
                        </label>
                    </div>
                </div>
            </div>
            <div class="div-widget-infos">
                <div class="w-100 widget-infos-firstname">
                    <input
                        ref="firstname"
                        class="form-control"
                        type="text"
                        name="firstname"
                        v-model="client.firstname"
                        maxlength="50"
                        :placeholder="$tl('labels.form.firstName') + ' *'"
                        required="required" />
                </div>
                <div class="w-100 widget-infos-lastname">
                    <input
                        class="form-control"
                        type="text"
                        name="lastname"
                        v-model="client.lastname"
                        maxlength="50"
                        :placeholder="$tl('labels.form.lastName') + ' *'"
                        required="required" />
                </div>
                <div class="w-100 widget-infos-company" v-if="widget.enable_society">
                    <input class="form-control" type="text" name="company" v-model="client.company" :placeholder="$tl('labels.form.company')" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 pt-1">
                    <input
                        class="form-control"
                        type="email"
                        name="email"
                        v-model="client.email"
                        :placeholder="$tl('labels.form.email') + ' *'"
                        required="required" />
                </div>
                <div class="col-sm-6 pt-1">
                    <vue-tel-input
                        name="telInput"
                        :defaultCountry="defaultTelCountry"
                        class="mb-2"
                        @validate="setPhoneAndCountry"
                        v-model="phone.raw"
                        :enabledCountryCode="true"
                        :wrapperClasses="phone.isValid === null || phone.isValid ? '' : 'border border-danger'"
                        inputClasses="m-0 border-0"
                        mode="international"
                        :placeholder="$tl('labels.form.phone') + ' *'"
                        required="required"></vue-tel-input>
                </div>
            </div>
            <div class="row" v-if="isFeatRoomNumbersEnable">
                <div class="col-12 pt-1">
                    <label class="mb-0 noshow-widget-font-title">{{ $tl("labels.form.roomNumber") }} <small>*</small></label>
                    <input type="text" name="room_number" v-model="reservation.room_number" class="form-control mt-2" required />
                    <div v-if="roomNumbersForAutocomplete && !isRoomNumberValid" class="room-dropdown">
                        <span
                            class="d-block pointer text-left"
                            v-for="roomNumber in roomNumbersForAutocomplete"
                            :key="roomNumber.id"
                            @click="reservation.room_number = roomNumber.number">
                            {{ roomNumber.number }}
                        </span>
                    </div>
                    <span
                        class="text-danger d-block"
                        v-if="
                            roomNumbers.length > 0 &&
                            !isRoomNumberValid &&
                            reservation.room_number !== null &&
                            reservation.room_number.length > 0 &&
                            roomNumbersForAutocomplete.length === 0
                        ">
                        Aucune chambre n'a été trouvée
                    </span>
                </div>
            </div>
            <div class="row" v-if="widget.enable_gift && widget.enable_gift == 1">
                <div class="col-12 pt-1">
                    <label class="mb-0 noshow-widget-font-title">{{ $tl("labels.option.gift") }}</label>
                    <div class="text-left mb-2">
                        <small>{{ $tl("labels.reservation.bookWithGiftGiveReference") }}</small>
                    </div>
                    <input type="text" name="gift" v-model="reservation.gift" class="form-control mt-2" />
                </div>
            </div>
            <div class="row" v-if="widget.comment_enabled">
                <div class="col-12 pt-1">
                    <label class="mb-0 noshow-widget-font-title"
                        >{{ $tl("labels.action.commentForRestorantOwner") }}
                        <span class="required-mark" v-if="widget.comment_mandatory">*</span>
                    </label>
                    <div class="text-left" v-if="widgetCommentLabel">
                        <small>
                            {{ widgetCommentLabel }}
                        </small>
                    </div>
                    <textarea
                        v-bind:required="widget.comment_mandatory === 1"
                        name="comment"
                        v-model="reservation.comment"
                        class="form-control mt-2"
                        rows="4"></textarea>
                </div>
            </div>
            <div v-if="isOptionalPaymentEnabled && (!reservation.gift || reservation.gift === '')" class="row">
                <div class="col-12 pt-1">
                    <div class="form-group text-left mb-0">
                        <label class="mb-0 noshow-widget-font-title"
                            >{{ $tl("labels.prepayment.payment") }} <span class="required-mark">*</span>
                        </label>
                        <div class="slot_btn_select radio-widget pt-2">
                            <label class="container-box" style="width: initial">
                                <input name="optionalPayment" type="radio" value="1" v-model="reservation.isPrepaymentByClient" required="required" />
                                <span class="checkmark"></span>
                                {{
                                    $tl("labels.prepayment.prepay", {
                                        percentage: reservation.service.prepayment_percent,
                                        total: formatCurrency(totalAmount),
                                    })
                                }}
                            </label>
                            <label class="container-box" style="width: initial">
                                <input name="optionalPayment" type="radio" value="0" v-model="reservation.isPrepaymentByClient" required="required" />
                                <span class="checkmark"></span> {{ $tl("labels.prepayment.payAtRestaurant") }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 checkbox-widget">
                    <label class="container-box" style="width: 100%; line-height: 1.3">
                        <input type="checkbox" required="required" v-model="cgv" true-value="1" />
                        <span class="checkmark"></span>
                        <i18n path="labels.form.acceptCGUAfterBooking" tag="span" class="link-widget">
                            <template slot="cgu">
                                <a :href="customCgu || `${base_url}/booking/widget/public/${this.$api_key}/cgv?lang=${currentLang}`" target="_blank">
                                    {{ $tl("labels.form.CGU") }}
                                </a>
                            </template>
                            <template slot="pcdp">
                                <a :href="`${base_url}/booking/widget/public/${this.$api_key}/policy?lang=${currentLang}`" target="_blank">
                                    {{ $tl("labels.form.PCDP") }}
                                </a>
                            </template>
                        </i18n>
                        <br />
                        <small class="text-muted">{{ $tl("labels.form.acceptCGUReason") }}</small>
                    </label>
                </div>
            </div>

            <div class="mt-0 text-center">
                <a href="#" @click="previousStep" class="previous action-button-previous">{{ $tl("labels.back") }}</a>
                <input
                    type="submit"
                    :value="option_bank === OPTION_BANK_PAYMENT.value ? $tl('labels.action.pay') : $tl('labels.action.book')"
                    class="next action-button mt-2" />
            </div>
        </form>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum";
import WidgetLangsEnum from "../../mixins/enums/WidgetLangsEnum.js";

export default {
    data() {
        return {
            loadingForm: false,
            cgv: false,
            phone: {
                raw: null,
                isValid: null,
                country: null,
                value: null,
            },
            client: this.$store.getters["client/client"],
            reservation: this.$store.getters["reservation/all"],
            createdReservation: this.$store.getters["reservationData/created_reservation"],
            confirm_add_doublon_booking_week: false,
        };
    },
    props: {
        globalLang: {
            default: null,
        },
        option_bank: {
            required: true,
        },
    },
    mixins: [OptionBankEnum, WidgetLangsEnum],
    computed: {
        defaultTelCountry() {
            return this.$store.getters["restaurant/defaultTelCountry"];
        },
        currentLang() {
            return this.$store.getters["client/lang"];
        },
        roomNumbers() {
            return this.$store.getters["restaurant/room_numbers"];
        },
        roomNumbersForAutocomplete() {
            if (this.reservation.room_number !== null && this.reservation.room_number.length > 0)
                return this.roomNumbers.filter((room) => room.number.toLowerCase().includes(this.reservation.room_number.toLowerCase()));
            return [];
        },
        isRoomNumberValid() {
            const firstRoomNumberInList = this.roomNumbersForAutocomplete.length > 0 ? this.roomNumbersForAutocomplete[0] : null;
            return firstRoomNumberInList && this.reservation.room_number !== null && this.reservation.room_number === firstRoomNumberInList.number;
        },
        isFeatRoomNumbersEnable() {
            return this.$store.getters["restaurant/feat_room_numbers"];
        },
        widgetCommentLabel() {
            if (!this.widget) return "";
            if (this.globalLang === this.LANG_FR.value) return this.widget.comment_label;
            return this.widget.comment_label_en || this.widget.comment_label;
        },
        stripeSetupIntent: {
            get() {
                return this.$store.getters["reservationData/stripe_setup_intent"];
            },
            set(newVal) {
                this.$store.dispatch("reservationData/setStripeSetupIntent", newVal);
            },
        },
        payplugSetupIntent: {
            get() {
                return this.$store.getters["reservationData/payplug_setup_intent"];
            },
            set(newVal) {
                this.$store.dispatch("reservationData/setPayPlugSetupIntent", newVal);
            },
        },
        customCgu() {
            return this.$store.getters["restaurant/cgu"];
        },
        base_url() {
            return NOSHOW_URL;
        },
        totalAmount() {
            var amount = 0;

            for (var index in this.reservation.choosen_menus) {
                const menu = this.reservation.choosen_menus[index];

                var menuAmount = menu.value * menu.price;

                for (var optionIndex in menu.options) {
                    var option = menu.options[optionIndex];
                    menuAmount += option.value * option.price;
                }

                amount += menuAmount;
            }

            for (var index in this.reservation.choosen_general_options) {
                const option = this.reservation.choosen_general_options[index];

                amount += option.value * option.price;
            }

            if (this.reservation.service) {
                return Math.round(amount * this.reservation.service.prepayment_percent) / 100;
            }

            return 0;
        },
        isOptionalPaymentEnabled() {
            return (
                this.reservation.service &&
                this.reservation.service.prepayment_enabled === 1 &&
                this.reservation.service.prepayment_mandatory === 0 &&
                this.reservation.service.prepayment_percent &&
                this.totalAmount > 0 &&
                ((this.reservation.choosen_general_options && !this.$_.isEmpty(this.reservation.choosen_general_options)) ||
                    (this.reservation.choosen_menus && !this.$_.isEmpty(this.reservation.choosen_menus)))
            );
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        reservation_id() {
            if (this.createdReservation && this.createdReservation.id) return this.createdReservation.id;
        },
    },
    methods: {
        previousStep() {
            this.confirm_add_doublon_booking_week = false;
            this.$emit("previous-step");
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.phone.isValid = phoneObject.isValid;

                if (this.phone.isValid) {
                    this.phone.value = phoneObject.number;

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.phone.country = phoneObject.regionCode;
                    } else {
                        this.phone.country = phoneObject.country.iso2;
                    }
                }
            } else {
                this.phone.isValid = false;
            }
        },
        getCardSetupIntent() {
            return axios.post(`${API_URL}/widget/card/intent/${this.reservation_id}`).then((response) => {
                if (response.data.type && response.data.setup_intent) this[`${response.data.type}SetupIntent`] = response.data.setup_intent;
            });
        },
        setData() {
            const data = {
                ...this.client,
                phone_number: this.client.phoneNumber,
                phone_country: this.client.phoneCountry,
                ...this.reservation,
                service_category: this.reservation.service_category.fr,
                service_category_en: this.reservation.service_category.en,
                slot_id: this.reservation.slot.id,
                cgv: this.cgv === "1" ? true : false,
                reservation_date: this.reservation.reservation_date.toISODate(),
                room_number: this.reservation.room_number,
                choosen_menus: this.reservation.choosen_menus,
                choosen_general_options: this.reservation.choosen_general_options,
            };

            if (this.isFeatRoomNumbersEnable) {
                data.room_number = this.reservation.room_number;
            }
            if (this.widget.comment_enabled) {
                data.comment = this.reservation.comment;
            }

            return data;
        },
        addToWaitingList() {
            this.loadingForm = true;
            this.$emit("set-form-errors", []);

            axios
                .post(`${API_URL}/widget/${this.$api_key}/waitings`, this.setData())
                .then(() => {
                    this.$store.dispatch("reservation/setSavedInWaitingList");
                    this.loadingForm = false;
                    this.$emit("change-step", 5);
                })
                .catch((error) => {
                    this.loadingForm = false;
                    if (error.response && error.response.data.errors) this.$emit("set-form-errors", Object.values(error.response.data.errors).flat());
                    else if (error.response && error.response.data.message) this.$emit("set-form-errors", [error.response.data.message]);
                    else if (error.message && error.message.toLowerCase() === "network error")
                        this.$emit("set-form-errors", [this.$tl("errors.common.network")]);
                    else if (error.message) this.$emit("set-form-errors", [error.message]);
                    else this.$emit("set-form-errors", [this.$tl("errors.common.unknown")]);
                });
        },
        post(e, confirm_add_doublon_booking = false) {
            if (e) e.preventDefault();
            this.$emit("set-form-errors", []);

            // control fields
            if (!this.client.firstname || !this.client.lastname || !this.client.email || !this.phone.isValid) {
                this.$emit("set-form-errors", [this.$tl("errors.general.fillAllFields")]);
                return;
            }

            if (this.confirm_add_doublon_booking_week) return this.addToWaitingList();

            this.loadingForm = true;
            let endpoint = `${API_URL}/widget/${this.$api_key}/reservations`;
            if (this.reservation_id) endpoint += `/${this.reservation_id}`;
            axios
                .post(endpoint, {
                    ...this.reservation,
                    ...this.client,
                    slot_id: this.reservation.slot.id,
                    cgv: this.cgv === "1" ? true : false,
                    waiting_token: this.$utils.getQueryParameter("token") || "",
                    confirm_add_doublon_booking,
                    confirm_add_doublon_booking_week: this.confirm_add_doublon_booking_week,
                    reservation_date: this.reservation.reservation_date.toISODate(),
                })
                .then((response) => {
                    this.createdReservation = response.data;
                    this.getCardSetupIntent()
                        .then(() => {
                            this.loadingForm = false;
                            if (this.stripeSetupIntent || this.payplugSetupIntent) this.$emit("next-step");
                            else this.$emit("change-step", 5);
                        })
                        .catch((error) => {
                            this.loadingForm = false;
                            if (error.response && error.response.data.errors) this.$emit("set-form-errors", error.response.data.errors);
                        });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 409) {
                            if (!confirm("Une réservation a déjà été enregistrée pour cette date. Êtes-vous sûr de vouloir réserver à nouveau ?")) {
                                this.loadingForm = false;
                                return;
                            }
                            this.post(null, true);
                            return;
                        }
                        if (error.response.status === 449) this.confirm_add_doublon_booking_week = true;
                    }
                    this.loadingForm = false;
                    if (error.response && error.response.data.errors) this.$emit("set-form-errors", Object.values(error.response.data.errors).flat());
                    else if (error.response && error.response.data.message) this.$emit("set-form-errors", [error.response.data.message]);
                    else if (error.message && error.message.toLowerCase() === "network error")
                        this.$emit("set-form-errors", [this.$tl("errors.common.network")]);
                    else if (error.message) this.$emit("set-form-errors", [error.message]);
                    else this.$emit("set-form-errors", [this.$tl("errors.common.unknown")]);
                });
        },
    },
    watch: {
        phone: {
            deep: true,
            handler(newVal) {
                if (newVal.isValid) {
                    this.client.phoneNumber = this.phone.value.international;
                    this.client.phoneCountry = this.phone.country;
                }
            },
        },
        client: {
            deep: true,
            handler(newVal) {
                if (newVal) this.$store.dispatch("client/setClient", newVal);
            },
        },
        reservation: {
            deep: true,
            handler(newVal) {
                if (newVal) this.$store.dispatch("reservation/setAll", newVal);
            },
        },
        createdReservation(newVal) {
            this.$store.dispatch("reservationData/setCreatedReservation", newVal);
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.phone = {
            raw: this.client.phoneNumber || null,
            isValid: this.client.phoneNumber != null,
            country: this.client.phoneCountry || null,
            value: this.client.phoneNumber ? { international: this.client.phoneNumber } : null,
        };
    },
};
</script>

<style>
.pointer {
    cursor: pointer !important;
}
</style>
