<template>
    <div>
        <span>
            <span v-if="!reservation.saved_in_waiting_list" class="d-block">{{ $tl("labels.reservation.thankForReservation") }}</span>
            <span v-if="reservation.saved_in_waiting_list">{{ $tl("success.waitingList.registered") }}</span>
            <span v-else-if="widget.is_gv_enabled && reservation.gift && option_bank && hasSetupIntent">
                {{ $tl("labels.reservation.summarySendAfterGvValidation") }}
            </span>
            <span v-else>{{ $tl("labels.reservation.summarySentByMail") }}</span>
            <br />
            <br />
            <span
                v-if="!reservation.saved_in_waiting_list && option_bank === OPTION_BANK_FOOTPRINT.value && !hasSetupIntent"
                v-html="$tl('labels.bankImprint.bankImprintInfos')">
            </span>
        </span>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum";

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        option_bank: {
            default: "",
        },
    },
    mixins: [OptionBankEnum],
    computed: {
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        hasSetupIntent() {
            return this.$store.getters["reservationData/stripe_setup_intent"] || this.$store.getters["reservationData/payplug_setup_intent"]
                ? true
                : false;
        },
        reservation() {
            return this.$store.getters["reservation/all"];
        },
    },
    created() {
        if (window.dataLayer) {
            window.dataLayer.push({ event: "booking-validated" });
        }
    },
    components: {
        LoaderComponent,
    },
};
</script>
