var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.stripeGenerated && !_vm.stripeLoading,
            expression: "stripeGenerated && !stripeLoading",
          },
        ],
        ref: "stripePaymentCard",
      }),
      _vm._v(" "),
      _vm.stripeLoading || (!_vm.stripeGenerated && !_vm.error)
        ? _c("loader-component")
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("span", { staticClass: "d-block text-danger mb-2" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stripeGenerated,
              expression: "stripeGenerated",
            },
          ],
          staticClass: "btn btn-sm btn-success",
          class: _vm.buttonAdditionalClass,
          attrs: { disabled: _vm.stripeLoading },
          on: { click: _vm.handleCardPayment },
        },
        [_vm._v("\n        " + _vm._s(_vm.buttonLabel) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }