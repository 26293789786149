export default {
    data() {
        return {
            RESERVATION_STATUS_PENDING: {
                label: "non enregistrée",
                value: "pending",
            },
            RESERVATION_STATUS_CREATED: {
                label: "en attente",
                value: "created",
            },
            RESERVATION_STATUS_VALIDATED: {
                label: "validée",
                value: "validated",
            },
            RESERVATION_STATUS_CANCELED: {
                label: "annulée",
                value: "canceled",
            },
            RESERVATION_STATUS_REFUSED: {
                label: "refusée",
                value: "refused",
            },
            RESERVATION_STATUS_OVER: {
                label: "terminée",
                value: "over",
            },
            RESERVATION_STATUS_NOSHOW: {
                label: "no-show",
                value: "noshow",
            },
            RESERVATION_STATUS_OPTION: {
                label: "option",
                value: "option",
            },
            RESERVATION_STATUS_AT_TABLE: {
                label: "à table",
                value: "at_table",
            },
            RESERVATION_STATUS_CONFIRMED: {
                label: "confirmée",
                value: "confirmed",
            },
        };
    },
    methods: {
        getReservationStatusLabel(value) {
            const found = this.ALL_RESERVATION_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        editableReservationStatus() {
            return [
                this.RESERVATION_STATUS_CREATED,
                this.RESERVATION_STATUS_OPTION,
                this.RESERVATION_STATUS_VALIDATED,
                this.RESERVATION_STATUS_CONFIRMED,
            ];
        },
        cancelableReservationStatus() {
            return [this.RESERVATION_STATUS_CREATED, this.RESERVATION_STATUS_OPTION, this.RESERVATION_STATUS_VALIDATED];
        },
        validReservationStatus() {
            return [
                this.RESERVATION_STATUS_CREATED,
                this.RESERVATION_STATUS_OPTION,
                this.RESERVATION_STATUS_VALIDATED,
                this.RESERVATION_STATUS_CONFIRMED,
                this.RESERVATION_STATUS_OVER,
                this.RESERVATION_STATUS_AT_TABLE,
            ];
        },
        ALL_RESERVATION_STATUS() {
            return [
                this.RESERVATION_STATUS_PENDING,
                this.RESERVATION_STATUS_CREATED,
                this.RESERVATION_STATUS_VALIDATED,
                this.RESERVATION_STATUS_CANCELED,
                this.RESERVATION_STATUS_REFUSED,
                this.RESERVATION_STATUS_OVER,
                this.RESERVATION_STATUS_NOSHOW,
                this.RESERVATION_STATUS_OPTION,
                this.RESERVATION_STATUS_AT_TABLE,
                this.RESERVATION_STATUS_CONFIRMED,
            ];
        },
    },
};
