export default {
    lang: (state) => {
        return state.lang;
    },
    client: (state) => {
        return state;
    },
    id: (state) => {
        return state.id;
    },
    firstname: (state) => {
        return state.firstname;
    },
    lastname: (state) => {
        return state.lastname;
    },
    email: (state) => {
        return state.email;
    },
    phoneNumber: (state) => {
        return state.phoneNumber;
    },
    phoneCountry: (state) => {
        return state.phoneCountry;
    },
    company: (state) => {
        return state.company;
    },
    civility: (state) => {
        return state.civility;
    },
};
