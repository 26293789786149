export default {
    data() {
        return {
            LANG_FR: {
                label: "Français",
                value: "fr",
                countryFlag: "fr",
                dropdownLabel: "Fr",
            },
            LANG_EN: {
                label: "Anglais",
                value: "en",
                countryFlag: "gb",
                dropdownLabel: "En",
            },
        };
    },
    methods: {
        getWidgetLangLabel(value) {
            const found = this.ALL_WIDGET_LANGS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        isLangValid(value) {
            return this.ALL_WIDGET_LANGS.some((l) => l.value === value);
        },
    },
    computed: {
        ALL_WIDGET_LANGS() {
            return [this.LANG_FR, this.LANG_EN];
        },
    },
};
