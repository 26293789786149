export default {
    setSavedInWaitingList({ commit }) {
        commit("setSavedInWaitingList", true);
    },
    setAll({ commit }, reservation) {
        commit("setReservationDate", reservation.reservation_date);
        commit("setServiceCategory", reservation.service_category);
        commit("setOptionBank", reservation.option_bank);
        commit("setNbPers", reservation.nb_pers);
        commit("setNbChildren", reservation.nb_children);
        commit("setSlot", reservation.slot);
        commit("setService", reservation.service);
        commit("setChoosenMenus", reservation.choosen_menus);
        commit("setChoosenGeneralOptions", reservation.choosen_general_options);
        commit("setFullPaymentNeeded", reservation.full_payment_needed);
        commit("setGift", reservation.gift);
        commit("setComment", reservation.comment);
        commit("setIsPrepaymentByClient", reservation.isPrepaymentByClient);
        commit("setPreferedRoom", reservation.prefered_room);
        commit("setRoomNumber", reservation.room_number);
    },
    clear({ commit }, step = 0) {
        if (step < 1) {
            commit("setReservationDate", null);
            commit("setServiceCategory", null);
            commit("setNbPers", null);
            commit("setNbChildren", 0);
        }
        if (step < 2) {
            commit("setSlot", null);
            commit("setService", null);
            commit("setPreferedRoom", null);
            commit("setChoosenMenus", {});
            commit("setChoosenGeneralOptions", {});
            commit("setFullPaymentNeeded", false);
        }
        if (step < 3) {
            commit("setIsPrepaymentByClient", 1);
        }
        commit("setOptionBank", null);
    },
    setPreferedRoom({ commit }, prefered_room) {
        commit("setPreferedRoom", prefered_room);
    },
    setRoomNumber({ commit }, room_number) {
        commit("setRoomNumber", room_number);
    },
    setServiceCategory({ commit }, service_category) {
        commit("setServiceCategory", service_category);
    },
    setReservationDate({ commit }, reservation_date) {
        commit("setReservationDate", reservation_date);
    },
    setOptionBank({ commit }, option_bank) {
        commit("setOptionBank", option_bank);
    },
    setNbPers({ commit }, nb_pers) {
        commit("setNbPers", nb_pers);
    },
    setNbChildren({ commit }, nb_children) {
        commit("setNbChildren", nb_children);
    },
    setSlot({ commit }, slot) {
        commit("setSlot", slot);
    },
    setService({ commit }, service) {
        commit("setService", service);
    },
    setChoosenMenus({ commit }, choosen_menus) {
        commit("setChoosenMenus", choosen_menus);
    },
    setChoosenGeneralOptions({ commit }, choosen_general_options) {
        commit("setChoosenGeneralOptions", choosen_general_options);
    },
    setFullPaymentNeeded({ commit }, full_payment_needed) {
        commit("setFullPaymentNeeded", full_payment_needed);
    },
    setGift({ commit }, gift) {
        commit("setGift", gift);
    },
    setComment({ commit }, comment) {
        commit("setComment", comment);
    },
    setIsPrepaymentByClient({ commit }, isPrepaymentByClient) {
        commit("setIsPrepaymentByClient", isPrepaymentByClient);
    },
};
