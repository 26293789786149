import * as Sentry from "@sentry/vue";
import Vue from "vue";

const initSentry = function (applicationKey) {
    const tracesSampleRate = APP_ENV === "production" ? 0.1 : 1.0;

    const dsn =
        THEME === "yservices"
            ? "https://229b5a5162fd4490a0868fd48d92b29e@o59715.ingest.sentry.io/4504633553846275"
            : "https://a36cb501be9944399572d2311dc33f42@o59715.ingest.sentry.io/6228114";

    Sentry.init({
        Vue,
        dsn,
        tracesSampleRate,
        environment: APP_ENV,
        logErrors: true,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: [applicationKey],
                behaviour: "drop-error-if-exclusively-contains-third-party-frames",
            }),
        ],
    });
};

export { initSentry };
