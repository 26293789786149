var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hasFeatWhileLabeled
    ? _c("div", { staticClass: "w-100 text-center" }, [
        _vm._v("\n    " + _vm._s(_vm.$tl("labels.widgetProvidedBy"))),
        _c("br"),
        _vm._v(" "),
        _c("img", {
          staticClass: "provider-logo",
          attrs: { src: `${_vm.NOSHOW_URL}/images/logo-lead-extend.png` },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }