<template>
    <div>
        <form class="text-left" method="post" style="position: relative">
            <loader-component v-if="loading" :message="loadingMessage" />
            <div v-show="!loading">
                <label v-if="option_bank === OPTION_BANK_PAYMENT.value" class="mt-4 noshow-widget-font-title">
                    <div v-if="reservation.service.prepayment_percent === 100">
                        {{ $tl("labels.prepayment.paymentMenus") }}
                        {{ formatCurrency(createdReservation.payment.stripe_intent_amount / 100) }}
                    </div>
                    <div v-else>
                        {{ $tl("labels.prepayment.titleDownPayment") }}
                        {{ formatCurrency(createdReservation.payment.stripe_intent_amount / 100) }}
                    </div>
                </label>
                <label v-else-if="option_bank === OPTION_BANK_FOOTPRINT.value" class="mt-4 noshow-widget-font-title">
                    {{ $tl("labels.step.bankImprint") }}
                </label>
                <h6 v-if="!reservation || !reservation.service" class="text-danger">
                    {{ $tl("errors.common.unknown") }}
                </h6>
                <div v-else-if="option_bank === OPTION_BANK_PAYMENT.value" class="note-obligatoire text-left mt-3">
                    <div v-if="reservation.service.prepayment_percent === 100">
                        <small class="text-muted">
                            {{
                                $tl("labels.prepayment.paymentMenusMandatory", {
                                    percentage: reservation.service.prepayment_percent,
                                })
                            }}
                        </small>
                    </div>
                    <div v-else>
                        <small class="text-muted">
                            {{
                                $tl("labels.prepayment.downPayment", {
                                    percentage: reservation.service.prepayment_percent,
                                })
                            }}
                        </small>
                    </div>
                </div>
                <div v-else-if="option_bank === OPTION_BANK_FOOTPRINT.value" class="note-obligatoire text-left mt-3">
                    <small v-if="minutesToHoursFormat(reservation.service.cancellation_until) !== undefined" class="text-muted">
                        {{ $tl("labels.bankImprint.allReservationsFrom") }} {{ reservation.service.footprint_min_pers }}
                        {{ $tl("labels.bankImprint.allReservationsFrom2" + (reservation.service.footprint_min_pers < 2 ? "" : "S")) }}<br />
                        {{ $tl("labels.bankImprint.sampleOf") }} {{ formatCurrency(reservation.service.amount_noshow) }}
                        {{ $tl("labels.bankImprint.perPerson") }} {{ $tl("labels.bankImprint.allReservationsFrom3") }}
                        {{ minutesToHoursFormat(reservation.service.cancellation_until) }}
                        {{ $tl("labels.bankImprint.beforeReservationDate") }}.<br />
                        {{ $tl("labels.bankImprint.totalBankImprint") }}
                        {{ formatCurrency((createdReservation.amount_if_noshow * nb_pers_total) / 100) }}
                    </small>
                    <small v-else class="text-muted">
                        {{ $tl("labels.bankImprint.allReservationsFrom") }} {{ reservation.service.footprint_min_pers }}
                        {{ $tl("labels.bankImprint.allReservationsFrom2" + (reservation.service.footprint_min_pers < 2 ? "" : "S")) }}<br />
                        {{ $tl("labels.bankImprint.sampleOf") }} {{ formatCurrency(reservation.service.amount_noshow) }}
                        {{ $tl("labels.bankImprint.perPerson") }} ({{ formatCurrency((createdReservation.amount_if_noshow * nb_pers_total) / 100) }})
                        {{ $tl("labels.bankImprint.applyInCaseOfnoSHow") }}
                    </small>
                </div>
                <StripePaymentForm
                    v-if="stripeSetupIntent"
                    class="mt-4"
                    @set-form-errors="$emit('set-form-errors', $event)"
                    @set-loading="loading = $event"
                    @next-step="$emit('next-step')"
                    @previous-step="$emit('previous-step')"
                    :option_bank="option_bank" />
                <PayPlugPaymentForm
                    v-if="payplugSetupIntent"
                    class="mt-4"
                    @set-form-errors="$emit('set-form-errors', $event)"
                    @set-loading="loading = $event"
                    @next-step="$emit('next-step')"
                    @previous-step="$emit('previous-step')"
                    @prevent-refresh-page="$emit('prevent-refresh-page')" />
            </div>
        </form>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum";
import StripePaymentForm from "../PaymentForm/Stripe.vue";
import PayPlugPaymentForm from "../PaymentForm/PayPlug.vue";

export default {
    data() {
        return {
            loading: false,
            card: null,
            stripe: null,
        };
    },
    props: {
        option_bank: {
            default: "",
        },
    },
    mixins: [OptionBankEnum],
    computed: {
        loadingMessage() {
            if (this.option_bank === this.OPTION_BANK_PAYMENT.value) return this.$tl("labels.prepayment.paymentWaitingConfirmation");
            if (this.option_bank === this.OPTION_BANK_FOOTPRINT.value) return this.$tl("labels.bankImprint.paymentWaitingConfirmation");
            return undefined;
        },
        stripeSetupIntent() {
            return this.$store.getters["reservationData/stripe_setup_intent"];
        },
        payplugSetupIntent() {
            return this.$store.getters["reservationData/payplug_setup_intent"];
        },
        nb_pers_total() {
            return this.$store.getters["reservation/nb_pers_total"];
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        reservation() {
            return this.$store.getters["reservation/all"];
        },
        client() {
            return this.$store.getters["client/client"];
        },
        createdReservation() {
            return this.$store.getters["reservationData/created_reservation"];
        },
    },
    methods: {
        minutesToHoursFormat(value) {
            if (value > 0) {
                var result = "";
                const hours = value / 60;
                const minutes = value % 60;

                if (hours > 0) {
                    result += `${hours}h`;
                }

                if (minutes > 0) {
                    if (minutes < 10) {
                        result += `0${minutes}min`;
                    } else {
                        result += `${minutes}min`;
                    }
                }

                return result;
            } else {
                return undefined;
            }
        },
    },
    components: {
        LoaderComponent,
        StripePaymentForm,
        PayPlugPaymentForm,
    },
};
</script>
