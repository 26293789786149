export default {
    reservation: {
        serviceRequired: "Vet *",
        nbOfPeople: "Number of pets",
        nbOfAdults: "Number of pets",
    },
    option: {
        chooseMenu: "Choice of patients",
        choosenMenus: "selected patients:",
        menuOption: "patients",
        choosenOptions: "patients:",
    },
    chooseOption: "Choice of services",
    step: {
        options: "services",
    },
};
