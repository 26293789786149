<template>
    <ul id="progressbar">
        <li :class="{ active: step >= 1 }" @click="changeStep(1)">
            <span class="none-mobile">{{ $tl("labels.step.reservation") }}</span>
        </li>
        <li :class="{ active: step >= 2 }" @click="changeStep(2)">
            <span class="none-mobile">{{ $tl("labels.step.options") }}</span>
        </li>
        <li :class="{ active: step >= 3 }" @click="changeStep(3)">
            <span class="none-mobile">{{ $tl("labels.step.informations") }}</span>
        </li>
        <li :class="{ active: step >= 4 }" @click="changeStep(4)">
            <div v-if="option_bank === OPTION_BANK_PAYMENT.value">
                <span class="none-mobile">{{ $tl("labels.step.prepayment") }}</span>
            </div>
            <div v-else-if="footprintRequiredNow">
                <span class="none-mobile">{{ $tl("labels.step.bankImprint") }}</span>
            </div>
            <div v-else>
                <span class="none-mobile">{{ $tl("labels.step.confirmation") }}</span>
            </div>
        </li>
    </ul>
</template>

<script>
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum.js";

export default {
    props: {
        step: {
            type: Number,
            required: true,
        },
        option_bank: {
            default: "",
        },
    },
    mixins: [OptionBankEnum],
    computed: {
        reservation_date() {
            return this.$store.getters["reservation/reservation_date"];
        },
        footprintRequiredNow() {
            if (!this.reservation_date) return false;
            const diffInDays = this.reservation_date.startOf("day").diff(this.getDateTime().startOf("day"), "days").days;

            return this.option_bank === this.OPTION_BANK_FOOTPRINT.value && diffInDays <= 6;
        },
    },
    methods: {
        changeStep() {
            return;
        },
    },
};
</script>
