var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadingForm
        ? _c("LoaderComponent")
        : [
            !_vm.langFromScriptInclusion
              ? _c("SelectLang", {
                  staticClass: "dropdown dropdown-lang align-content-end",
                  on: {
                    "change-lang": function ($event) {
                      return _vm.$emit("change-lang", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "form",
              { attrs: { method: "post" }, on: { submit: _vm.post } },
              [
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("DatePicker", {
                      attrs: {
                        disabledDates: _vm.disabledDates,
                        inline: true,
                        "day-cell-content": _vm.onDayCellContentRendered,
                      },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.customEventsForSelectedDate.length > 0
                  ? _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "text-left w-100" },
                        _vm._l(
                          _vm.customEventsForSelectedDate,
                          function (customEvent) {
                            return _c("show-custom-event", {
                              key: customEvent.id,
                              attrs: { "custom-event": customEvent },
                            })
                          }
                        ),
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loadingServicesCategories > 0
                  ? _c("LoaderComponent")
                  : _c("div", [
                      _c("div", { staticClass: "mt-3 text-left" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reservation.service_category,
                                expression: "reservation.service_category",
                              },
                            ],
                            staticClass: "custom-select",
                            class: {
                              disabled: !_vm.atLeastOneServiceAvailable,
                            },
                            style: _vm.atLeastOneServiceAvailable
                              ? "text-transform: capitalize;"
                              : "",
                            attrs: {
                              required: "required",
                              disabled: !_vm.atLeastOneServiceAvailable,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.reservation,
                                  "service_category",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { disabled: "" },
                                domProps: { value: null },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.atLeastOneServiceAvailable
                                        ? _vm.widget[
                                            `wording_service_selection_${_vm.currentLang}`
                                          ] ||
                                            _vm.$tl(
                                              "labels.reservation.serviceRequired"
                                            )
                                        : _vm.$tl(
                                            "labels.reservation.noServiceForThisDate"
                                          )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.categ_services_available,
                              function (categ, index) {
                                return _c(
                                  "option",
                                  {
                                    key: `category-${index}`,
                                    domProps: { value: categ },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.getCategoryName(categ)) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-left div-pax-child" }, [
                        _c("div", { staticClass: "w-100 mt-2" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reservation.nb_pers,
                                  expression: "reservation.nb_pers",
                                },
                              ],
                              staticClass: "custom-select",
                              class: {
                                disabled:
                                  !_vm.atLeastOneServiceAvailable ||
                                  !_vm.reservation.service_category,
                              },
                              attrs: {
                                required: "required",
                                disabled:
                                  !_vm.atLeastOneServiceAvailable ||
                                  !_vm.reservation.service_category,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.reservation,
                                    "nb_pers",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$tl(
                                          _vm.widget.enable_children
                                            ? "labels.reservation.nbOfAdults"
                                            : "labels.reservation.nbOfPeople"
                                        )
                                      ) +
                                      "\n                                *\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.maxPers - _vm.reservation.nb_children * 1,
                                function (i) {
                                  return [
                                    i >= _vm.minPers
                                      ? _c(
                                          "option",
                                          {
                                            key: `nb-people-${i}`,
                                            domProps: { value: i },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(i) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.widget.enable_children
                          ? _c("div", { staticClass: "w-100 mt-2 pax-child" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.reservation.nb_children,
                                      expression: "reservation.nb_children",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "custom-select",
                                  class: {
                                    disabled:
                                      !_vm.atLeastOneServiceAvailable ||
                                      !_vm.reservation.service_category,
                                  },
                                  attrs: {
                                    disabled:
                                      !_vm.atLeastOneServiceAvailable ||
                                      !_vm.reservation.service_category,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return _vm._n(val)
                                        })
                                      _vm.$set(
                                        _vm.reservation,
                                        "nb_children",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "0" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.reservation.nbOfChildren"
                                        )
                                      ) +
                                        " (" +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.reservation.lessTenYears"
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.remainingAvailableNbChildren,
                                    function (i) {
                                      return _c(
                                        "option",
                                        {
                                          key: `nb-children-${i}`,
                                          domProps: { value: i },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(i) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _c("input", {
                          staticClass: "next action-button mt-2",
                          class: { disabled: !_vm.atLeastOneServiceAvailable },
                          attrs: {
                            type: "submit",
                            disabled: !_vm.atLeastOneServiceAvailable,
                          },
                          domProps: { value: _vm.$tl("labels.next") },
                        }),
                      ]),
                    ]),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }