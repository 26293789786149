export default {
    slots: [],
    available_menus: [],
    available_general_options: [],
    slot_message: null,
    special_closure: false,
    slots_next_dates: [],
    can_search_more_next_dates: false,
    restaurant_proposals: [],
    slots_full: [],
    stripe_setup_intent: null,
    payplug_setup_intent: null,
    created_reservation: null,
    hasMoreSlots: false,
    service_full_option: {
        value: null,
        othersDays: "othersDays",
        waiting_list: "waitingList",
        othersRestaurants: "othersRestaurants",
    },
};
