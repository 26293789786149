var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.fatalError
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v("\n        " + _vm._s(_vm.fatalError) + "\n    "),
        ])
      : _c(
          "form",
          {
            staticClass: "text-center",
            attrs: { action: "", method: "post", novalidate: "" },
            on: { submit: _vm.showPaymentForm },
          },
          [
            _c(
              "a",
              {
                staticClass: "previous action-button-previous",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("previous-step")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$tl("labels.back")))]
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "next action-button",
              attrs: { type: "submit" },
              domProps: { value: _vm.$tl("labels.action.pay") },
            }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }