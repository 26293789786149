<template>
    <div class="d-flex align-items-center w-100">
        <div class="py-2 pl-1 pr-3" v-html="customEventBellIcon"></div>
        <div class="overflow-auto">
            <div class="font-weight-bold">
                {{ customEvent.title }}
            </div>
            <template v-if="formattedDescription !== ''">
                <span v-if="shortedDescription === formattedDescription" v-html="formattedDescription"></span>
                <template v-else>
                    <span v-html="showFullDescription ? formattedDescription : shortedDescription"></span>
                    <a class="text-muted" href="#" @click.stop.prevent="showFullDescription = !showFullDescription">
                        {{ showFullDescription ? $tl("labels.showLess") : $tl("labels.showMore") }}
                    </a>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import getCustomEventBell from "../icons/customEventBell.js";

export default {
    data() {
        return {
            showFullDescription: false,
        };
    },
    props: {
        customEvent: {
            type: Object,
            required: true,
        },
    },
    computed: {
        customEventBellIcon() {
            return getCustomEventBell();
        },
        shortedDescription() {
            return this.escapeHtml(this.customEvent.description.substr(0, 100).split("\n")[0]);
        },
        formattedDescription() {
            return this.escapeHtml(this.customEvent.description).replace(/\n/g, "<br />");
        },
    },
};
</script>
