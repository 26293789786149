<template>
    <div>
        <span v-html="custom_css"></span>
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div style="padding-top: 20px">
            <loader-component v-if="loading" />
            <div v-show="!loading">
                <EditReservationComponent
                    v-if="reservation && !hasSuccess"
                    ref="editReservationComponent"
                    :langFromScriptInclusion="langFromScriptInclusion"
                    :reservation_token="reservation_token"
                    :reservation="reservation"
                    @fetch-reservation="fetchReservation"
                    @payment-success="onPaymentSuccess"
                    @footprint-success="onFootprintSuccess"
                    @resa-confirmed="onConfirmationSuccess"
                    @change-lang="$emit('change-lang', $event)" />
                <EditReservationSuccessComponent
                    v-if="reservation && hasSuccess"
                    ref="editReservationSuccessComponent"
                    :langFromScriptInclusion="langFromScriptInclusion"
                    :reservation_token="reservation_token"
                    :reservation="reservation"
                    :type="successType"
                    :amount="paymentAmount"
                    @change-lang="$emit('change-lang', $event)" />
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import axios from "axios";
import { DateTime } from "luxon";
import PaymentStatusEnum from "../mixins/enums/booking/PaymentStatusEnum.js";
import OptionBankEnum from "../mixins/enums/booking/OptionBankEnum.js";
import EditReservationComponent from "../components/EditReservation/EditReservationComponent.vue";
import EditReservationSuccessComponent from "../components/EditReservation/EditReservationSuccessComponent.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            reservation: null,
            hasSuccess: false,
            successType: null,
            paymentAmount: null,
        };
    },
    mixins: [PaymentStatusEnum, OptionBankEnum],
    props: {
        langFromScriptInclusion: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        reservation_token() {
            return location.href
                .replace(/.+\/reservation\//, "")
                .replace(/.+\/edit\//, "")
                .replace(/\?.*/, "")
                .replace(/#.*/, "")
                .replace("/reservation/", "")
                .replace("/edit/", "");
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"] || null;
        },
        fontTitle() {
            return this.$store.getters["restaurant/widgetFontTitle"];
        },
        custom_css() {
            if (!this.bgColor) return "";
            return `<style>
                    .noshow-booking-widget .fs-title {
                        color: ${this.bgColor} !important;
                    }
                    .link-widget a {
                        color: ${this.bgColor} !important;
                    }
                    .noshow-booking-widget .btn-color {
                        background-color: ${this.bgColor} !important;
                        border-color: ${this.bgColor} !important;
                    }
                    .noshow-booking-widget .loader-color .lds-ring div {
                        border-color: ${this.bgColor} transparent transparent transparent !important ;
                    }
                    .noshow-booking-widget .noshow-widget-font-title {
                        font-family: ${this.fontTitle} !important;
                    }
                </style>`;
        },
    },
    methods: {
        fetchReservation() {
            this.loading = true;
            this.error = null;

            axios
                .get(
                    `${API_URL}/widget/reservation/${this.reservation_token}?include=payment,client,slot,slot.service,slot.service.restaurant,menus,menus.options,general_options`
                )
                .then((response) => {
                    this.loading = false;
                    this.reservation = response.data;
                    this.$store.dispatch("restaurant/setBgColor", response.data.main_color);
                    this.$nextTick(() => {
                        if (this.$refs.editReservationComponent) this.$refs.editReservationComponent.initPaymentForm();
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onPaymentSuccess(amount) {
            this.hasSuccess = true;
            this.paymentAmount = amount;
            this.successType = "payment";
        },
        onFootprintSuccess() {
            this.hasSuccess = true;
            this.successType = "footprint";
        },
        onConfirmationSuccess() {
            this.hasSuccess = true;
            this.successType = "confirmation";
        },
    },
    components: {
        LoaderComponent,
        EditReservationComponent,
        EditReservationSuccessComponent,
    },
    created() {
        if (!this.reservation_token) this.error = this.$tl("errors.common.reservation_not_found");
        else {
            this.$store.dispatch("restaurant/fetchRestaurantData");
            this.fetchReservation();
        }
    },
};
</script>
