export default {
    reservation: {
        serviceRequired: "Jeu *",
        noServiceForThisDate: "Aucun jeu n'est disponible à cette date",
        lessTenYears: "- 11 ans",
    },
    full: {
        serviceFull: "Notre établissement est complet pour ce jeu.",
    },
};
