import Vue from "vue";
import VueI18n from "vue-i18n";
import fr from "../lang/fr";
import en from "../lang/en";

Vue.use(VueI18n);

const i18n = new VueI18n({
    fallbackLocale: "en",
    locale: "fr",
    messages: {
        fr,
        en,
    },
    silentTranslationWarn: false,
});

Vue.prototype.$tl = function (key, values = undefined, locale = undefined) {
    const lexicon = this.$store.getters["restaurant/lexicon"] || (THEME === "noshow" ? "restaurant" : "generic");

    const tmpKey = `lexicons.${lexicon}.${key}`;

    return this.$te(tmpKey, locale) ? this.$t(tmpKey, values) : this.$t(key, values);
};

export default i18n;
