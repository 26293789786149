<template>
    <div id="msform">
        <span v-html="custom_css"></span>
        <LoaderComponent class="w-100 text-center" v-if="loading" />
        <div class="alert alert-danger" v-else-if="error">{{ error }}</div>
        <template v-else>
            <Stepper v-if="step > 0" :step="step" :option_bank="option_bank" @change-step="step = $event" />
            <div class="formContainer">
                <div v-if="formErrors && formErrors.length > 0" class="alert alert-danger">
                    <p v-for="(error, index) in formErrors" :key="`error-${index}`">
                        {{ error }}
                    </p>
                </div>
                <h2 v-if="restaurant_name && step > 0" class="fs-title restaurant_name noshow-widget-font-title mb-4">
                    {{ restaurant_name }}
                </h2>
                <div v-if="step > 1">
                    <div class="recap-resa mb-3 table-responsive mt-2 pt-1 pb-1">
                        <span class="text-capitalize">
                            {{ displayDate(reservation.reservation_date, DateTime.DATE_HUGE) }}
                        </span>
                        -
                        <span v-if="!reservation.slot">
                            {{ getCategoryName(reservation.service_category) }}
                        </span>
                        <span v-else>
                            {{ reservation.slot.hour_start }}
                        </span>
                        - {{ reservation.nb_pers }} {{ $tl("labels.option.adult") }}
                        <span v-if="widget.enable_children == 1 && reservation.nb_children !== 0">
                            - {{ reservation.nb_children }} {{ $tl("labels.option.children") }}</span
                        >
                        <div class="d-block text-center text-warning" v-if="timeout">
                            <span
                                >Il vous reste {{ remainingMinsBeforeTimeout }} minutes pour effectuer votre réservation.<br />Passé ce délai, cette
                                dernière ne sera plus assurée.</span
                            >
                        </div>
                    </div>
                </div>
                <keep-alive>
                    <component
                        :is="currentStepComponent"
                        ref="currentStepComponent"
                        :formErrors="formErrors"
                        :langFromScriptInclusion="langFromScriptInclusion"
                        :globalLang="globalLang"
                        :option_bank="option_bank"
                        @next-step="step++"
                        @change-step="step = $event"
                        @previous-step="previousStep"
                        @previous-step-and-post="previousStepAndPost"
                        @change-lang="$emit('change-lang', $event)"
                        @set-form-errors="formErrors = $event"
                        @prevent-refresh-page="clearNoShowTimeout" />
                </keep-alive>
                <show-provider />
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import OptionBankEnum from "../mixins/enums/booking/OptionBankEnum";
import Stepper from "../components/Steps/Stepper.vue";
import Step0 from "../components/Steps/Step0Informations.vue";
import Step1 from "../components/Steps/Step1Date.vue";
import Step2 from "../components/Steps/Step2SlotAndMenus.vue";
import Step3 from "../components/Steps/Step3Client.vue";
import Step4 from "../components/Steps/Step4Payment.vue";
import Step5 from "../components/Steps/Step5Confirmation.vue";
import { DateTime } from "luxon";
import ShowProvider from "../components/ShowProvider.vue";

export default {
    name: "ReservationLayout",
    data() {
        return {
            loading: false,
            error: null,
            stepData: null,
            formErrors: [],
            timeout: undefined,
            timeoutCreatedAt: undefined,
            timeoutValue: 3300000, // 55 mins = 3300000 millisecs
            timeoutInc: 0,
            DateTime,
        };
    },
    mixins: [OptionBankEnum],
    props: {
        startStep: {
            type: Number,
            default: 1,
        },
        langFromScriptInclusion: {
            type: Boolean,
            required: true,
        },
        globalLang: {
            default: null,
        },
    },
    computed: {
        remainingMinsBeforeTimeout() {
            // Reference to trigger computed refresh
            this.timeoutInc;

            if (typeof this.timeoutCreatedAt === "undefined") {
                return undefined;
            }

            return Math.floor((this.timeoutCreatedAt + this.timeoutValue - Date.now()) / 60 / 1000);
        },
        restaurant_name() {
            return this.$store.getters["restaurant/name"];
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        fontTitle() {
            return this.$store.getters["restaurant/widgetFontTitle"];
        },
        reservation: {
            get() {
                return this.$store.getters["reservation/all"];
            },
            set(newVal) {
                this.$store.dispatch("reservation/setAll", newVal);
            },
        },
        step: {
            get() {
                if (this.stepData === null) return this.startStep;
                return this.stepData;
            },
            set(newVal) {
                this.stepData = newVal;
                this.$emit("change-step", newVal);
            },
        },
        currentStepComponent() {
            return `Step${this.step}`;
        },
        custom_css() {
            if (!this.bgColor) return "";
            return `<style>
                    .noshow-booking-widget .vdp-datepicker__calendar .cell.selected,
                    .noshow-booking-widget #progressbar li.active::before,
                    .noshow-booking-widget #progressbar li.active::after {
                        background: ${this.bgColor};
                        border-color: ${this.bgColor};
                    }
                    .noshow-booking-widget-page .noshow-booking-widget #progressbar li.active::before,
                    .noshow-booking-widget-page .noshow-booking-widget #progressbar li.active::after {
                        background: #fff;
                        border-color: #000;
                    }
                    .noshow-booking-widget #msform .action-button, .modal-header {
                        background: ${this.bgColor};
                    }
                    .noshow-booking-widget #msform .action-button:hover, .noshow-booking-widget #msform .action-button:focus {
                        background-color: ${this.bgColor};
                    }
                    .noshow-booking-widget .btn-secondary:not(:disabled):not(.disabled).hover, .noshow-booking-widget .btn-secondary:not(:disabled):not(.disabled):hover, .publicWidget .btn-secondary:not(:disabled):not(.disabled).active, .publicWidget .btn-secondary:not(:disabled):not(.disabled):active, .publicWidget .show > .btn-secondary.dropdown-toggle {
                        background-color: ${this.bgColor} !important;
                        border-color: ${this.bgColor} !important;
                    }
                    .noshow-booking-widget .fs-title.restaurant_name, .link-widget a {
                        color: ${this.bgColor} !important;
                    }
                    .noshow-booking-widget #msform input:focus,
                    .noshow-booking-widget #msform textarea:focus {
                        border: 1px solid ${this.bgColor} !important;
                    }
                    .noshow-booking-widget .loader-color .lds-ring div {
                        border-color: ${this.bgColor} transparent transparent transparent !important ;
                    }
                    .noshow-booking-widget .container-box input:checked ~ .checkmark,
                    .noshow-booking-widget .container-box:hover input:checked ~ .checkmark,
                    .noshow-booking-widget .radio .container-box input:checked ~ .checkmark,
                    .noshow-booking-widget .radio .container-box:hover input:checked ~ .checkmark {
                        background-color:  ${this.bgColor} !important;
                    }
                    .noshow-booking-widget .noshow-widget-font-title {
                        font-family: ${this.fontTitle} !important;
                    }
                    .noshow-booking-widget .label-menu .feather-search{
                        color: ${this.bgColor};
                    }
                </style>`;
        },
        currentLang() {
            return this.$store.getters["client/lang"];
        },
        createdReservation() {
            return this.$store.getters["reservationData/created_reservation"];
        },
        nb_pers_total() {
            return this.$store.getters["reservation/nb_pers_total"];
        },
        nb_menus_choosen() {
            return this.$store.getters["reservation/nb_menus_choosen"];
        },
        totalAmount() {
            var amount = 0;

            for (var index in this.reservation.choosen_menus) {
                const menu = this.reservation.choosen_menus[index];

                var menuAmount = menu.value * menu.price;

                for (var optionIndex in menu.options) {
                    var option = menu.options[optionIndex];
                    menuAmount += option.value * option.price;
                }

                amount += menuAmount;
            }

            for (var index in this.reservation.choosen_general_options) {
                const option = this.reservation.choosen_general_options[index];

                amount += option.value * option.price;
            }

            if (this.reservation.service) {
                return Math.round(amount * this.reservation.service.prepayment_percent) / 100;
            }

            return 0;
        },
        option_bank() {
            if (this.widget) {
                if (
                    this.createdReservation &&
                    this.step > 3 &&
                    ((this.reservation.gift && this.reservation.gift !== "" && this.widget.gift_voucher_footprint === 0) ||
                        !this.reservation.gift ||
                        this.reservation.gift === "")
                ) {
                    return this.createdReservation.option_bank;
                } else if (this.reservation.service) {
                    var tmpOptionBank = this.reservation.service.option_bank;

                    if (
                        this.reservation.service.prepayment_enabled &&
                        this.totalAmount > 0 &&
                        this.reservation.service.prepayment_percent &&
                        ((this.reservation.choosen_general_options && !this.$_.isEmpty(this.reservation.choosen_general_options)) ||
                            this.nb_menus_choosen > 0)
                    ) {
                        if (
                            this.reservation.service.prepayment_mandatory === 1 &&
                            this.reservation.service.prepayment_min_pers &&
                            this.reservation.service.prepayment_min_pers <= this.nb_pers_total
                        ) {
                            tmpOptionBank = this.OPTION_BANK_PAYMENT.value;
                        } else if (this.reservation.service.prepayment_mandatory === 0 && this.reservation.isPrepaymentByClient == 1) {
                            tmpOptionBank = this.OPTION_BANK_PAYMENT.value;
                        }
                    }

                    if (tmpOptionBank === this.OPTION_BANK_PAYMENT.value && this.reservation.gift && this.reservation.gift !== "") {
                        if (this.reservation.service.footprint_enabled && this.widget.gift_voucher_footprint === 0) {
                            return this.OPTION_BANK_FOOTPRINT.value;
                        } else {
                            return "";
                        }
                    } else if (
                        (!this.reservation.gift || this.reservation.gift === "") &&
                        ((this.reservation.choosen_general_options && !this.$_.isEmpty(this.reservation.choosen_general_options)) ||
                            this.nb_menus_choosen > 0) &&
                        this.step >= 2 &&
                        this.totalAmount > 0 &&
                        this.reservation.service.prepayment_enabled &&
                        this.reservation.service.prepayment_percent &&
                        (this.reservation.service.prepayment_mandatory === 1 || this.reservation.isPrepaymentByClient == 1)
                    ) {
                        return this.OPTION_BANK_PAYMENT.value;
                    } else if (
                        this.reservation.service.footprint_enabled &&
                        this.reservation.service.footprint_min_pers <= this.nb_pers_total &&
                        ((this.reservation.gift && this.reservation.gift !== "" && this.widget.gift_voucher_footprint === 0) ||
                            !this.reservation.gift ||
                            this.reservation.gift === "")
                    ) {
                        return this.OPTION_BANK_FOOTPRINT.value;
                    } else {
                        return "";
                    }
                }
            }

            return "";
        },
    },
    methods: {
        previousStep() {
            this.step--;
            this.$store.dispatch("reservation/clear", this.step);
            this.$store.dispatch("reservationData/clear", this.step);
        },
        previousStepAndPost() {
            this.step--;
            this.$store.dispatch("reservation/clear", this.step);
            this.$store.dispatch("reservationData/clear", this.step);
            this.$nextTick(() => {
                this.$refs.currentStepComponent.post();
            });
        },
        getCategoryName(category) {
            const basicCategories = ["brunch", "midi", "soir"];

            if (category === null) {
                return "";
            }

            if (basicCategories.includes(category.fr)) {
                return this.$tl(`labels.service.category.${category.fr}`);
            }

            if (this.currentLang === "fr") {
                return category.fr;
            }

            return category.en;
        },
        clearNoShowTimeout() {
            if (typeof this.timeout !== "undefined") {
                clearTimeout(this.timeout);
                this.timeout = undefined;
                this.timeoutCreatedAt = undefined;
            }
        },
    },
    watch: {
        step(newVal, oldVal) {
            if (newVal <= 2 || newVal === 5) {
                this.clearNoShowTimeout();
            } else if (newVal === 4 && oldVal === 3) {
                this.clearNoShowTimeout();

                this.timeout = setTimeout(() => {
                    alert("Vous avez dépassé le temps limite pour effectuer votre réservation, merci de réitérer votre demande.");
                    document.location.reload();
                }, this.timeoutValue);

                this.timeoutCreatedAt = Date.now();
            }
        },
    },
    components: {
        ShowProvider,
        LoaderComponent,
        Stepper,
        Step0,
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
    },
    created() {
        // Force refresh display of timeout
        setInterval(() => {
            this.timeoutInc++;
            if (this.timeoutInc > 100) this.timeoutInc = 0;
        }, 1000);
        this.loading = true;
        this.$store
            .dispatch("restaurant/fetchRestaurantData")
            .then(() => {
                this.loading = false;
                if (
                    this.widget &&
                    this.widget.widget_messages_configuration.cover_page_message_content &&
                    this.widget.widget_messages_configuration.cover_page_message_activated === 1
                )
                    this.step = 0;
            })
            .catch((error) => {
                this.loading = false;
                if (error.response && error.response.data.message) this.error = error.response.data.message;
                else if (error.message) this.error = error.message;
                else this.error = this.$tl("errors.common.unknown");
            });
    },
};
</script>
