import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import RestaurantStore from "./modules/restaurant/restaurant.js";
import ClientStore from "./modules/client/client.js";
import ReservationStore from "./modules/reservation/reservation.js";
import ReservationDataStore from "./modules/reservation_data/reservation_data.js";
import ErrorStore from "./modules/error/error.js";
import WidgetStore from "./modules/widget/widget.js";

function isLocalStorageSupported() {
    try {
        const testKey = "__this_key_should_never_be_used__";

        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);

        return true;
    } catch (e) {
        return false;
    }
}

Vue.use(Vuex);

export default function (token) {
    let plugins = [];

    if (isLocalStorageSupported()) {
        plugins.push(
            createPersistedState({
                key: `${THEME}-booking-${token.substr(0, 6)}`,
                paths: ["client", "restaurant.bgcolor", "restaurant.name"],
            })
        );
    }

    return new Vuex.Store({
        modules: {
            restaurant: RestaurantStore,
            client: ClientStore,
            reservation: ReservationStore,
            reservationData: ReservationDataStore,
            error: ErrorStore,
            widget: WidgetStore,
        },
        plugins,
    });
}
