export default {
    setBgColor(state, bgcolor) {
        state.bgcolor = bgcolor;
    },
    setStripeClientId(state, stripe_client_id) {
        state.stripe_client_id = stripe_client_id;
    },
    setName(state, name) {
        state.name = name;
    },
    setWidget(state, widget) {
        state.widget = widget;
    },
    setWidgetFontTitle(state, widget_font_title) {
        state.widget_font_title = widget_font_title;
    },
    setOwnerId(state, owner_id) {
        state.owner_id = owner_id;
    },
    setRoomNumbers(state, room_numbers) {
        state.room_numbers = room_numbers;
    },
    setTimezone(state, timezone) {
        state.timezone = timezone;
    },
    setDefaultTelCountry(state, default_tel_country) {
        state.default_tel_country = default_tel_country;
    },
    setFeatRoomNumbers(state, feat_room_numbers) {
        state.feat_room_numbers = feat_room_numbers;
    },
    setCurrency(state, currency) {
        state.currency = currency;
    },
    setLexicon(state, lexicon) {
        state.lexicon = lexicon;
    },
    setCustomEvents(state, customEvents) {
        state.customEvents = customEvents;
    },
    setFeatWhiteLabeled(state, featWhiteLabeled) {
        state.featWhiteLabeled = featWhiteLabeled;
    },
};
