export default {
    all(state) {
        return state;
    },
    reservation_date(state) {
        return state.reservation_date;
    },
    option_bank(state) {
        return state.option_bank;
    },
    service(state) {
        return state.service;
    },
    service_category(state) {
        return state.service_category;
    },
    choosen_menus(state) {
        return state.choosen_menus;
    },
    full_payment_needed(state) {
        return state.full_payment_needed;
    },
    nb_menus_choosen(state) {
        let nb = 0;
        Object.values(state.choosen_menus).forEach((m) => (nb += m.value * 1));
        return nb;
    },
    nb_pers_total(state) {
        return state.nb_pers * 1 + state.nb_children * 1;
    },
    gift(state) {
        return state.gift;
    },
    comment(state) {
        return state.comment;
    },
    isPrepaymentByClient(state) {
        return state.isPrepaymentByClient;
    },
    choosen_general_options(state) {
        return state.choosen_general_options;
    },
    prefered_room(state) {
        return state.prefered_room;
    },
    room_number(state) {
        return state.room_number;
    },
};
