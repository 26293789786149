var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "white-space": "pre-line" } },
    [
      !_vm.langFromScriptInclusion
        ? _c("SelectLang", {
            staticClass: "dropdown dropdown-lang align-content-end mr-2 mt-2",
            staticStyle: { top: "auto" },
            on: {
              "change-lang": function ($event) {
                return _vm.$emit("change-lang", $event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h2",
        {
          staticClass: "fs-title restaurant_name noshow-widget-font-title mb-2",
        },
        [_vm._v(_vm._s(_vm.restaurant_name))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-left", staticStyle: { "font-size": "0.8rem" } },
        [_vm._v("\n        " + _vm._s(_vm.widgetMessage) + "\n    ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-center mt-4" }, [
        _c(
          "button",
          {
            staticClass: "next action-button mt-4",
            on: {
              click: function ($event) {
                return _vm.$emit("next-step")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$tl("labels.action.book")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }