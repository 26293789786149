export default {
    setResponse(state, response) {
        state.response = response;
    },
    setHasLicenceError(state, value) {
        state.hasLicenceError = value;
    },
    setInMaintenance(state, value) {
        state.inMaintenance = value;
    },
};
