<template>
    <div>
        <div v-show="stripeGenerated && !stripeLoading" ref="stripePaymentCard"></div>
        <loader-component v-if="stripeLoading || (!stripeGenerated && !error)" />
        <span class="d-block text-danger mb-2" v-if="error">{{ error }}</span>
        <button
            class="btn btn-sm btn-success"
            :class="buttonAdditionalClass"
            v-show="stripeGenerated"
            :disabled="stripeLoading"
            @click="handleCardPayment">
            {{ buttonLabel }}
        </button>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import OptionBankEnum from "../../../mixins/enums/booking/OptionBankEnum.js";

export default {
    data() {
        return {
            error: null,
            stripeGenerated: false,
            stripeLoading: false,
            card: undefined,
            stripeInitTries: 0,
            stripe: null,
        };
    },
    mixins: [OptionBankEnum],
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        stripeIntent: {
            type: Object,
            required: true,
        },
        buttonLabel: {
            type: String,
            default: "Payer",
        },
        buttonAdditionalClass: {
            type: String,
            default: "",
        },
    },
    methods: {
        initStripe() {
            ++this.stripeInitTries;

            if (typeof Stripe === "undefined") {
                if (this.stripeInitTries < 3) {
                    setTimeout(() => {
                        this.initStripe();
                    }, 500);
                } else {
                    this.error = this.$tl("errors.form.payment");
                }

                return;
            }

            this.stripe = Stripe(STRIPE_API_KEY, { stripeAccount: this.reservation.payment.stripe_client_id });

            this.card = this.stripe.elements().create("card");
            this.card.mount(this.$refs.stripePaymentCard);

            this.stripeGenerated = true;
        },
        handleCardPayment() {
            if (this.stripe === null) {
                alert(this.$tl("errors.form.payment"));

                return;
            }

            this.stripeLoading = true;
            this.error = null;

            this.stripe
                .handleCardPayment(this.stripeIntent.client_secret, this.card, {
                    payment_method_data: {
                        billing_details: { name: this.reservation.client.lastname },
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.error = result.error.message;
                        return;
                    }

                    if (this.reservation.payment.option_bank === this.OPTION_BANK_PAYMENT.value) {
                        this.$emit("payment-success", this.reservation.payment.stripe_intent_amount);
                    } else if (this.reservation.payment.option_bank === this.OPTION_BANK_FOOTPRINT.value) {
                        this.$emit("footprint-success");
                    }
                })
                .catch((error) => {
                    if (error && error.message) {
                        this.error = error.message;
                    } else if (typeof error === "string" && error !== "") {
                        this.error = error;
                    } else {
                        this.error = this.$tl("errors.common.unknown");
                    }
                })
                .finally(() => {
                    this.stripeLoading = false;
                });
        },
    },
    components: {
        LoaderComponent,
    },
    mounted() {
        this.initStripe();
    },
};
</script>
