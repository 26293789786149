var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "msform" } },
    [
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _vm.reservation && !_vm.loading
        ? _c(
            "div",
            { staticClass: "formContainer" },
            [
              !_vm.langFromScriptInclusion
                ? _c("SelectLang", {
                    staticClass:
                      "dropdown dropdown-lang align-content-end mr-2 mt-2",
                    staticStyle: { top: "auto" },
                    on: {
                      "change-lang": function ($event) {
                        return _vm.$emit("change-lang", $event)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("h2", { staticClass: "fs-title noshow-widget-font-title" }, [
                _vm._v(_vm._s(_vm.reservation.slot.service.restaurant.name)),
              ]),
              _vm._v(" "),
              !_vm.reservation.canCancel
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(_vm._s(_vm.$tl("infos.cannotCancelReservation"))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "row border-light b-radius-20 pt-4 pb-4 mb-3 mt-3 ml-0 mr-0",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-left",
                      class: {
                        "col-md-7": _vm.reservation.qrCodeEnabled,
                        "col-12": !_vm.reservation.qrCodeEnabled,
                      },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "mb-2" }, [
                          _c(
                            "strong",
                            { staticClass: "noshow-widget-font-title" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$tl("labels.reservation.details")
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.displayDate(
                                  _vm.reservation.reservation_datetime,
                                  {
                                    ..._vm.DateTime.DATETIME_FULL,
                                    timeZoneName: undefined,
                                  }
                                )
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.reservation.nb_pers) +
                              " " +
                              _vm._s(_vm.$tl("labels.option.person"))
                          ),
                          _c("br"),
                        ]),
                        _vm._v(" "),
                        _vm.reservation.nb_children &&
                        _vm.reservation.nb_children > 0
                          ? _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.reservation.nb_children) +
                                  " " +
                                  _vm._s(_vm.$tl("labels.option.children"))
                              ),
                              _c("br"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reservation.menus.data &&
                        _vm.reservation.menus.data.length > 0
                          ? _c("div", [
                              _c("p", { staticClass: "mb-0 mt-2" }, [
                                _vm._v(
                                  _vm._s(_vm.$tl("labels.option.choosenMenus"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.reservation.menus.data,
                                  function (m, i) {
                                    return _c(
                                      "li",
                                      {
                                        key: i,
                                        staticStyle: {
                                          "list-style-type": "disc",
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(m.nb) +
                                              " x " +
                                              _vm._s(m.name) +
                                              " (" +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  m.price,
                                                  _vm.restaurantCurrency
                                                )
                                              ) +
                                              ")"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        m.menu_options &&
                                        m.menu_options.length > 0
                                          ? _c(
                                              "ul",
                                              _vm._l(
                                                m.menu_options,
                                                function (o, io) {
                                                  return _c("li", { key: io }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(o.nb) +
                                                        " x " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.option.menuOption"
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(o.name) +
                                                        " (" +
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            o.price,
                                                            _vm.restaurantCurrency
                                                          )
                                                        ) +
                                                        ")\n                                    "
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reservation.general_options.data &&
                        _vm.reservation.general_options.data.length > 0
                          ? _c("div", [
                              _c("p", { staticClass: "mb-0 mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl("labels.option.choosenOptions")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.reservation.general_options.data,
                                  function (o, i) {
                                    return _c(
                                      "li",
                                      {
                                        key: i,
                                        staticStyle: {
                                          "list-style-type": "disc",
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(o.nb) +
                                              " x " +
                                              _vm._s(o.name) +
                                              " (" +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  o.price,
                                                  _vm.restaurantCurrency
                                                )
                                              ) +
                                              ")"
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.stripeIntentExpired &&
                        (_vm.reservation.status ===
                          _vm.RESERVATION_STATUS_REFUSED.value ||
                          _vm.reservation.status ===
                            _vm.RESERVATION_STATUS_CANCELED.value)
                          ? _c("div", { staticClass: "mt-1" }, [
                              _vm.reservation.status ===
                              _vm.RESERVATION_STATUS_REFUSED.value
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.reservation.status.refused"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm.reservation.status ===
                                  _vm.RESERVATION_STATUS_CANCELED.value
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.reservation.status.canceled"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm.reservation.payment &&
                            _vm.reservation.payment.option_bank ===
                              _vm.OPTION_BANK_PAYMENT.value &&
                            _vm.reservation.payment.status ===
                              _vm.PAYMENT_STATUS_PENDING.value
                          ? _c(
                              "div",
                              [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.prepayment.amountToPay")
                                      ) +
                                        "\n                                " +
                                        _vm._s(
                                          _vm.formatCurrency(
                                            _vm.reservation.payment
                                              .stripe_intent_amount / 100
                                          )
                                        )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.stripeIntent
                                  ? _c("StripePaymentForm", {
                                      attrs: {
                                        buttonAdditionalClass: "btn-color",
                                        reservation: _vm.reservation,
                                        stripeIntent: _vm.stripeIntent,
                                      },
                                      on: {
                                        "payment-success": function ($event) {
                                          return _vm.$emit(
                                            "payment-success",
                                            $event
                                          )
                                        },
                                        "footprint-success": function ($event) {
                                          return _vm.$emit(
                                            "footprint-success",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.payplugIntent
                                  ? _c("PayPlugPaymentForm", {
                                      attrs: {
                                        buttonAdditionalClass: "btn-color",
                                        reservation: _vm.reservation,
                                        payplugIntent: _vm.payplugIntent,
                                      },
                                      on: {
                                        "payment-success": function ($event) {
                                          return _vm.$emit(
                                            "payment-success",
                                            $event
                                          )
                                        },
                                        "footprint-success": function ($event) {
                                          return _vm.$emit(
                                            "footprint-success",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm.reservation.payment &&
                            _vm.reservation.payment.option_bank ===
                              _vm.OPTION_BANK_PAYMENT.value &&
                            _vm.reservation.payment.status ===
                              _vm.PAYMENT_STATUS_EXPIRED.value
                          ? _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$tl("labels.prepayment.expired"))
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.reservation.status !==
                                      _vm.RESERVATION_STATUS_CANCELED.value
                                      ? _vm.$tl(
                                          "labels.restaurant.contactForInfo"
                                        )
                                      : ""
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm.reservation.payment &&
                            _vm.reservation.payment.option_bank ===
                              _vm.OPTION_BANK_FOOTPRINT.value &&
                            _vm.reservation.payment.status ===
                              _vm.PAYMENT_STATUS_PENDING.value
                          ? _c(
                              "div",
                              [
                                _c("br"),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$tl("labels.bankImprint.mustBeFilled")
                                    )
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.bankImprint.amountPerPersonToCapture"
                                        )
                                      ) +
                                      "\n                            " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.reservation.amount_if_noshow / 100
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.bankImprint.totalBankImprint"
                                          )
                                        ) +
                                        "\n                                " +
                                        _vm._s(
                                          _vm.formatCurrency(
                                            (_vm.reservation.amount_if_noshow /
                                              100) *
                                              _vm.nb_pers_total
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.stripeIntent
                                  ? _c("StripePaymentForm", {
                                      attrs: {
                                        buttonLabel: "Envoyer",
                                        buttonAdditionalClass: "btn-color",
                                        reservation: _vm.reservation,
                                        stripeIntent: _vm.stripeIntent,
                                      },
                                      on: {
                                        "payment-success": function ($event) {
                                          return _vm.$emit(
                                            "payment-success",
                                            $event
                                          )
                                        },
                                        "footprint-success": function ($event) {
                                          return _vm.$emit(
                                            "footprint-success",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.payplugIntent
                                  ? _c("PayPlugPaymentForm", {
                                      attrs: {
                                        buttonLabel:
                                          "Saisir l'empreinte bancaire",
                                        buttonAdditionalClass: "btn-color",
                                        reservation: _vm.reservation,
                                        payplugIntent: _vm.payplugIntent,
                                      },
                                      on: {
                                        "payment-success": function ($event) {
                                          return _vm.$emit(
                                            "payment-success",
                                            $event
                                          )
                                        },
                                        "footprint-success": function ($event) {
                                          return _vm.$emit(
                                            "footprint-success",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm.reservation.payment &&
                            _vm.reservation.payment.option_bank ===
                              _vm.OPTION_BANK_FOOTPRINT.value &&
                            _vm.reservation.payment.status ===
                              _vm.PAYMENT_STATUS_EXPIRED.value
                          ? _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$tl("labels.bankImprint.expired"))
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.reservation.status !==
                                      _vm.RESERVATION_STATUS_CANCELED.value
                                      ? _vm.$tl(
                                          "labels.restaurant.contactForInfo"
                                        )
                                      : ""
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm.reservation.payment &&
                            (_vm.reservation.payment.status ===
                              _vm.PAYMENT_STATUS_CAPTURABLE.value ||
                              _vm.reservation.payment.status ===
                                _vm.PAYMENT_STATUS_CAPTURED.value)
                          ? _c("div", [
                              _vm.reservation.payment.option_bank ===
                              _vm.OPTION_BANK_FOOTPRINT.value
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.bankImprint.label")
                                      ) + " "
                                    ),
                                  ])
                                : _vm.reservation.payment.has_receipt
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.prepayment.label")
                                      ) + " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.prepayment.labelPartial"
                                        )
                                      ) + " "
                                    ),
                                  ]),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      _vm.reservation.payment
                                        .stripe_intent_amount / 100
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.reservation.status ===
                          _vm.RESERVATION_STATUS_VALIDATED.value ||
                          _vm.reservation.status ===
                            _vm.RESERVATION_STATUS_OPTION.value) &&
                        _vm.reservation.confirmed_by_client !== null &&
                        _vm.reservation.confirmed_by_client === 0
                          ? _c("div", [
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.reservation.status.toBeConfirmedByClient"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-success btn-color mb-2",
                                    staticStyle: {
                                      "text-transform": "none !important",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmResa($event)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl("labels.reservation.confirm")
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showStatus
                          ? _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.reservationStatus(
                                      _vm.reservation.status
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reservation.canCancel
                          ? _c("div", [
                              _vm.cancelableReservationStatus.some(
                                (s) => s.value === _vm.reservation.status
                              )
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-danger btn-sm mt-4",
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelResa()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.reservation.cancel"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.reservation.qrCodeEnabled
                    ? _c(
                        "div",
                        { staticClass: "col-md-5" },
                        [
                          _c("qrcode", {
                            attrs: {
                              value: _vm.reservation.token,
                              options: { width: 160 },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reservation.payment &&
                  _vm.reservation.payment.status ===
                    _vm.PAYMENT_STATUS_PENDING.value
                    ? _c(
                        "div",
                        { staticClass: "col-12 text-left mt-1" },
                        [
                          _c(
                            "i18n",
                            {
                              staticClass: "link-widget",
                              attrs: {
                                path: `labels.form.${_vm.cguLabel}`,
                                tag: "span",
                              },
                            },
                            [
                              _c("template", { slot: "cgu" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.customCgu ||
                                        `${_vm.base_url}/booking/widget/public/${this.$api_key}/cgv?lang=${_vm.currentLang}`,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.$tl("labels.form.CGU")) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "pcdp" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: `${_vm.base_url}/booking/widget/public/${this.$api_key}/policy?lang=${_vm.currentLang}`,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.$tl("labels.form.PCDP")) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.form.acceptCGUReason"))
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 text-left" }, [
                    _c("div", { staticClass: "sepa" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-2" }, [
                      _c(
                        "strong",
                        { staticClass: "noshow-widget-font-title" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$tl("labels.restaurant.details")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-2 text-uppercase" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.reservation.slot.service.restaurant.name)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.reservation.slot.service.restaurant
                              .formatted_address
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.reservation.slot.service.restaurant.tel) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }