<template>
    <div style="white-space: pre-line">
        <SelectLang
            v-if="!langFromScriptInclusion"
            style="top: auto"
            class="dropdown dropdown-lang align-content-end mr-2 mt-2"
            @change-lang="$emit('change-lang', $event)" />
        <h2 class="fs-title restaurant_name noshow-widget-font-title mb-2">{{ restaurant_name }}</h2>
        <div class="text-left" style="font-size: 0.8rem">
            {{ widgetMessage }}
        </div>
        <div class="text-center mt-4">
            <button class="next action-button mt-4" @click="$emit('next-step')">{{ $tl("labels.action.book") }}</button>
        </div>
    </div>
</template>

<script>
import WidgetLangsEnum from "../../mixins/enums/WidgetLangsEnum.js";
import SelectLang from "../SelectLang.vue";

export default {
    props: {
        globalLang: {
            default: null,
        },
        langFromScriptInclusion: {
            type: Boolean,
            required: true,
        },
    },
    mixins: [WidgetLangsEnum],
    computed: {
        widgetMessage() {
            if (!this.widget || !this.widget.widget_messages_configuration) return "";
            if (this.globalLang === this.LANG_FR.value) return this.widget.widget_messages_configuration.cover_page_message_content;
            return (
                this.widget.widget_messages_configuration.cover_page_message_content_en ||
                this.widget.widget_messages_configuration.cover_page_message_content
            );
        },
        restaurant_name() {
            return this.$store.getters["restaurant/name"];
        },
        currentLang() {
            return this.$store.getters["client/lang"];
        },
        widget() {
            return this.$store.getters["restaurant/widget"];
        },
    },
    components: {
        SelectLang,
    },
};
</script>
