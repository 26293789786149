var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { attrs: { id: "msform" } }, [
      _c(
        "div",
        { staticClass: "formContainer" },
        [
          !_vm.langFromScriptInclusion
            ? _c("SelectLang", {
                staticClass:
                  "dropdown dropdown-lang align-content-end mr-2 mt-2",
                staticStyle: { top: "auto" },
                on: {
                  "change-lang": function ($event) {
                    return _vm.$emit("change-lang", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h2", { staticClass: "fs-title" }, [
            _vm._v(_vm._s(_vm.reservation.slot.service.restaurant.name)),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", { staticClass: "mt-4 mb-4 settings-title" }, [
              _vm.type === "payment"
                ? _c(
                    "h6",
                    [
                      _vm.amount
                        ? [
                            _vm._v(
                              _vm._s(
                                _vm.$tl("labels.prepayment.successAmount", {
                                  amount: _vm.$utils.formatPrice(
                                    _vm.amount / 100
                                  ),
                                })
                              )
                            ),
                          ]
                        : [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.prepayment.success"))
                            ),
                          ],
                    ],
                    2
                  )
                : _vm.type === "footprint"
                ? _c("h6", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$tl("labels.bankImprint.success")) +
                        "\n                    "
                    ),
                  ])
                : _vm.type === "confirmation"
                ? _c("h6", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$tl("labels.reservation.status.confirmed")) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "next action-button mt-4",
                staticStyle: { "text-transform": "none !important" },
                attrs: {
                  href: `/booking/widget/public/${_vm.$api_key}/reservation/${_vm.reservation_token}`,
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$tl("labels.reservation.detailsLink")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }