export default {
    setAll({ commit }, reservation_data) {
        commit("setSlots", reservation_data.slots);
        commit("setAvailableMenus", reservation_data.available_menus);
        commit("setAvailableGeneralOptions", reservation_data.available_general_options);
        commit("setSlotMessage", reservation_data.slot_message);
        commit("setSpecialClosure", reservation_data.special_closure);
        commit("setSlotsNextDates", reservation_data.slots_next_dates);
        commit("setCanSearchMoreNextDates", reservation_data.can_search_more_next_dates);
        commit("setRestaurantProposals", reservation_data.restaurant_proposals);
        commit("setSlotsFull", reservation_data.slots_full);
        commit("setStripeSetupIntent", reservation_data.stripe_setup_intent);
        commit("setPayPlugSetupIntent", reservation_data.payplug_setup_intent);
        commit("setCreatedReservation", reservation_data.created_reservation);
        commit("setServiceFullOption", reservation_data.service_full_option);
        commit("setHasMoreSlots", reservation_data.hasMoreSlots);
    },
    clear({ commit }, step = 1) {
        if (step < 2) {
            commit("setSlots", []);
            commit("setAvailableMenus", []);
            commit("setAvailableGeneralOptions", []);
            commit("setSlotMessage", null);
            commit("setSpecialClosure", false);
            commit("setSlotsNextDates", []);
            commit("setCanSearchMoreNextDates", false);
            commit("setRestaurantProposals", []);
            commit("setSlotsFull", []);
            commit("setServiceFullOption", {
                value: null,
                othersDays: "othersDays",
                waiting_list: "waitingList",
                othersRestaurants: "othersRestaurants",
            });
        }
        if (step < 4) {
            commit("setStripeSetupIntent", null);
            commit("setPayPlugSetupIntent", null);
        }
    },
    setSlots({ commit }, slots) {
        commit("setSlots", slots);
    },
    setAvailableMenus({ commit }, available_menus) {
        commit("setAvailableMenus", available_menus);
    },
    setAvailableGeneralOptions({ commit }, available_general_options) {
        commit("setAvailableGeneralOptions", available_general_options);
    },
    setSlotMessage({ commit }, slot_message) {
        commit("setSlotMessage", slot_message);
    },
    setSpecialClosure({ commit }, special_closure) {
        commit("setSpecialClosure", special_closure);
    },
    setSlotsNextDates({ commit }, slots_next_dates) {
        commit("setSlotsNextDates", slots_next_dates);
    },
    setCanSearchMoreNextDates({ commit }, can_search_more_next_dates) {
        commit("setCanSearchMoreNextDates", can_search_more_next_dates);
    },
    setRestaurantProposals({ commit }, restaurant_proposals) {
        commit("setRestaurantProposals", restaurant_proposals);
    },
    setSlotsFull({ commit }, slots_full) {
        commit("setSlotsFull", slots_full);
    },
    setStripeSetupIntent({ commit }, stripe_setup_intent) {
        commit("setStripeSetupIntent", stripe_setup_intent);
    },
    setPayPlugSetupIntent({ commit }, payplug_setup_intent) {
        commit("setPayPlugSetupIntent", payplug_setup_intent);
    },
    setCreatedReservation({ commit }, created_reservation) {
        commit("setCreatedReservation", created_reservation);
    },
    setServiceFullOption({ commit }, service_full_option) {
        commit("setServiceFullOption", service_full_option);
    },
    setHasMoreSlots({ commit }, value) {
        commit("setHasMoreSlots", value);
    },
};
