var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-outline-secondary dropdown-toggle",
        staticStyle: {
          "text-transform": "capitalize",
          "border-color": "#ededed",
        },
        attrs: {
          type: "button",
          id: "dropdownMenu2",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_vm._v("\n        " + _vm._s(_vm.currentLang) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "dropdown-menu dropdown-menu-right shadow",
        staticStyle: {
          transform: "translate3d(-69px, 29px, 0px)",
          "min-width": "100px",
        },
        attrs: { "aria-labelledby": "dropdownMenu2" },
      },
      _vm._l(_vm.ALL_WIDGET_LANGS, function (lang) {
        return _c(
          "button",
          {
            key: lang.value,
            staticClass: "dropdown-item d-flex pl-1",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("change-lang", lang.value)
              },
            },
          },
          [
            _c("country-flag", {
              staticClass: "dropdown-item",
              attrs: { country: lang.countryFlag },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "name-lang" }, [
              _vm._v(_vm._s(lang.dropdownLabel)),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }