export default {
    setSlots(state, slots) {
        state.slots = slots;
    },
    setAvailableMenus(state, available_menus) {
        state.available_menus = available_menus;
    },
    setAvailableGeneralOptions(state, available_general_options) {
        state.available_general_options = available_general_options;
    },
    setSlotMessage(state, slot_message) {
        state.slot_message = slot_message;
    },
    setSpecialClosure(state, special_closure) {
        state.special_closure = special_closure;
    },
    setSlotsNextDates(state, slots_next_dates) {
        state.slots_next_dates = slots_next_dates;
    },
    setCanSearchMoreNextDates(state, can_search_more_next_dates) {
        state.can_search_more_next_dates = can_search_more_next_dates;
    },
    setRestaurantProposals(state, restaurant_proposals) {
        state.restaurant_proposals = restaurant_proposals;
    },
    setSlotsFull(state, slots_full) {
        state.slots_full = slots_full;
    },
    setStripeSetupIntent(state, stripe_setup_intent) {
        state.stripe_setup_intent = stripe_setup_intent;
    },
    setPayPlugSetupIntent(state, payplug_setup_intent) {
        state.payplug_setup_intent = payplug_setup_intent;
    },
    setCreatedReservation(state, created_reservation) {
        state.created_reservation = created_reservation;
    },
    setServiceFullOption(state, service_full_option) {
        state.service_full_option = service_full_option;
    },
    setHasMoreSlots(state, value) {
        state.hasMoreSlots = value;
    },
};
