var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fatalError
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n        " + _vm._s(_vm.fatalError) + "\n    "),
          ])
        : [
            _vm.payplugLoading ? _c("loader-component") : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.payplugGenerated,
                    expression: "payplugGenerated",
                  },
                ],
                staticClass: "btn btn-sm btn-success",
                class: _vm.buttonAdditionalClass,
                attrs: { disabled: _vm.payplugLoading },
                on: { click: _vm.handleCardPayment },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.buttonLabel) + "\n        "
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }