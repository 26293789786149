export default {
    seeExternalMenu: "Voir la carte",
    reservation: {
        serviceRequired: "Service *",
        noServiceForThisDate: "Aucun service n'est disponible à cette date",
    },
    option: {
        chooseMenu: "Choix des menus",
        choosenMenus: "Menus sélectionnés :",
        childrenMenu: "Menu enfant",
        menuIsUnique: "Un menu unique est demandé pour l'ensemble de la table",
    },
    prepayment: {
        paymentMenusMandatory: "Pour valider et garantir votre réservation, le paiement des menus et options sélectionnées est nécessaire.",
        downPayment:
            "Pour valider et garantir votre réservation, un acompte de {percentage}% du total des menus et options sélectionnées est nécessaire.",
    },
    full: {
        serviceFull: "Notre établissement est complet pour ce service.",
    },
};
