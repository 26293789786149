<template>
    <transition appear v-on:after-enter="afterEnter" name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="$emit('close', $event)">
                <div class="modal-container" :ref="`modal-container`" :style="modalContainerStyle">
                    <div class="modal-header">
                        <slot name="header"></slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default {
    props: {
        modalContainerStyle: {
            default: "",
        },
    },
    methods: {
        afterEnter(el) {
            const body = document.getElementsByTagName("BODY")[0];

            if (document.getElementsByClassName("modal-container").length <= 1) {
                if (!body.classList.contains("w-100")) {
                    body.classList.add("w-100");
                }
            }

            disableBodyScroll(this.$refs["modal-container"].$el);
        },
    },
    beforeDestroy() {
        const body = document.getElementsByTagName("BODY")[0];

        if (document.getElementsByClassName("modal-container").length <= 1) {
            if (body.classList.contains("w-100")) {
                body.classList.remove("w-100");
            }
        }

        enableBodyScroll(this.$refs["modal-container"].$el);
    },
};
</script>
