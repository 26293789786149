var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [_c("div", { ref: "card", attrs: { id: "stripeCardForm" } })]),
    _vm._v(" "),
    _c("div", { staticClass: "note-obligatoire text-left mt-3" }, [
      _c("small", {
        staticClass: "text-muted",
        domProps: {
          innerHTML: _vm._s(
            _vm.$tl("labels.bankImprint.stripeInfos", {
              stripe_url: "https://stripe.com",
            })
          ),
        },
      }),
    ]),
    _vm._v(" "),
    _vm.threeDSMessage
      ? _c("div", { staticClass: "text-left mt-2 text-warning" }, [
          _c("small", [_vm._v(_vm._s(_vm.threeDSMessage))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4 text-center" }, [
      _c(
        "a",
        {
          staticClass: "previous action-button-previous",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.$emit("previous-step")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$tl("labels.back")))]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "next action-button mt-4",
          attrs: { type: "button" },
          on: { click: _vm.post },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$tl("labels.action.book")) +
              "\n        "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }