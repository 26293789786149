var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "msform" } },
    [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.custom_css) } }),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent", { staticClass: "w-100 text-center" })
        : _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : [
            _vm.step > 0
              ? _c("Stepper", {
                  attrs: { step: _vm.step, option_bank: _vm.option_bank },
                  on: {
                    "change-step": function ($event) {
                      _vm.step = $event
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "formContainer" },
              [
                _vm.formErrors && _vm.formErrors.length > 0
                  ? _c(
                      "div",
                      { staticClass: "alert alert-danger" },
                      _vm._l(_vm.formErrors, function (error, index) {
                        return _c("p", { key: `error-${index}` }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(error) +
                              "\n                "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.restaurant_name && _vm.step > 0
                  ? _c(
                      "h2",
                      {
                        staticClass:
                          "fs-title restaurant_name noshow-widget-font-title mb-4",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.restaurant_name) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.step > 1
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "recap-resa mb-3 table-responsive mt-2 pt-1 pb-1",
                        },
                        [
                          _c("span", { staticClass: "text-capitalize" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.displayDate(
                                    _vm.reservation.reservation_date,
                                    _vm.DateTime.DATE_HUGE
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(
                            "\n                    -\n                    "
                          ),
                          !_vm.reservation.slot
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.getCategoryName(
                                        _vm.reservation.service_category
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.reservation.slot.hour_start) +
                                    "\n                    "
                                ),
                              ]),
                          _vm._v(
                            "\n                    - " +
                              _vm._s(_vm.reservation.nb_pers) +
                              " " +
                              _vm._s(_vm.$tl("labels.option.adult")) +
                              "\n                    "
                          ),
                          _vm.widget.enable_children == 1 &&
                          _vm.reservation.nb_children !== 0
                            ? _c("span", [
                                _vm._v(
                                  "\n                        - " +
                                    _vm._s(_vm.reservation.nb_children) +
                                    " " +
                                    _vm._s(_vm.$tl("labels.option.children"))
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.timeout
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-block text-center text-warning",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Il vous reste " +
                                        _vm._s(_vm.remainingMinsBeforeTimeout) +
                                        " minutes pour effectuer votre réservation."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "Passé ce délai, cette\n                            dernière ne sera plus assurée."
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "keep-alive",
                  [
                    _c(_vm.currentStepComponent, {
                      ref: "currentStepComponent",
                      tag: "component",
                      attrs: {
                        formErrors: _vm.formErrors,
                        langFromScriptInclusion: _vm.langFromScriptInclusion,
                        globalLang: _vm.globalLang,
                        option_bank: _vm.option_bank,
                      },
                      on: {
                        "next-step": function ($event) {
                          _vm.step++
                        },
                        "change-step": function ($event) {
                          _vm.step = $event
                        },
                        "previous-step": _vm.previousStep,
                        "previous-step-and-post": _vm.previousStepAndPost,
                        "change-lang": function ($event) {
                          return _vm.$emit("change-lang", $event)
                        },
                        "set-form-errors": function ($event) {
                          _vm.formErrors = $event
                        },
                        "prevent-refresh-page": _vm.clearNoShowTimeout,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("show-provider"),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }