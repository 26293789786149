export default {
    getResponse(state) {
        return state.response;
    },
    getHasLicenceError(state) {
        return state.hasLicenceError;
    },
    getInMaintenance(state) {
        return state.inMaintenance;
    },
};
